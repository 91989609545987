import React, { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./Header.css";
import { authenticationService } from "../services";
import { Dropdown, Menu } from "semantic-ui-react";
import { Input } from "antd";
import Message from "../components/portail/Message";
import { api } from "../api";
import { authAxios } from "../services/authentications.services";
import { useStateValue } from "../StateProvider";

const Header = () => {
  const [{ searchResult }, dispatch] = useStateValue();
  const [error, setError] = useState(null);
  const { Search } = Input;
  let history = useHistory();

  async function handleSearch(value) {
    // dispatch the search result into the data layer
    try {
      const response = await authAxios.get(api.candidats.search, {
        params: value,
      });
      // console.log(response.data);
      dispatch({
        type: "SEARCH",
        result: response.data,
      });
    } catch (error) {
      setError(error.message);
      console.log(searchResult);
    }
  }

  return (
    <nav 

    className="header">
      {error && <Message negative message={error} />}
      <NavLink to="/careers">
        <img
          className="header__logo"
          src="https://gallery.mailchimp.com/10d310a71fa47b547a2f36940/images/5e42911b-546a-49ea-a2a0-f2f8ec620af2.png"
          alt="AGP Assurance Logo"
        />
      </NavLink>

      <Search
        placeholder="Prénom, nom, téléphone..."
        size="large"
        onSearch={handleSearch}
      />

      <Menu.Item 

      className="header_navMenu"

      style={{
        left: '-20px'
      }}
     >
        <Dropdown 
      
        icon="user">
          <Dropdown.Menu
            style={{left: '-45px',


            fontSize: '25px',
            color: '#3c3c3c'}}
          >
            <Menu.Item as={Link} to="/livestats">
                Livestats
              </Menu.Item>
              <Menu.Item as={Link} to="/careers">
                Carrières
              </Menu.Item>
              <Menu.Item as={Link} to="/concours-list">
                Concours
              </Menu.Item>
              <Menu.Item 
              
              as={Link} to="/foniva">
                Foniva
              </Menu.Item>
            {/* <Dropdown.Item>Profile</Dropdown.Item>
              <Dropdown.Divider /> */}
            {authenticationService.isAuthenticated ? (
              <Menu.Item 
              style={{backgroundColor: 'red', color: 'white'}}
              onClick={() => authenticationService.logout(history)}>
                Logout
              </Menu.Item>
              
            ) : (
              <Menu.Item as={Link} to="/login">
                Login
              </Menu.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    </nav>
  );
};

export default Header;
