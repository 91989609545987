import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Table, Icon, Checkbox } from "semantic-ui-react";
import InsertDriveFileTwoToneIcon from "@material-ui/icons/InsertDriveFileTwoTone";
import { useStateValue } from "../StateProvider";
import Pagination from "../components/Pagination";
import { Link } from "react-scroll";

const Careers = () => {
  const [{ searchResult }, dispatch] = useStateValue();
  const [currentPage, setCurrentPage] = useState(1);
  const [candidatsPerPage] = useState(15);
  const [boxSelection, setBoxSelection] = useState([]);

  const [arrowPhone, setArrowPhone] = useState(false);
  const [arrowStatus, setArrowStatus]= useState(false);


  const isNullOrEmpty = (value) => {
   return value === null || value.trim() === "" 
  }
  const preventNullValue = (value) => {
    return isNullOrEmpty(value) ? "-" : value
  }

  console.log(boxSelection);

  const indexOfLastCandidat = currentPage * candidatsPerPage;
  const indexOfFirstCandidat = indexOfLastCandidat - candidatsPerPage;
  let currentSearch = [];
  let currentCandidats = [];
  let totalCandidats = [];

  if (searchResult["0"] !== undefined) {
    currentSearch = searchResult["0"];
    totalCandidats = currentSearch.length;
    currentCandidats = currentSearch.slice(
      indexOfFirstCandidat,
      indexOfLastCandidat
    );
  }


  const sortingPhone = (key) => {
    console.log('ARROW PHONE')

    if (arrowPhone === true){
      setArrowPhone(false)
      dispatch({
        type: "REVERSE",
        result: currentCandidats.sort((a, b) => {
          return parseFloat(a[key]) - parseFloat(b[key]);
        }),
      });

    } if(arrowPhone !== true){
      setArrowPhone(true)
      dispatch({
        type: "REVERSE",
        result: currentCandidats.sort((a, b) => {
          return parseFloat(b[key]) - parseFloat(a[key]);
        }),
      });

    }
  }


  const sortingStatus = (key) => {
    
    if (arrowStatus === true){

      console.log('ARROW STATUS TRUE')
      setArrowStatus(false)
      dispatch({
        type: "REVERSE",
        result: currentCandidats.sort((a, b) => {
          // console.log(a[key])
          // console.log(b[key])
          
          return parseFloat(a[key]) - parseFloat(b[key]);
        }),
      });

    } if(arrowStatus !== true){
      console.log('ARROW STATUS FALSE')
      setArrowStatus(true)
      dispatch({
        type: "REVERSE",
        result: currentCandidats.sort((a, b) => {
          // console.log(a[key])
          // console.log(b[key])
          // console.log(parseFloat(b[key]) - parseFloat(a[key]))
          return parseFloat(b[key]) - parseFloat(a[key]);
        }),
      });

    }
  }


  const paginate = (pageNum) => {
    setCurrentPage(pageNum);
  };

  const nextPage = () => {
    let maximumPages = Math.ceil(totalCandidats / candidatsPerPage);
    if (currentPage < maximumPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const firstPage = () => {
    setCurrentPage(1)
  }

  const lastPage = () =>{
    setCurrentPage(Math.ceil(currentSearch.length/candidatsPerPage))
  }

  return (
    <div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Actions</Table.HeaderCell>
            <Table.HeaderCell>

              <Link to="">
                Nom
              </Link>
        
            </Table.HeaderCell>
            
              {arrowPhone ?(
                <Table.HeaderCell>
              <Link onClick={() => sortingPhone("phone")} >
                Téléphone <Icon name="caret up" />
              </Link>
              </Table.HeaderCell>
            ):(
              <Table.HeaderCell>
              <Link onClick={() => sortingPhone("phone")}>
                Téléphone <Icon name="caret down"  />
              </Link>
              </Table.HeaderCell>
            )}

            <Table.HeaderCell>
              <Link to="">
                Département
              </Link>
            </Table.HeaderCell>

            <Table.HeaderCell>
             Résultat téléphonique (%)
            </Table.HeaderCell>

            <Table.HeaderCell>
            Statut téléphonique
            </Table.HeaderCell>

            <Table.HeaderCell>
            Date d'entrevue finale
            </Table.HeaderCell>

            <Table.HeaderCell>
            Résultat final (%)
            </Table.HeaderCell>


            <Table.HeaderCell>
            Statut final
            </Table.HeaderCell>
            

            {arrowStatus ?(
              <Table.HeaderCell>
              <Link onClick={() => sortingStatus("reach_status")}>
                Statut
              </Link>
              </Table.HeaderCell>
            ):(
              <Table.HeaderCell>
              <Link onClick={() => sortingStatus("reach_status")}>
                Statut
              </Link>
              </Table.HeaderCell>
            )}
            
            <Table.HeaderCell>
              <Link to="">
                Nb de Tentatives 
              </Link>
            </Table.HeaderCell>
            <Table.HeaderCell>CV</Table.HeaderCell>
            <Table.HeaderCell>Date de création</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>

          {currentCandidats?.map((result) => {
            return (
              <Table.Row key={result.id}>
                <Table.Cell>
                  <Checkbox
                    name="myCheckBox1"
                    onChange={() =>
                      setBoxSelection([...boxSelection, result.id])
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  <NavLink to={`/careers/${result.id}`}>
                    {result.first_name} {result.last_name}
                  </NavLink>
                </Table.Cell>
                <Table.Cell>{result.phone}</Table.Cell>
                <Table.Cell>{result.department}</Table.Cell>

               
                <Table.Cell>{preventNullValue(result.phone_result)}</Table.Cell>      
                <Table.Cell>{preventNullValue(result.phone_status)}</Table.Cell>      
                <Table.Cell>{preventNullValue(result.interview_date)}</Table.Cell>      
                <Table.Cell>{preventNullValue(result.final_result)}</Table.Cell>      
                <Table.Cell>{preventNullValue(result.final_status)}</Table.Cell>      

                <Table.Cell >{result.reach_status}</Table.Cell>
                
                <Table.Cell >{isNullOrEmpty(result.attempt) ? 0 : result.attempt}</Table.Cell>
                
                <Table.Cell>
                  <Link to="">
                    {result.attachment ? (
                      <InsertDriveFileTwoToneIcon
                        onClick={() => window.open(result.attachment, "_blank")}
                      />
                    ) : (
                      "N/A"
                    )}
                  </Link>
                </Table.Cell>
                <Table.Cell>{result.created_at}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <Pagination
        candidatsPerPage={candidatsPerPage}
        totalCandidats={currentSearch.length}
        paginate={paginate}
        nextPage={nextPage}
        previousPage={previousPage}
        lastPage ={lastPage}
        firstPage={firstPage}
        current={currentPage}
      />
      <div className='mt-5'></div>
    </div>
  );
};

export default Careers;
