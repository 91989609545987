import React, { Component } from "react";
import PropTypes from "prop-types";
import i from "react-icofont";
import { Container} from 'semantic-ui-react'
export class Features extends Component {

  render() {

    //Start Features Loop
    const featuresdata = this.props.featuresData.map((features, index) => (
      <div className="single-features" key={index}>
        <div className="icon">
          <i className={features.icon} />
        </div>
        <h3 style={{color: "black"}}>{features.title}</h3>
        <p>{features.content}</p>
      </div>
    ));

    //Start Features Loop
    const featuresdata2 = this.props.featuresData2.map((features, index) => (
      <div className="single-features" key={index}>
        <div className="icon">
          <i className={features.icon} />
        </div>
        <h3 style={{color: "black"}}>{features.title}</h3>
        <p>{features.content}</p>
      </div>
    ));


    //End Features Loop
    return (

        <React.Fragment>

            <section className="features-area ptb-100">

              <div className="section-title">
                <h2><b>Description</b></h2>     
              </div>

                <Container textAlign='justified'>
                  <div className="row">
                      {/* <h1 class="ui header ">DESCRIPTION</h1> */}
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <br/>
                        <br/>
                        <p style={{color: "white"}}>
                        Le poste d’agent de contact consiste à récolter les dates de renouvellement de l’assurance automobile et habitation de nos 
                        clients potentiels. Cet emploi pourrait marquer le début de ta carrière chez AGP Assurance!
                        </p>
                        <p style={{color: "white"}}>
                        Après seulement quelques mois, de nouvelles fonctions pourraient s'offrir à toi. En t’impliquant au support client, 
                        notamment en répondant à des questions techniques, administratives et de perception, tu seras dans la bonne voie pour progresser 
                        au sein de l’entreprise. Tout ça, bien sûr, avec toujours une rémunération des plus compétitives!
                        </p>
                        <p style={{color: "white", paddingBottom: "20px"}}>
                        Des salaires compétitifs, un horaire flexible, une ambiance incomparable, une équipe du tonnerre, et surtout, un défi à ta hauteur 
                        avec de réelles possibilités d'avancement. Voilà ce que l'on t'offre. C'est beaucoup plus qu'un boulot, c'est une opportunité à 
                        saisir. Envoie-nous ta candidature: il ne manque que toi.
                        </p>
                    </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        {featuresdata}
                          <div className='mt-4'>
                          
                          </div>
                        {featuresdata2}
                      </div>
                  </div>
                </Container>
         
            </section>

        </React.Fragment>
    );
  }
}


Features.PropsTypes = {
  animationimg1: PropTypes.string,
  animationimg2: PropTypes.string,
  animationimg3: PropTypes.string,
  featuresImage: PropTypes.string,
  featuresData: PropTypes.array,
  featuresData2: PropTypes.array
};


Features.defaultProps = {
  featuresImage: require("../../src/assets/img/features-img.jpg"),
  animationimg1: require("../../src/assets/img/3.png"),
  animationimg2: require("../../src/assets/img/4.png"),
  animationimg3: require("../../src/assets/img/1.png"),
  featuresData: [
    {
      icon: "icofont-result-sport",
      title: "# Motivé",
      content:
        "Les objectifs de performance ne te font pas peur, au contraire, c'est ton carburant!"
    },
    {
      icon: "icofont-thunder-light",
      title: "# Dynamique",
      content:
        "Tu as de l'énergie à revendre? Nous sommes acheteurs. Oublie les paravents gris des cabinets d'assurance traditionnels."
    }
  ],
  featuresData2: [
    {
      icon: "icofont-book",
      title: "# Formation",
      content: 
        <div>
          <p>Tu dois détenir au minimum l'équivalent d'un Diplôme d'études collégiales (54 unités collégiales), ou être en voie de le compléter dans la prochaine année.</p>
          <p
          style={{
            marginTop: '20px'
          }}
          >
          Si ce n'est pas le cas, tu dois répondre aux exigences des équivalences de formation délivrées par
          <a 
          style={{
            display: 'inline',
            paddingLeft: '2px', 
            color: 'white',
            borderBottom: '1px solid white', 
            lineHeight: '1.1em',
            fontStyle : 'italic'
          }}
          href='https://lautorite.qc.ca/devenir-professionnel/assurance-de-dommages/formation-minimale/equivalences-de-formation-delivrees-par-lautorite'
          > l'Autorité des marchés financiers (AMF)</a>
          </p>
        </div>
    
        
    },
    {
      icon: "icofont-sand-clock",
      title: "# Disponibilité",
      content:
        "Tu dois être disponible à travailler un minimum de 3 journées par semaine."
    }
  ]
};


export default Features;
