import React from "react";
import "./Footer.css";
import { Image, List } from "semantic-ui-react";
import { Container } from "react-bootstrap";

const Footer = () => {
  return (
    <footer>
      <Container fluid className="footer" >
        <Image
          className="footer_img"
          centered
          size="mini"
          src="https://gallery.mailchimp.com/10d310a71fa47b547a2f36940/images/5e42911b-546a-49ea-a2a0-f2f8ec620af2.png"
        />
        <List horizontal divided link size="small">
          <List.Item>Powered by AGP Lab @ 2020</List.Item>
          <List.Item>Nous joindre</List.Item>
          <List.Item>Termes et conditions</List.Item>
          <List.Item>Notre cabinet a des liens financiers avec l’assureur Intact Assurance et nous faisons principalement affaire avec lui.</List.Item>
        </List>
      </Container>
    </footer>
  );
};

export default Footer;
