import React, { useState, useEffect } from "react";
import axios from "axios";
import { api } from "../../api";
const fetch = require('node-fetch');

const clientId = process.env.REACT_APP_CLIENT_ID;
const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
const environment = process.env.REACT_APP_GENESYS_CLOUD_ENVIRONMENT;


// console.log("clientId", clientId)
// console.log("clientSecret", clientSecret)
// console.log("environment", environment)


const OAuth = () => {

    function handleTokenCallback(body){
        return fetch(`https://api.${environment}/api/v2/authorization/roles`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${body.token_type} ${body.access_token}`,
                'Access-Control-Allow-Origin': '*'

            }
        })
        .then(res => {
            if(res.ok){
                console.log("Resp", res.json())
                return res.json();
            } else {
                throw Error(res.statusText);
            }
        })
        .then(jsonResponse => {
            console.log(jsonResponse);
        })
        .catch(e => console.error(e));
    }


    const params = new URLSearchParams();
    params.append('grant_type', 'client_credentials');

    fetch(`https://login.${environment}/oauth/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${Buffer.from(clientId + ':' + clientSecret).toString('base64')}`,
            'Access-Control-Allow-Origin': '*'

        },
        body: params
    })
    .then(res => {
        if(res.ok){
            console.log("Resp",res.json())
            return res.json();
        } else {
            throw Error(res.statusText);
        }
    })
    .then(jsonResponse => {
        console.log(jsonResponse);
        handleTokenCallback(jsonResponse);
    })
    .catch(e => console.error(e));

}





export const GetCurrentRDV = () => {

    let access = OAuth()

    console.log("ACCESS", access)

    const currentDate = new Date();
    const timestamp = currentDate.getTime();

    // console.log("patate")


    console.log("Time", timestamp)


    // console.log("interval", `${f_now}T00:00:00.000Z/${end_date.strftime('%Y-%m-%d')}T23:59:59.000Z`)

    let body = {
        // "interval": f"{f_now}T00:00:00.000Z/{end_date.strftime('%Y-%m-%d')}T23:59:59.000Z",
        "order": "asc",
        "orderBy": "conversationStart",
        "paging": {
            "pageSize": 25,
            "pageNumber": 1
        },
        "segmentFilters": [
            {
                "type": "and",
                "clauses": [
                    {
                        "type": "and",
                        "predicates": [
                            {
                                "type": "dimension",
                                "dimension": "mediaType",
                                "operator": "matches",
                                "value": "callback"
                            },
                            {
                                "type": "dimension",
                                "dimension": "segmentType",
                                "operator": "matches",
                                "value": "Scheduled"
                            },
                            {
                                "type": "dimension",
                                "dimension": "segmentEnd",
                                "operator": "notExists",
                                "value": null
                            }
                        ]
                    }
                ]
            }
        ]
    }





}


const GetCurrentTransfert = () => {



}


const GetCurrentSC = () => {



}


const GetCurrentCallService = () => {



}


const GetCurrentCallVente = () => {



}