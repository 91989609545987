import React, { useState, useRef } from "react";
import axios from 'axios';
import { api } from "../api";
import { Form, Input, Button, Segment } from 'semantic-ui-react'
import Message from "../components/Message";
import {history} from "../containers/helpers"
import CareerCreateChef from '../templates/CareerCreateChef'
import CareerConfirmationClient from '../templates/CareerConfirmation'
import { handleOnChange, validateAll, ErrorLabel } from "../utils/error";
import {Regx, testInput} from "../utils/regx"
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {

    return <MuiAlert elevation={6} variant="filled" {...props} />;
}




const optionsOrigin = [
    { key: "1", text: "SPLA (UL)", value: "SPLA (UL)" },
    { key: "2", text: "Facebook", value: "Facebook" },
    { key: "3", text: "Instagram", value: "Instagram" },
    { key: "4", text: "LinkedIn", value: "LinkedIn" },
    { key: "5", text: "Jobillico", value: "Jobillico" },
    { key: "6", text: "Pinterest", value: "Pinterest" },
    { key: "7", text: "Quelqu'un qui travaille chez AGP", value: "Quelqu'un qui travaille chez AGP" },
    { key: "8", text: "Autre", value: "Autre" }
];

const optionsSchoolStatus = [
    { key: "1", text: "Université", value: "Université" },
    { key: "2", text: "Cégep ", value: "Cégep" },
    { key: "3", text: "Pas à l'école", value: "Pas à l'école" }
];

const optionsStudy = [
    { key: "1", text: "Maîtrise", value: "Maîtrise" },
    { key: "2", text: "MBA", value: "MBA" },
    { key: "3", text: "BAC", value: "BAC" },
    { key: "4", text: "Certificat", value: "Certificat" },
    { key: "5", text: "Autre", value: "Autre" }
];

const optionsProgram = [
    { key: "1", text: "Administration", value: "Administration" },
    { key: "2", text: "Orientation", value: "Orientation" },
    { key: "3", text: "Relations industrielles", value: "Relations industrielles" },
    { key: "4", text: "Assurances de dommages", value: "Assurances de dommages" },
    { key: "5", text: "Droit", value: "Droit" },
    { key: "6", text: "Informatique", value: "Informatique" },
    { key: "7", text: "Sciences de la consommation", value: "Sciences de la consommation" },
    { key: "8", text: "Comptabilité", value: "Comptabilité" },
    { key: "9", text: "Multidiciplinaire", value: "Multidiciplinaire" },
    { key: "10", text: "Économie", value: "Économie" },
    { key: "11", text: "Études internationales", value: "Études internationales" },
    { key: "12", text: "Communication", value: "Communication" },
    { key: "13", text: "Enseignement", value: "Enseignement" },
    { key: "14", text: "Programmation", value: "Programmation" },
    { key: "15", text: "Marketing", value: "Marketing" },
    { key: "16", text: "Gestion de commerce", value: "Gestion de commerce" },
    { key: "17", text: "Psychologie", value: "Psychologie" },
    { key: "18", text: "Autre", value: "Autre" },
];

const optionsHourToCall = [
    { key: "1", text: "Toute la journée", value: "Toute la journée" },
    { key: "2", text: "En matinée", value: "En matinée" },
    { key: "3", text: "9h - 10h", value: "9h - 10h" },
    { key: "4", text: "10h - 11h", value: "10h - 11h" },
    { key: "5", text: "11h - 12h", value: "11h - 12h" },
    { key: "6", text: "12h - 13h", value: "12h - 13h" },
    { key: "7", text: "13h - 14h", value: "13h - 14h" },
    { key: "8", text: "14h - 15h", value: "14h - 15h" },
    { key: "8", text: "15h - 16h", value: "15h - 16h" },
    { key: "8", text: "16h - 17h", value: "16h - 17h" }
];


const CareerCreateAC = () => {

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpenError({
            ...openError,
            error : false
        })
      };



    const [errorModel, setErrorModel  ] = useState({
        "first_name" : {     
        isValid : (value) => { return testInput(value, Regx.ALPHA)},
        msg : "",
        value : "",
        key : "first_name",
        errorMsg : "Ce prénom est invalide", 
        required: true
    },
    "last_name" : {
        isValid : (value) => { return testInput(value, Regx.ALPHA)},
        msg : "",
        value : "",
        key : "last_name",
        errorMsg : "Ce nom de famille est invalide",
        required: true
    },
    "email" : {
        isValid : (value) => { return testInput(value, Regx.EMAIL)},
        msg : "",
        value : "",
        key : "email",
        errorMsg : "Cette adresse courriel est invalide",
        required: true

    },
    "phone_number" : {
        isValid : (value) => { return testInput(value, Regx.PHONE_NUMBER)},
        msg : "",
        value : "",
        key : "phone_number",
        errorMsg : "Ce numéro de téléphone est invalide (ex: 1234567891)",
        required: true


    }})

   
    const [origin, setOrigin] = useState(null);
    const [school_status, setSchool_status] = useState(null)
    const [study, setStudy] = useState(null)
    const [program, setProgram] = useState(null)
    const [number_of_session, setNumber_of_session] = useState(null)
    const [number_of_hour, setNumber_of_hour] = useState(null)
    const [document, setDocument] = useState(null)
    const [hour_to_call, setHour_to_call] = useState(null)
    const [error, setError] = useState(null)
    const [openError, setOpenError] = useState({ error: false, msg:"" });

    const [loading, setLoading] = useState(false)

    const mailgun = require("mailgun-js");
    const DOMAIN = 'mail.agpassurance.ca';
    const mg = mailgun({apiKey: 'key-8cdc956d187052f646ef4a5f3e1a8df4', domain: DOMAIN});
   
    const dataConfirmationAgp = {
        from: 'AGP Assurance <info@mail.agpassurance.ca>',
        to: 'ca.sirois@agpassurance.ca, justine.couillard@agpassurance.ca',
        // to: 'jonathan.pagani@agpassurance.ca',
        // to: 'mathieu.morin@agpassurance.ca',
        subject: 'Accusé de réception',
        html: CareerCreateChef(errorModel.first_name.value, errorModel.last_name.value, new Date().toLocaleDateString())
    };

    
    const dataConfirmationClient = {
        from: 'AGP Assurance <info@mail.agpassurance.ca>',
        to: errorModel.email.value,
        subject: "Accusé de réception",
        html: CareerConfirmationClient(errorModel.first_name.value, errorModel.last_name.value)
    }

    
    const fileInputRef = useRef()

    const handleSubmit = e => {

        e.preventDefault();
        setLoading(true);

        const isValid = validateAll(errorModel, setErrorModel)



        if(isValid){

            const formData = new FormData()
            formData.append("first_name", errorModel.first_name.value)
            formData.append("last_name", errorModel.last_name.value)
            formData.append("email", errorModel.email.value)
            formData.append("phone", errorModel.phone_number.value)
            formData.append("origin", origin)
            formData.append("school_status", school_status)
            formData.append("study", study)
            formData.append("program", program)
            formData.append("number_of_session", number_of_session)
            formData.append("number_of_hour", number_of_hour)
            formData.append("attachment", document)
            formData.append("hour_to_call", hour_to_call)


            
            axios
                .post(api.candidats.create, formData,{
                    headers: {
                        "Content-type": "multipart/form-data"
                    }
                })
                .then(async res => {
                    if (res.status === 201) {
                        setLoading(true);

                       await Promise.all([mg.messages().send(dataConfirmationAgp), mg.messages().send(dataConfirmationClient)])

                        history.push('/succes')

                    }
                })
                .catch(err => {
                    console.log(err.response)
                    setError(err.message || err)
                    setLoading(false);
                })

        }else{
            setLoading(false);
            setOpenError({ error: true, msg: "Veuillez corriger les champs en erreur" })
            return false;
        }

    }
        

    return (

        <section id="contact" className="contact-area ptb-100">
            <div className="container">

                <div className="section-title">
                    <h2>ENVOIE-NOUS TON 
                        <b> C.V.</b>!
                    </h2>
                    <p></p>
                </div>
                <Segment stacked color='orange' >
                <div className="box" style={{paddingTop: "50px"}}>
                    
                {error && (

                    <Message danger message='Une erreur est survenue, veuillez vérifier que le type de fichier est en format : (.pdf, .doc ou .docx)' />

                )}

                {document && <Message info message={`Document sélectionné: ${document.name}`} />}

                    <Form onSubmit={handleSubmit} >

                        {/* first_name & last_name */}
                        <Form.Group widths='equal'>

                            <Form.Field
                                className={errorModel.first_name.msg !== "" ? "error" : ""}
                                label={
                                <ErrorLabel 
                                        errorMsg={errorModel.first_name.msg} 
                                        text="Prénom" 
                                        />
                                }
                                required
                                maxLength="20"
                                name="first-name"
                                id='form-input-control-first-name'
                                control={Input}
                                placeholder='Prénom'
                                value={errorModel.first_name.value}
                                onChange={e => {handleOnChange(errorModel, setErrorModel,  errorModel.first_name, e)}}
                            />

                            <Form.Field
                                   className={errorModel.last_name.msg !== "" ? "error" : ""}
                                   label={
                                   <ErrorLabel 
                                           errorMsg={errorModel.last_name.msg} 
                                           text="Nom" 
                                           />
                                   }
                                required
                                maxLength="20"
                                id='form-input-control-last-name'
                                name="last-name"
                                control={Input}
                                placeholder='Nom'
                                value={errorModel.last_name.value}
                                onChange={e => {handleOnChange(errorModel, setErrorModel,  errorModel.last_name, e)}}

                            />
                            
                        </Form.Group>

                        {/* email & phone */}
                        <Form.Group widths='equal'>
                          
                            <Form.Field
                                className={errorModel.email.msg !== "" ? "error" : ""}
                                required
                                maxLength="50"
                                id='form-input-control-email'
                                control={Input}
                                name="email"
                                placeholder='Email'
                                label={
                                    <ErrorLabel 
                                    errorMsg={errorModel.email.msg} 
                                    text="Email" 
                                    />
                                }
                                value={errorModel.email.value}
                                onChange={e => {handleOnChange(errorModel, setErrorModel, errorModel.email, e)}  }
                            />

                            <Form.Field
                                className={errorModel.phone_number.msg !== "" ? "error" : ""}
                                required
                                maxLength="10"
                                minLength="10"
                                id='form-input-control-phone'
                                control={Input}

                                label={
                                    <ErrorLabel
                                    errorMsg={errorModel.phone_number.msg}
                                    text="Téléphone"
                                    />
                                }
                                name="phone"
                                placeholder='Téléphone'
                                value={errorModel.phone_number.value}
                                onChange={e => {handleOnChange(errorModel, setErrorModel, errorModel.phone_number, e)}  }

                            />

                        </Form.Group>

                        {/* origin & school_status */}
                        <Form.Group widths='equal'>

                            <Form.Dropdown
                                required
                                placeholder="Choisir"
                                label={
                                    <ErrorLabel
                                    text="Où as-tu entendu parler de nous?"
                                    />
                                }
                                selection
                                onChange={(e, result) => setOrigin(result.value)}
                                name="about-us"
                                options={optionsOrigin}
                                value={origin}
                            />

                            <Form.Dropdown
                                required
                                placeholder='Choisir'
                                label={
                                    <ErrorLabel
                                    text="Es-tu présentement aux études?"
                                    />
                                }
                                name="studies"
                                selection
                                onChange={(e, result)  => setSchool_status(result.value)}
                                options={optionsSchoolStatus}
                                value={school_status}
                            />

                        </Form.Group>

                        
                        {school_status === "Pas à l'école" || school_status === null  ? (
                  
                            <div></div>

                        ) : (

                            <Form.Group widths='equal'>
                            
                                <Form.Dropdown
                                    placeholder="Choisir"
                                    label="Niveau d'étude"
                                    selection
                                    name="studies-level"
                                    onChange={(e, result)  => setStudy(result.value)}
                                    options={optionsStudy}
                                    value={study}
                                />

                                <Form.Dropdown
                                    placeholder="Choisir"
                                    label="Quel programme?"
                                    name="studies-program"
                                    selection
                                    onChange={(e, result)  => setProgram(result.value)}
                                    options={optionsProgram}
                                    value={program}
                                />

                            </Form.Group>

                        )}



                        {/* nb session & nb hour */}
                        <Form.Group widths='equal'>

                            <Form.Field
                                required
                                maxLength="2"
                                type="number"
                                id='form-input-control-number'
                                control={Input}
                                label={
                                    <ErrorLabel
                                    text='Nombre de sessions restantes?'
                                    />
                                }

                                name="studies-sessions-remains"
                                placeholder='Nombre de sessions restantes'
                                value={number_of_session}
                                onChange={e => setNumber_of_session(e.target.value)}
                                
                            />

                            <Form.Field
                                required
                                maxLength="2"
                                type="number"
                                id='form-input-control-number'
                                control={Input}
                                name="studies-hours"
                                label={
                                    <ErrorLabel
                                    text="Nombre d'heures souhaitées?"
                                    />
                                }
 
                                placeholder="Nombre d'heures souhaitées"
                                value={number_of_hour}
                                onChange={e => setNumber_of_hour(e.target.value)}
                                
                            />
                            
                        </Form.Group>

                        {/* attachment */}
                        <Form.Group widths='equal'>

                            <Form.Field>

                                <Button
                                    style={{backgroundColor: 'orange'}}
                                    type="button"
                                    fluid
                                    content="Télécharge ton C.V. (pdf/docx/doc)"
                                    labelPosition="left"
                                    icon="file"
                                    onClick={() => fileInputRef.current.click()}
                                />

                                <input
                                    accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/msword"
                                    ref={fileInputRef}
                                    type="file"
                                    hidden
                                    name="cv"
                                    onChange={e => setDocument(e.target.files[0])}
                                />
                            </Form.Field>

                        </Form.Group>

                        {/* hour to call */}
                        <Form.Group widths='equal'>
                          
                          <Form.Dropdown
                                required
                                placeholder="Choisir"
                                label="Moment idéal pour te joindre?"
                                selection
                                onChange={(e, result)  => setHour_to_call(result.value)}
                                options={optionsHourToCall}
                                value={hour_to_call}
                                name="hour-to-call"
                          />

                      </Form.Group>

                        <Button 
                            inverted 
                            loading={loading}
                            disabled={loading}
                            type='submit'
                            color='orange'
                            // control={Button}
                            content='Envoyer'
                            
                        />
                        
                        <Snackbar open={openError.error} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="Error">
                                {openError.msg}
                            </Alert>
                        </Snackbar>

                    </Form>

                </div>
                </Segment>
            </div>
        </section>
            
        
    )
}

export   {CareerCreateAC, 
          optionsOrigin}
