import React from 'react';
import NavBar from "../components/NavBar";
import Features from "../components/Features";
import Work from "../components/Work";
import ContactUs from "../components/ContactUs";
import FooterWeb from "../components/FooterWeb";
import ScrollUpBtn from "../components/ScrollUpBtn";
import Services from "../components/Services";
import 'antd/dist/antd.css'
import { Tabs } from 'antd';
import  {CareerCreateAC}from '../containers/CareerCreate'
import { Grid } from 'semantic-ui-react'



const { TabPane } = Tabs;


function Carrieres(props) {

    return( 

        <React.Fragment>

            <NavBar />

            <Work />
            
        <Grid centered>
            <Grid.Row>
                <Grid.Column width={12}>
                        <Tabs defaultActiveKey="1" type="card" size='large' tabBarStyle={{backgroundColor: "white"}} className="box">

                                <TabPane tab={<span style={{ color: "orange" }}>Agent de contact</span>} key="1" >

                                    <ContactUs />
                            
                                    <Features />

                                    <Services />

                                    <CareerCreateAC />

                                </TabPane>

                                {/* <TabPane tab="Adjoint administratif" disabled key="3">

                                    <ContactUs />
                            
                                    <Features />

                                    <Services />

                                    <CareerCreateAC />
                                    
                                </TabPane> */}
                        </Tabs>
                </Grid.Column>
            </Grid.Row>
        </Grid>
            <FooterWeb />

            <ScrollUpBtn />

        </React.Fragment>
    );
    
}
export default Carrieres;


