import React, { Component } from "react";
import Icofont from "react-icofont";
import PropTypes from "prop-types";
import { Divider } from "semantic-ui-react";
import { Link } from "react-scroll";

class Welcome extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="welcome-area ptb-100" ref="reclamation">
          <div className="container">
            <div className="section-title">
              <h2 style={{ fontSize: "35px", color: "orange" }}>
                Section réclamation
              </h2>
              <Divider />
            </div>
            <div 
            style={{justifyContent: 'center'}}
            className="row">
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="single-box">
                  <Link href="tel:+18664642424" onClick="tel:+18664642424">
                    <Icofont icon="icofont-phone" />
                    <h3>Intact 1 866 464-2424</h3>
                  </Link>
                  <p>
                    <b>Intact</b>
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="single-box">
                  <Link href="tel:+14186832711" onClick="tel:+14186832711">
                    <Icofont icon="icofont-phone" />
                    <h3>L'Unique 1 418 683-2711</h3>
                  </Link>
                  <p>
                    <b>L'Unique</b>
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="single-box">
                  <Link href="tel:+15143564111" onClick="tel:+15143564111">
                    <Icofont icon="icofont-phone" />
                    <h3>PAFCO 1 514 356-4111</h3>
                  </Link>
                  <p>
                    <b>PAFCO</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
Welcome.propTypes = {
  sectiontopTitle: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionTitleSpan: PropTypes.string,
  sectionContent: PropTypes.string,
  welcomesData: PropTypes.array,
};
Welcome.defaultProps = {
  sectiontopTitle: "",
  sectionTitle: "",
  sectionTitleSpan: "",
  sectionContent: "",
  welcomesData: [
    {
      icon: "icofont-phone",
      title: "Intact 1 866 464-2424*",
      content: "",
    },
    {
      icon: "icofont-car-alt-4",
      title: "Réclamation auto",
      content: "",
    },
    {
      icon: "icofont-check",
      title: "Suivre une réclamation",
      content: "",
    },
  ],
};
export default Welcome;
