import React, { useState, useRef } from "react";

const validate  =  (models, setModels,key, value) => {
    if(!models[key].isValid(value)){
                                        
        setModels((oldValue) => {
            oldValue[key].msg = oldValue[key].errorMsg
            oldValue[key].value = value
            return {
                ...oldValue
            }
        })

    

    } else{
        setModels((oldValue) => {
            oldValue[key].msg = ""
            oldValue[key].value = value
            return {
                ...oldValue
            }
        })
    }

}


const validateAll = (models, setModels) => {

    let isValid = true

    for (const key in models){
        const model = models[key]
        if(model.msg !== ""){
            console.log(model.key,  model.msg)
            
            isValid = false
        }else if(model.value === "" && model.required){
            console.log(model.key,  model.value)

            isValid = false
            setModels((oldValue) => {
                oldValue[model.key].msg = "Ce champ est requis"
                return {
                    ...oldValue
                }

            })
        }



 
    }

    return isValid
}

const handleOnChange = (models, setModels,model, e) => {
    let value = e.target.value.trim()
    validate(models, setModels, model.key, value)
}

const ErrorLabel = ({text, errorMsg}) => {
    return( 
        <div style={{display: 'block'}}>
            <span style={{color:"red", display:'block', height: 20, fontSize:"0.9em",marginBottom: 10}}>{errorMsg}</span>
            <span style={{fontWeight: 'bold'}}  >{text}</span> <span style={{color: '#db2828'}}>*</span>
        </div>                                       
    )
}

export {ErrorLabel, handleOnChange, validateAll, validate}