export const initialState = {
  searchResult: [],
};

const reducer = (state, action) => {
  console.log(action);
  switch (action.type) {
    case "SEARCH":
      return {
        searchResult: [action.result],
      };

    case "REVERSE":
      // console.log("toto");
      return {
        searchResult: [action.result],
      };

    default:
      return state;
  }
};

export default reducer;
