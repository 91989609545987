

import NavBar from "../components/NavBar";
import FooterWeb from "../components/FooterWeb";
import "./template.css";

import { ReactComponent as MapIcon } from "../assets/svg/map.svg"

import React, { useState, useRef } from "react";
import { Form, Input, Button, Segment, Label, TextArea } from 'semantic-ui-react'

import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import axios from 'axios';
import { api } from "../api";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Regx, testInput } from "../utils/regx"
import { ErrorLabel, handleOnChange, validateAll, validate } from "../utils/error"

import {history} from "../containers/helpers"



const moduleStyle = {
    section: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center',
        minHeight: '70vh',
        marginBottom: 20, 
        backgroundColor: 'rgb(241, 241, 241)'
    },

    wrapper: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
    },

    div: {
        textAlign: 'center',
        flex: 0.5,
    },

    divLeft: {
        padding: '3vh'

    },

    divRight: {
        padding: '3vh'
    }
}







const BanniereCentrale = () => {

    const [loading, setLoading] = useState(false)

    const [email, setEmail] = useState(null)
    const [openError, setOpenError] = useState({ error: false, msg: "" });
    const [openSuccess, setOpenSuccess] = useState(false);

    const [models, setModels] = useState({
        "first_name": {
            isValid: (value) => { return testInput(value, Regx.ALPHA) },
            msg: "",
            value: "",
            key: "first_name",
            errorMsg: "Ce champ est invalide",
            required: true
        },
        "last_name": {
            isValid: (value) => { return testInput(value, Regx.ALPHA) },
            msg: "",
            value: "",
            key: "last_name",
            errorMsg: "Ce champ est invalide", 
            required: true

        },
        "phone_number": {
            isValid: (value) => { return testInput(value, Regx.PHONE_NUMBER) },
            msg: "",
            value: "",
            key: "phone_number",
            errorMsg: "Ce champ est invalide (ex: 1234567891)",
            required: true

        },
        "email": {
            isValid: (value) => { return testInput(value, Regx.EMAIL) },
            msg: "",
            value: "",
            key: "email",
            errorMsg: "Ce champ est invalide",
            required: true
        },
        "police": {
            isValid: (value) => { return false },
            msg: "",
            value: "",
            key: "police",
            errorMsg: "",
            required: false
        },
        "call_reason": {
            isValid: (value) => { return false },
            msg: "",
            value: "",
            key: "call_reason",
            errorMsg: "",
            required: false
        },
        "callback_date":{
            msg : "",
            value : "", 
            key: "callback_date",
            isValid: (value) =>{ return value !== ""},
            required: true

        }
    })



    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenError(false)
        setOpenSuccess(false);
    };

    function Alert(props) {

        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }


    const handleSubmit = e => {

        const form = e.currentTarget
        setLoading(true);

        const isValid = validateAll(models, setModels)

        if (!isValid) {

            setLoading(false);
            setOpenError({ error: true, msg: "Veuillez corriger les champs en erreur" })
            return false
        }



        const formData = new FormData()
        formData.append("first_name", models.first_name.value)
        formData.append("last_name", models.last_name.value)
        formData.append("email", models.email.value)
        formData.append("phone_number", models.phone_number.value)
        formData.append("callback_date", moment(models.callback_date.value).format("YYYY-MM-DD HH:mm"))
        formData.append("police", models.police.value )
        formData.append("call_reason", models.call_reason.value )

        setLoading(true);

        axios
            .post(api.appelDeService.create, formData, {
                headers: {
                    "Content-type": "multipart/form-data"
                }
            })
            .then(async res => {
                if (res.status === 201) {
                    setOpenSuccess(true)
                    setLoading(false);
                    history.push('/confirmation-appel-de-service')

                }
            })
            .catch((err, data) => {

                setLoading(false)
                console.log(err.response)

                setOpenError({ error: true, msg: "Une erreur est survenue. Veuillez réessayer plus tard." })

            })
    }

    return (
        <section  id="rendez-vous" style={moduleStyle.section}>
            <div className="wrapper" style={moduleStyle.wrapper}>

              
                    <Form 
                    className="segment"
                    
                    style={{
                        marginTop:'10vh',
                        marginBottom:'10vh',
                        width: '70%', 
                        padding: '50px',
                        borderRadius: '25px'

                }}  onSubmit={handleSubmit} >
                        <Form.Group widths='equal'>


                            <Form.Field
                                className={models.first_name.msg !== "" ? "error" : ""}
                                name="first_name"
                                id='form-input-control-first-name'
                                control={Input}
                                value={models.first_name.value}
                                label={() => {
                                    return (<ErrorLabel
                                        errorMsg={models.first_name.msg}
                                        text="Prénom"
                                    />)
                                }}
                                placeholder='Prénom'
                                onChange={e => { handleOnChange(models, setModels, models.first_name, e) }}
                                style={{ order: 2, width: '100%'}}
                            />



                            <Form.Field
                                className={models.last_name.msg !== "" ? "error" : ""}
                                id='form-input-control-last-name'
                                value={models.last_name.value}
                                name="last_name"
                                label={() => {
                                    return (<ErrorLabel
                                        errorMsg={models.last_name.msg}
                                        text="Nom"
                                    />)
                                }}
                                control={Input}
                                placeholder='Nom'
                                onChange={e => { handleOnChange(models, setModels, models.last_name, e) }}
                                style={{ order: 1 }}
                            />

                        </Form.Group>

                        <Form.Group widths='equal'>
                            <Form.Field

                                className={models.email.msg !== "" ? "error" : ""}
                                id='form-input-control-email'
                                value={models.email.value}
                                name="email"
                                control={Input}
                                label={() => {
                                    return (<ErrorLabel errorMsg={models.email.msg} text='Adresse courriel' />)
                                }}
                                placeholder='Adresse courriel'
                                onChange={e => { handleOnChange(models, setModels, models.email, e) }}


                            />

                            <Form.Field
                                id='form-input-control-phone-number'
                                className={models.phone_number.msg !== "" ? "error" : ""}
                                value={models.phone_number.value}
                                name="phone_number"
                                control={Input}
                                label={() => {
                                    return (<ErrorLabel errorMsg={models.phone_number.msg} text='Numéro de téléphone' />)
                                }}
                                onChange={e => { handleOnChange(models, setModels, models.phone_number, e) }}

                                placeholder='Numéro de téléphone'
                            />
                        </Form.Group>

            
                        <Form.Group
                        style={{paddingTop: '20px'}}


                            widths='equal'>
                                  <Form.Field
                                    style={{width: '100%'}}
                                    required={false}
                                    id='form-input-no-police'
                                    value={models.police.value}
                                    name="police"
                                    control={Input}
                                    label={() => {
                                        return (
                                        <div style={{display: 'block'}}>
                                            <span style={{color:"red", display:'block', height: 20, fontSize:"0.9em",marginBottom: 10}}></span>
                                            <span style={{fontWeight: 'bold'}}  >Numéro de contrat</span> 
                                        </div>    )
                                    }}
                                    onChange={e => { handleOnChange(models, setModels, models.police, e) }}

                                    placeholder='Numéro de contrat'
                                />

                            <div
                                style={{width: '100%'}}
                            >

                                {/*<label style={{ display: 'block', width: '100%' }}>Date / Heure</label>*/}
                                <ErrorLabel errorMsg={models.callback_date.msg} text='Date / Heure' />

                                <DatePicker
                                minTime={new Date().setHours(8)}
                                maxTime={new Date().setHours(20)}
                                timeCaption="Heure"

                                    wrapperClassName={models.callback_date.msg !== "" ? "error" : ""}
                                    className={"ui input"}
                                    value={models.callback_date.value}
                                    name="callback_date"
                                    showTimeSelect
                                    timeFormat="HH:mm" selected={models.callback_date.value}
                                    onChange={date => { 
                                        validate(models, setModels, models.callback_date.key, date)
                                    }}
                                    

                                />
                            </div>

                 
                        </Form.Group>


                            <Form>
                                <label
                                style={{
                                    display: 'block'
                                }}>
                                <span style={{color:"red", display:'block', height: 20, fontSize:"0.9em",marginBottom: 10}}></span>
                                                        <span style={{fontWeight: 'bold'}}>Raison de la demande de service</span> 
                                </label>
                            <TextArea
                                        style={{width: '100%'}}
                                        required={false}
                                        id='form-input-call-reason'
                                        value={models.call_reason.value}
                                        
                                        name="call_reason"
                                        control={TextArea}
                              
                                        onChange={e => { 
                                            let value = e.target.value
                                            setModels((oldValue) => {
                                                oldValue["call_reason"].value = value
                                                return {...oldValue}
                                            }) 
                                    }}
              
                                        placeholder='Raison de la demande'
                            />


                            </Form>


                    
                        <Button
                            inverted
                            loading={loading}
                            disabled={loading}
                            type='submit'
                            color='orange'
                            inverted
                            content='Rendez-vous'
                            style={{ display: 'block', margin: 'auto', marginTop: 30 }}
                        />

                    </Form>
                    <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success">
                            Merci! Les informations ont bien été enregistrées!
                            </Alert>
                    </Snackbar>

                    <Snackbar open={openError.error} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="Error">
                            {openError.msg}
                        </Alert>
                    </Snackbar>


            </div>
        </section>
    )
}








export default () => {
    return (


        <React.Fragment>

            <NavBar />



            <div id="header-appel-de-service"
                style={{
                    color: 'white',
                    padding: '40px',
                    backgroundColor: '#b5b5b5',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    flexWrap: 'wrap',
                    textAlign: 'center',
                    content: "",
                    left: 0,
                    opacity: 1,
                    background: 'url(https://mcusercontent.com/10d310a71fa47b547a2f36940/images/440c0d55-e378-4a7a-b5a4-df61f7d937e4.jpg)',
                    zIndex: -1
                }}
            >
                <h2 style={{ paddingTop: '100px', fontSize: '3em' }} > <span style={{ color: 'orange' }}>Des questions?</span></h2>
                <p style={{ width: '100%', textAlign: 'center', color: 'white' }}>Permettez-nous de vous rappeler au meilleur moment!</p>
            </div>

            <BanniereCentrale />

            <FooterWeb />

        </React.Fragment>


    )
}