import React, { Component } from 'react';

//Components List
import NavBar from "../components/NavBar";
import BannerAppel from "../components/BannerAppel";
import Footer from "../components/Footer";
import ScrollUpBtn from "../components/ScrollUpBtn";

class ConfirmationAppel extends Component {
    render() {
        return(
            <React.Fragment>
                {/* NavBar: src/components/NavBar */}
                <NavBar />
                {/* NavBar: src/components/banner/BannerOne */}
                <BannerAppel />
                {/* Subscribe: src/components/Subscribe */}
                <Footer />
                {/* ScrollUpBtn: src/components/ScrollUpBtn */}
                <ScrollUpBtn />
            </React.Fragment>
        );
    }
}
export default ConfirmationAppel;


