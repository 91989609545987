import React, { Component } from "react";
import { Link } from "react-scroll";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Image, Button } from "semantic-ui-react";
// import SideNav from  "../components/SideNav";
class NavBar extends Component {
  componentDidMount() {
    let elem = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elem.classList.add("is-sticky");
      } else {
        elem.classList.remove("is-sticky");
      }
    });
    let scrollWithOffset = (el, offset) => {
      const elementPosition = el.offsetTop - offset;
      window.scroll({
        top: elementPosition,
        left: 0,
        behavior: "smooth",
      });
    };
    this.setState({ scrollWithOffset });
  }
  closeNavbar() {
    if (window.matchMedia("screen and (max-width: 991px)").matches) {
      document.getElementById("collaspe-btn").click();
    }
  }


  
  render() {
    return (
      <React.Fragment >
        <Navbar
          id="navbar"
          sticky="top"
          bg="light"
          expand="lg"
          className={"navbar navbar-expand-lg navbar-light bg-light " + this.props.colorClass === undefined ? "" : this.props.colorClass  }
          collapseOnSelect={true}
        >
          <Container>
           
            <Navbar.Brand>
              <Link
                to="home"
                href="/"
                onClick="/"

                spy={true}
                smooth={true}
                offset={-70}
                duration={800}
              >
                <Image
                  src="https://gallery.mailchimp.com/10d310a71fa47b547a2f36940/images/5e42911b-546a-49ea-a2a0-f2f8ec620af2.png"
                  style={{ height: "60px", width: "55" }}
                />
              </Link>
            </Navbar.Brand>
            
            <Navbar.Toggle aria-controls="basic-navbar-nav" id="collaspe-btn" />
           
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Item className="pt-2">
                  <Link
                    activeClass="active"
                    href="/"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={800}
                    className="nav-link"
                    onClick="/"
                  >
                    Particuliers
                  </Link>
                </Nav.Item>
                <Nav.Item className="pt-2">
                  <Link
                    activeClass="active"
                    href="/entreprises"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={800}
                    className="nav-link"
                    onClick="/entreprises"
                  >
                    Entreprises
                  </Link>
                </Nav.Item>
                {/* <Nav.Item className="pt-2">
                  <Link
                    activeClass="active"
                    to=""
                    href="/particuliers"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={800}
                    className="nav-link"
                    onClick="/particuliers"
                  >
                    Réclamation
                  </Link>
                </Nav.Item> */}
                <Nav.Item className="pt-2">
                  <Link
                    activeClass="active"
                    href="/carrieres"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={800}
                    className="nav-link"
                    onClick="/carrieres"
                  >
                    Carrières
                  </Link>
                </Nav.Item>

                    {/*
                <Nav.Item className="pt-2">
                  <Link
                    activeClass="active"
                    href="/appel-de-service"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={800}
                    className="nav-link"
                    onClick="/appel-de-service"
                  >
                    Prise de rendez-vous
                  </Link>
                </Nav.Item>

                */}

                <Nav.Item className="pt-2">
                  <Link
                    activeClass="active"
                    href="/nous-joindre"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={800}
                    className="nav-link"
                    onClick="/nous-joindre"
                  >
                    Nous joindre
                  </Link>
                </Nav.Item>

                {/* <Nav.Item className="pt-2">
                                <Link
                                    activeClass="active"
                                    to=""
                                    href='https://apps.intactinsurance.com/waa/?province=qc&language=fr'
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={800}
                                    className="nav-link"
                                    onClick='https://apps.intactinsurance.com/waa/?province=qc&language=fr'
                                >
                                    Espace Client Intact
                                </Link>
                            </Nav.Item> */}
                <Nav.Item>
                  <Link
                    href="https://apps.intactinsurance.com/waa/?province=qc&language=fr"
                    onClick="https://apps.intactinsurance.com/waa/?province=qc&language=fr"
                  >
                    <Button color="orange">Espace client Intact</Button>
                  </Link>
                  {/* <SideNav /> */}
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </React.Fragment>
    );
  }
}
export default NavBar;
