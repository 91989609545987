import React from 'react';
import { Link } from "react-scroll";


const moduleStyle = {
    header: {
        backgroundColor : '#000', 
        height: 100
    }
}

const Header = () => {



    return (<div style={{height: 100, backgroundColor: 'black', paddingLeft: '5%', display: 'flex',alignItems: 'center' ,justifyContent: 'space-between'}}>
        <Link
                href="/"
                onClick="/"
        >
        <img style={{
            height: 60, 
            margin: 20, 
            marginLeft: '10%'
        }} src="https://mcusercontent.com/10d310a71fa47b547a2f36940/images/864446e1-c03f-4b99-bb60-83d419015d2c.png">
        </img>
        </Link>

        <p style={{
            paddingRight: '10%', 
            fontSize: '1.5em',
            color: 'white', 
            fontFamily: "'Nimbus black', sans-serif'",
            textAlign: 'right'
        }}>
            Soumission d'assurance</p>
    </div>)
}

export default Header