import React from 'react';
import NavBar from "../../components/NavBar";

import BanniereCentrale from "./modules/BanniereCentrale"
import Header from "./modules/Header"

import FooterWeb from "../../components/FooterWeb";
import ScrollUpBtn from "../../components/ScrollUpBtn";

import 'antd/dist/antd.css'
import { Tabs } from 'antd';


const { TabPane } = Tabs;

function Cotation(props) {

    return( 
        <React.Fragment>

            <Header />

            <BanniereCentrale />
            
            <FooterWeb />

            <ScrollUpBtn />

        </React.Fragment>
    );
    
}
export default Cotation;


