import React from "react";
import "./Pagination.css";


const Pages = ({nbPages, current, paginate, nbPageShow}) =>{

  let pages = []
  
  for (let index = 1; index <= nbPages; index++) {
    pages.push(index)
  }

  
  const min = () => Math.max(Math.floor(current/nbPageShow) * nbPageShow -1, 0)
  const max = () => min() + nbPageShow





  return (

    nbPages > 0 ?

    pages.slice(
      min(),
      max() 
    ).map( i =>
      <li 
      className="page-item" key={i}>
      <button 
       style={{
        color :  i === current ? "blue" : "black"
      }}
      onClick={() => paginate(i)} className="page-link">
        {i}
      </button>
      </li>
    ) : ""
  

  )
}

const Pagination = ({
  candidatsPerPage,
  totalCandidats,
  paginate, 
  current,
  previousPage,
  nextPage,
  lastPage, 
  firstPage
}) => {
 

  const nbPages = Math.ceil(totalCandidats/candidatsPerPage);
  const pages = [];

  for (let index = 1; index <= nbPages; index++) {
    pages.push(index)
  }


  return (
    <nav>
      <ul className="pagination justify-content-center">
        
        
        <li 
            style={{

              paddingRight: '1em'
  
          }}
        className="page-item">
          <button onClick={() => firstPage()} className="page-link">
            First
          </button>
        </li>





        <li 
    
        className="page-item">
          <button onClick={() => previousPage()} className="page-link">
            {"<"}
          </button>
        </li>


          <Pages nbPages={nbPages} 
                 paginate={paginate}
                 current={current} 
                 candidatsPerPage={candidatsPerPage} 
                 nbPageShow={10} 

                 />      


        <li className="page-item">
          <button onClick={() => nextPage()} className="page-link">
            {">"}
          </button>
        </li>

        <li 
        style={{
          paddingLeft: '1em'
        }}
        className="page-item">
          <button onClick={() => lastPage()} className="page-link">
            Last
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
