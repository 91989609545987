// import React, {useState} from 'react'
// import Avatar from '@material-ui/core/Avatar';
// import Button from '@material-ui/core/Button';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import TextField from '@material-ui/core/TextField';
// // import FormControlLabel from '@material-ui/core/FormControlLabel';
// // import Checkbox from '@material-ui/core/Checkbox';
// import Link from '@material-ui/core/Link';
// import Paper from '@material-ui/core/Paper';
// import Box from '@material-ui/core/Box';
// import Grid from '@material-ui/core/Grid';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
// import Typography from '@material-ui/core/Typography';
// import { makeStyles } from '@material-ui/core/styles';
// import {authenticationService} from "../services";
// import {useHistory} from "react-router-dom";
// import { Message } from 'semantic-ui-react';
// // import Loader from "../components/portail/Loader";

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
//       <Link color="inherit" href="https://www.agpassurance.ca/">
//         2020 AGP Assurance
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

// const useStyles = makeStyles((theme) => ({
//   root: {
//     height: '100vh',
//   },
//   image: {
//     backgroundImage: 'url(https://source.unsplash.com/random)',
//     backgroundRepeat: 'no-repeat',
//     backgroundColor:
//       theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//   },
//   paper: {
//     margin: theme.spacing(8, 4),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//   },
//   avatar: {
//     margin: theme.spacing(1),
//     backgroundColor: theme.palette.secondary.main,
//   },
//   form: {
//     width: '100%', // Fix IE 11 issue.
//     marginTop: theme.spacing(1),
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2),
//   },
// }));

// export default function Login() {

//   const classes = useStyles();

//   const [loading, setLoading] = useState(false)
//   const [error, setError] = useState(null)

//   const [username, setUsername] = useState("")
//   const [email, setEmail] = useState("")
//   const [password, setPassword] = useState("")

//   let history = useHistory()

//   function handleConnection(e) {

//       setLoading(true)
//       e.preventDefault()
//       authenticationService.login(username, email, password)
//           .then(res => {
//             if (res.status === 200) {
//               setLoading(false)
//               history.push('/careers')
//               // TODO: MAKE AUTO REFRESH
//               window.location.reload(true)
//             }
//       })
//           .catch(error => {
//           setLoading(false)
//           setError(error.message || error)
//       })
//   }

//   if (authenticationService.isAuthenticated) {
//       history.push('/careers')
//   }

//   return (

//     <Grid container component="main" className={classes.root}>
//       <CssBaseline />
//       <Grid item xs={false} sm={4} md={7} className={classes.image} />
//       <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
//         <div className={classes.paper}>
//           <Avatar className={classes.avatar} style={{backgroundColor: "orange"}}>
//             <LockOutlinedIcon style={{backgroundColor: "orange"}}/>
//           </Avatar>
//           <Typography component="h3" variant="h5" >
//             Bienvenue sur le portail
//           </Typography>
//           <Typography component="h1" variant="h5" style={{fontWeight: 'bold'}}>
//             AGP Assurance
//           </Typography>
//           <form className={classes.form} noValidate>

//             {error && <Message negative style={{fontWeight: 'bold'}}> Le mot de passe ou le nom d'utilisateur est invalide! </Message> }

//             <TextField
//               variant="outlined"
//               margin="normal"
//               required
//               fullWidth
//               id="username"
//               label="Nom d'utilisateur"
//               name="username"
//               autoComplete="username"
//               value={username}
//               onChange={e => setUsername(e.target.value)}
//               autoFocus
//             />

//             <TextField
//               variant="outlined"
//               margin="normal"
//               required
//               fullWidth
//               id="email"
//               label="Adresse courriel"
//               name="email"
//               autoComplete="email"
//               onChange={e => setEmail(e.target.value)}
//               autoFocus
//             />

//             <TextField
//               variant="outlined"
//               margin="normal"
//               required
//               fullWidth
//               name="password"
//               label="Mot de passe"
//               type="password"
//               id="password"
//               onChange={e => setPassword(e.target.value)}
//               autoComplete="current-password"
//             />

//             {/* <FormControlLabel
//               control={<Checkbox value="remember" color="primary" />}
//               label="Remember me"
//             /> */}
//             <Button
//               type="submit"
//               fullWidth
//               variant="contained"
//               color="primary"
//               className={classes.submit}
//               onClick={handleConnection}
//               disabled={loading}
//             >
//               Se connecter
//             </Button>
//             {/* <Grid container>
//               <Grid item xs>
//                 <Link href="#" variant="body2">
//                   Forgot password?
//                 </Link>
//               </Grid>
//               <Grid item>
//                 <Link href="#" variant="body2">
//                   {"Don't have an account? Sign Up"}
//                 </Link>
//               </Grid>
//             </Grid> */}
//             <Box mt={5}>
//               <Copyright />
//             </Box>
//           </form>
//         </div>
//       </Grid>
//     </Grid>
//   );
// }

import React, { useState } from "react";
import { Button, Form, Grid, Image, Message, Segment } from "semantic-ui-react";
import { NavLink, useHistory } from "react-router-dom";
import { authenticationService } from "../services";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  let history = useHistory();

  function handleConnection(e) {
    setLoading(true);
    e.preventDefault();
    authenticationService
      .login(username, email, password)
      .then((res) => {
        setLoading(false);
        // TODO: MAKE AUTO REFRESH
        window.location.reload(true);
        history.push("/careers");
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message || error);
      });
  }

  if (authenticationService.isAuthenticated) {
    history.push("/careers");
  }

  return (
    <div>
      {error && <Message negative message={error} />}
      <Grid
        textAlign="center"
        style={{ height: "100vh", backgroundColor: "#E6E5E4" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <NavLink to="/">
            <Image
              style={{ marginBottom: "20px", marginLeft: "15px" }}
              src="https://gallery.mailchimp.com/10d310a71fa47b547a2f36940/images/5e42911b-546a-49ea-a2a0-f2f8ec620af2.png"
            />
          </NavLink>
          <Form size="large">
            <Segment stacked>
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder="Nom d'utilisateur"
                value={username}
                type="text"
                onChange={(e) => setUsername(e.target.value)}
              />
              <Form.Input
                fluid
                icon="mail"
                iconPosition="left"
                placeholder="Adresse courriel"
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Mot de passe"
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />

              <Button
                color="orange"
                fluid
                size="large"
                onClick={handleConnection}
                loading={loading}
                disabled={loading}
              >
                Connectez-vous
              </Button>
            </Segment>
          </Form>
          {/* <Message>
            Vous n'avez pas compte? <NavLink to="/">Cliquez ici</NavLink>
          </Message> */}
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default Login;
