import React from 'react';
import Horaire from "../components/Horaire"
import NavBar from "../components/NavBar";
import FooterWeb from "../components/FooterWeb";
import zIndex from '@material-ui/core/styles/zIndex';
import "./template.css";


import { ReactComponent as MapIcon } from "../assets/svg/map.svg"





export default () => {
    return (


        <React.Fragment>
        
        <NavBar colorClass="color-nav" />

            <div 
            id="map"
            style={{ backgroundColor: '#73727226', opacity:'0.7' ,width: '100%' }}>
                    <div
                       style={{
                           borderRadius:'5px',
                        height:'500px',
                        width:'100%',
                        boxShadow: 'inset 4px 4px 40px 12px white',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '2000px',
                        backgroundImage: 'url("https://mcusercontent.com/10d310a71fa47b547a2f36940/images/6587407a-a7d8-1378-1134-92ca963e87e0.png")'
                        }}
                    >

                    </div>
                </div>

                <Horaire style={{
                    top:'170px',
                    left:'50px', 
                    backgroundColor: 'white',
                    width: '40%',
                    maxWidth: '500px',
                    minWidth: '300px',
                    position: 'absolute',
                    padding: '20px',
                    boxShadow: "0 0 10px 1px rgb(0 0 0 / 30%)",
                    borderRadius: '2px'


                }} />



                <a href="https://www.google.com/maps/place/AGP+Assurance/@46.8428435,-71.2818888,17z/data=!3m1!4b1!4m5!3m4!1s0x4cb897eb5de43313:0x67774fea4e18555!8m2!3d46.8427487!4d-71.279598">

                <MapIcon className="map-icon" style={{ position: 'absolute', left: '990px', top: '260px' }} strokeOpacity='0.8' strokeWidth='10px' fill='#f29d1d' height={80} />
                </a>
                

                
      
            <section
             id="nous-joindre"
             style={{ backgroundColor: 'white', marginTop:'25px' ,padding:'20px', textAlign:'justify'  }}>
                    <div className="section-title">
                        <h2 style={{ fontSize: "35px", color: "orange" }}>Nous joindre</h2>
                    </div>

                    <div style={{display:'flex', flexWrap:'wrap',justifyContent:'space-evenly', maxWidth:'600px', marginLeft:'auto', marginRight:'auto'}}>

                        <div style={{marginBottom: '15px'}}> 
                            <i style={{fontSize:'2em'}}  class="icofont-google-map"></i> 
                            <span style={{fontWeight:'bold', color:'black'}}>Adresse</span> 
                            <p style={{marginBottom: '0'}}>320-725,</p> 
                            <p style={{marginBottom: '0'}}>Boulevard Lebourgneuf,</p>
                            <p style={{marginBottom: '0'}}>Québec, QC</p>
                            <p style={{marginBottom: '0'}}>G2J 0C4</p>
                        </div>
                        

                        <div>
                            <div style={{marginBottom: '15px'}}>
                                <p style={{marginBottom: 0}}>
                                    <i style={{fontSize:'2em'}} class="icofont-phone"></i> <span style={{fontWeight:'bold', color:'black'}}>Téléphone</span> 
                                </p> 
                                <p>
                                1 866-629-3686
                                </p>
                            </div>

                        
                                <div>
                                    <p style={{marginBottom: 0}}>
                                    <i class="icofont-ui-email"></i> <span style={{fontWeight:'bold', color:'black'}}>Courriel</span> 
                                    </p> 
                                    <p style={{marginBottom: 0}}>
                                    <a style={{color: '#f29d1d'}} href="mailto:info@agpassurance.ca">info@agpassurance.ca</a>
                                    </p>
                                </div>
                        </div>

                    </div>


                </section>
                <FooterWeb />
        </React.Fragment>


    )
}