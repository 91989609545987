import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { authenticationService } from "../services";

const Layout = ({ children }) => {
  return (
    <div>
      {authenticationService.isAuthenticated ? (
        <div>
          <Header />
          {children}
          <Footer />
        </div>
      ) : (
        <div>{children}</div>
      )}
    </div>
  );
};

export default Layout;
