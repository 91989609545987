import React from "react";
import { Row, Col } from "react-bootstrap";
import { Segment, Image } from 'semantic-ui-react';
import { Divider } from "semantic-ui-react";


const PolitiqueConfidentialite = () => {

    document.body.style = 'background: #F1F1F1;';
   


  return (

    <section id="contact" className="ptb-100">
        <div className="container" style={{ maxWidth: '710px'}}>
            <Row>
                <Col xs={5} md={5} lg={5}>
                    <Image href='https://www.agpassurance.ca/' style={{marginLeft: '20px'}}src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/BeeProAgency/491756_472251/AGP-Logo-RVB-Noir.png"  />
                </Col>
                <Col>
                    <Image href='https://www.linkedin.com/company/agp-assurance/' target='_blank' style={{width: '32px', height: '32px'}} className="ui small right floated image" src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/t-only-logo-dark-gray/linkedin@2x.png"  />
                    <Image href='https://www.instagram.com/agpassurance/' target='_blank' style={{width: '32px', height: '32px'}}  className="ui small right floated image" src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/t-only-logo-dark-gray/instagram@2x.png"  />
                    <Image href='https://www.facebook.com/agpassuranceqc/' target='_blank' style={{width: '32px', height: '32px'}} className="ui small right floated image" src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/t-only-logo-dark-gray/facebook@2x.png"  />
                </Col>
            </Row>

            <Segment style={{backgroundColor: 'white', borderTopLeftRadius: '25px', borderTopRightRadius: '25px', paddingLeft: '40px', paddingRight: '40px'}} className='mt-5' >

               
            <div>

                <h1 className='mt-3'>Introduction</h1>

                <p style={{textAlign: 'justify'}}>
                    Devant le développement des nouveaux outils de communication, 
                    il est nécessaire de porter une attention particulière à la protection de la vie privée. 
                    C'est pourquoi, nous nous engageons à respecter la confidentialité des renseignements personnels que nous collectons.
                </p>

                <Divider />

                <h1>Collecte des renseignements personnels</h1>

                <p>Nous collectons les renseignements suivants :</p>
                <p>- Nom</p>
                <p>- Prénom</p>
                <p>- Adresse électronique</p>
                <p>- Numéro de téléphone / télécopieur</p>
                <p>- Date de renouvellement</p>

                <p style={{textAlign: 'justify'}}>
                    Les renseignements personnels que nous collectons sont recueillis au travers de formulaires et grâce à 
                    l'interactivité établie entre vous et notre site Web. Nous utilisons également, comme indiqué dans la section suivante, 
                    des fichiers témoins et/ou journaux pour réunir des informations vous concernant.
                </p>

                <Divider />

                <h1>Formulaires et interactivité:</h1>

                <p>Vos renseignements personnels sont collectés par le biais de formulaire, à savoir :</p>
                <p>Formulaire d'inscription au site Web</p>
                <p>Concours</p>
                <p>Nous utilisons les renseignements ainsi collectés pour les finalités suivantes :</p>
                <p>- Informations / Offres promotionnelles</p>
                <p>- Contact</p>
                <p>Vos renseignements sont également collectés par le biais de l'interactivité pouvant s'établir entre vous et notre site Web et ce, de la façon suivante:</p>
                <p>- Statistiques</p>
                <p>- Contact</p>
                <p>Nous utilisons les renseignements ainsi collectés pour les finalités suivantes :</p>
                <p>- Forum ou aire de discussion</p>
                <p>- Correspondance</p>
                <p>- Informations ou pour des offres promotionnelles</p>

                <Divider />

                <h1>Fichiers journaux et témoins</h1>

                <p style={{textAlign: 'justify'}}>
                    Nous recueillons certaines informations par le biais de fichiers journaux (log file)
                    et de fichiers témoins (cookies). Il s'agit principalement des informations suivantes :
                </p>

                <p>- Adresse IP</p>
                <p>- Pages visitées et requêtes</p>
                <p>- Heure et jour de connexion</p>
                <p>Le recours à de tels fichiers nous permet :</p>
                <p>- Amélioration du service et accueil personnalisé</p>
                <p>- Profil personnalisé de consommation</p>
                <p>- Statistique</p>

                <Divider />

                <h1>Partage des renseignements personnels</h1>

                <p style={{textAlign: 'justify'}}>
                    Nous nous engageons à ne pas commercialiser les renseignements personnels collectés. 
                    Toutefois, il nous arrive de partager ces informations avec des tiers pour les raisons suivantes :
                </p>

                <p style={{textAlign: 'justify'}}>
                    Si vous ne souhaitez pas que vos renseignements personnels soient communiqués à des tiers, 
                    il vous est possible de vous y opposer au moment de la collecte ou à tout moment par la suite, 
                    comme mentionné dans la section suivante.
                </p>

                <Divider />

                <h1>Droit d'opposition et de retrait</h1>

                <p style={{textAlign: 'justify'}}>
                    Nous nous engageons à vous offrir un droit d'opposition et de retrait quant à vos renseignements personnels.
                    Le droit d'opposition s'entend comme étant la possiblité offerte aux internautes de refuser que leurs renseignements 
                    personnels soient utilisées à certaines fins mentionnées lors de la collecte.
                </p>

                <p style={{textAlign: 'justify'}}>
                    Le droit de retrait s'entend comme étant la possiblité offerte aux internautes de demander
                    à ce que leurs renseignements personnels ne figurent plus, par exemple, dans une liste de diffusion.
                </p>

                <p>Pour pouvoir exercer ces droits, vous pouvez :</p>
                <p><b>Code postal :</b> 725 boulevard Lebourgneuf, Québec G2J 0C4</p>
                <p><b>Courriel :</b> maxime-morin.gagnon@agpassurance.ca</p>
                <p><b>Téléphone :</b> 18666293686</p>
                <p><b>Section du site web :</b> www.agpassurance.ca/</p>

                <Divider />

                <h1>Droit d'accès</h1>

                <p style={{textAlign: 'justify'}}>
                    Nous nous engageons à reconnaître un droit d'accès et de rectification aux personnes concernées désireuses de consulter, 
                    modifier, voire radier les informations les concernant.
                </p>
                <p>L'exercice de ce droit se fera :</p>
                <p><b>Code postal :</b> 725 boulevard Lebourgneuf, Québec G2J 0C4</p>
                <p><b>Courriel :</b> maxime-morin.gagnon@agpassurance.ca</p>
                <p><b>Téléphone :</b> 18666293686</p>
                <p><b>Section du site web :</b> www.agpassurance.ca/</p>

                <Divider />

                <h1>Sécurité</h1>

                <p style={{textAlign: 'justify'}}>
                    Les renseignements personnels que nous collectons sont conservés dans un environnement sécurisé. 
                    Les personnes travaillant pour nous sont tenues de respecter la confidentialité de vos informations.
                </p>

                <p>Pour assurer la sécurité de vos renseignements personnels, nous avons recours aux mesures suivantes :</p>

                <p>- Gestion des accès - personne autorisée</p>
                <p>- Gestion des accès - personne concernée</p>
                <p>- Sauvegarde informatique</p>
                <p>- Identifiant / mot de passe</p>

                <p style={{textAlign: 'justify'}}>
                    Nous nous engageons à maintenir un haut degré de confidentialité en intégrant les dernières 
                    innovations technologiques permettant d'assurer la confidentialité de vos transactions. 
                    Toutefois, comme aucun mécanisme n'offre une sécurité maximale, une part de risque est toujours 
                    présente lorsque l'on utilise Internet pour transmettre des renseignements personnels.
                </p>

            </div>

            </Segment>

            <div className="container">

                <div className='mt-4 mb-2'>
                    <Image style={{height: '68px', width: '120px', marginRight: 'auto', marginLeft: 'auto'}} src='https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/BeeProAgency/491756_472251/AGP-Logo-RVB-Noir.png'   />
                </div>

                <p style={{textAlign: 'center', color: 'black', marginBottom: '-5px'}} >725, boulevard Lebourgneuf, bureau 320 </p>
                <p style={{textAlign: 'center', color: 'black'}} >Québec G2J 0C4</p>
            </div>

        </div>
    </section>
  
  );
};

export default PolitiqueConfidentialite;



