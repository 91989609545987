import React from "react";
import { useHistory} from "react-router-dom";


function  BannerSuccess() {

    let history = useHistory();

    function goToHome(){

        history.push('/')
    }

    return (
      <React.Fragment>
        <div id="home" className="main-banner item-bg-two">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="hero-content">
                  <h1>Votre rendez-vous a bien été <span style={{color: 'orange'}}>enregistré!</span></h1>

                  <button onClick={goToHome} className="btn btn-primary">
                     Quitter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}


export default BannerSuccess;
