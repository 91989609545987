import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import { StateProvider } from "./StateProvider";
import reducer, { initialState } from "./reducer";

import App from "./App";

ReactDOM.render(
  <BrowserRouter basename={"/"}>
    <StateProvider initialState={initialState} reducer={reducer}>
      <Route path="/" component={App} />
    </StateProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
