import React, { useState, useRef } from "react";
import { Form, Input, Button, Segment, Label } from 'semantic-ui-react'
import "./bannierecentrale.css"
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import axios from 'axios';
import { api } from "../../../api";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Regx, testInput} from "../../../utils/regx"
import {ErrorLabel, handleOnChange, validateAll} from "../../../utils/error"


const moduleStyle = {
    section: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center',
        minHeight: '70vh',
        marginBottom: 20
    },

    wrapper:{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center', 
    },

    div:{
        textAlign: 'center', 
        flex: 0.5, 
    },

    divLeft:{ 
        padding: '3vh'

   },

    divRight: {
        padding: '3vh'
    }
}







const BanniereCentrale = () => {

    const [loading, setLoading] = useState(false)
    const [date, setNewDate] = useState(new Date())
    const [email, setEmail] = useState(null)
    const [openError, setOpenError] = useState({ error: false, msg:"" });
    const [openSuccess, setOpenSuccess] = useState(false);
    
    const [models, setModels] = useState({
        "first_name"  : {
            isValid : (value) => { return testInput(value, Regx.ALPHA)},
            msg : "",
            value : "",
            key : "first_name",
            errorMsg : "Un prénom ne peut contenir que des lettres",
            required : true
        }, 
        "last_name"  : {
            isValid : (value) => { return testInput(value, Regx.ALPHA)},
            msg : "",
            value : "",
            key : "last_name",
            errorMsg : "Un nom de famille ne peut contenir que des lettres", 
            required : true

        }, 
        "phone_number" : {
            isValid : (value) => { return testInput(value, Regx.PHONE_NUMBER)},
            msg : "",
            value : "",
            key : "phone_number",
            errorMsg : "Ce numéro de téléphone est invalide (ex: 1234567891)",
            required: true

        },
        "email" : {
            isValid : (value) => { return testInput(value, Regx.EMAIL)},
            msg : "",
            value : "",
            key : "email",
            errorMsg : "Cette adresse courriel est invalide",
            required : true
        }
    })


    
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpenError(false)
        setOpenSuccess(false);
      };

    function Alert(props) {

        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }


    const handleSubmit = e => {

        const form = e.currentTarget
        setLoading(true);

        const isValid = validateAll(models, setModels)
        console.log(isValid)
        if(!isValid){
            setLoading(false);
            setOpenError({ error: true, msg: "Veuillez corriger les champs en erreur" })
            return false
        }



        const formData = new FormData()
        formData.append("first_name", models.first_name.value)
        formData.append("last_name", models.last_name.value)
        formData.append("email", models.email.value)
        formData.append("phone_number", models.phone_number.value)
        formData.append("callback_date", moment(date).format("YYYY-MM-DD HH:mm"))

        setLoading(true);
        
        axios
            .post(api.lead.create, formData,{
                headers: {
                    "Content-type": "multipart/form-data"
                }
            })
            .then(async res => {
                if (res.status === 201) {
                    setOpenSuccess(true)
                    setLoading(false);

                 }
            })
            .catch((err, data) => {

                setLoading(false)
                console.log(err.response)

                setOpenError({ error: true, msg: "Une erreur est survenue. Veuillez réessayer plus tard." })
                
            })
    }

    return (
        <section id="cotation" style={moduleStyle.section}>
            <div className="wrapper" style={moduleStyle.wrapper}>
                <div className="border-divider-left" style={moduleStyle.divLeft}>

                <h2 style={{textAlign: 'center', marginBottom: 20, marginTop: 20 }}>Notre site web fait peau neuve!</h2>
                <div className="en-tete-banniere">
                    <p style={{textAlign: 'center', color: 'black', fontSize: '1.5em'}}>
                    <img style={{height:50, width: 60, paddingRight: 10}} src="https://mcusercontent.com/10d310a71fa47b547a2f36940/images/2df556ac-3aea-428b-8fc2-997941bd098f.png" alt="Cône" />
                        En construction
                    <img style={{height: 50, width: 60, paddingLeft: 10}} src="https://mcusercontent.com/10d310a71fa47b547a2f36940/images/2df556ac-3aea-428b-8fc2-997941bd098f.png" alt="Cône" />
                    </p>
                    </div>
                </div>
                <div className="border-divider-right" style={moduleStyle.divRight}>
                    <p>Pendant ce temps, indiquez-nous la plage qui vous conviendrait le mieux
                    et un agent prendra contact avec vous.
                    </p>
                    <Form onSubmit={handleSubmit} >
                        <Form.Group widths='equal'>


                        <Form.Field
                                className={models.first_name.msg !== "" ? "error" : ""}
                                name="first_name"
                                id='form-input-control-first-name'
                                control={Input}
                                value={models.first_name.value}
                                label={()=> {
                                    return(<ErrorLabel 
                                        errorMsg={models.first_name.msg} 
                                        text="Prénom" 
                                        />)
                                }}
                                placeholder='Prénom'
                                onChange={e => {handleOnChange(models, setModels, models.first_name, e) }}
                                style={{order: 2}}
                            />
                            


                            <Form.Field
                                className={models.last_name.msg !== "" ? "error" : ""}
                                id='form-input-control-last-name'
                                value={models.last_name.value}
                                name="last_name"
                                label={()=> {
                                    return(<ErrorLabel 
                                        errorMsg={models.last_name.msg} 
                                        text="Nom" 
                                        />)
                                }}
                                control={Input}
                                placeholder='Nom'
                                onChange={e => {handleOnChange(models, setModels,models.last_name, e) }}
                                style={{order: 1}}
                            />
                
                        </Form.Group>

                        <Form.Group widths='equal'>
                            <Form.Field
                                //required
                                className={models.email.msg !== "" ? "error" : ""}
                                id='form-input-control-email'
                                value={models.email.value}
                                name="email"
                                control={Input}
                                label={()=> {
                                    return(<ErrorLabel errorMsg={models.email.msg} text='Adresse courriel' />)
                                }}
                                placeholder='Adresse courriel'
                                onChange={e => {handleOnChange(models, setModels,models.email, e) }}


                            />

                            <Form.Field
                                //required
                                id='form-input-control-phone-number'
                                className={models.phone_number.msg !== "" ? "error" : ""}
                                value={models.phone_number.value}
                                name="phone_number"
                                control={Input}
                                label={()=> {
                                    return(<ErrorLabel errorMsg={models.phone_number.msg} text='Numéro de téléphone' />)
                                }}
                                onChange={e => {handleOnChange(models, setModels,models.phone_number, e) }}
                                
                                placeholder='Numéro de téléphone'
                            />
                        </Form.Group>

                        <p>Choisissez le moment qui vous convient le mieux!</p>

                     
                            <Form.Group  

                            className="required field" 
                            
                            widths='equal'>
                            
                            <div 
                            
                            className='required field'
                            
                            >
                            <label style={{display:'block', width: '100%'}}>Date</label>
                            
                          
                            <DatePicker  
                            className="ui input"
                        
                            showTimeSelect
                                            timeFormat="HH:mm"selected={date} 
                                            onChange={date => { 
                                            setNewDate(date)
                                            }} 
                                            
                                            />
                            </div>
                            </Form.Group>
                   

                        <Button
                            inverted
                            loading={loading}
                            disabled={loading}
                            type='submit'
                            color='orange'
                            inverted 
                            // control={Button}
                            content='Rappelez-moi!'
                            style={{display: 'block', margin : 'auto', marginTop: 30}}
                        />

                    </Form>
                    <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="success">
                                Merci! Les informations ont bien été enregistrées!
                            </Alert>
                        </Snackbar>

                        <Snackbar open={openError.error} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="Error">
                                {openError.msg}
                            </Alert>
                        </Snackbar>

                </div>
            </div>
        </section>
    )
}


export default BanniereCentrale