import React, { useState, useEffect } from "react";
import { api } from "../../api";
import { authAxios } from "../../services/authentications.services";
import { Button, Form, Dropdown, Input, Label } from "semantic-ui-react";
import ReactDataGrid from 'react-data-grid';
import {ErrorModal, ConfirmModal, SuccessModal, InfoModal}  from "./Modals"


const columns = [
{
    key :"is_selected", name: "Sélectionner"
  
},
{
  key: "foniva_agent_dbid", name: "DBID", width: '10%'
},
{
  key:"foniva_username", name: "Nom d'utilisateur"
},
{
  key:"poste", name: "Poste", width: '20%'
}
]


const skillColumns =[

  {key : "dbid", name : "DBID"}, 

  {key : "name", name : "Skill"}

]
  
const POSTE  = [
   "all",
   "Conseiller",
   "Contact",
   "Agent de saisie"
]






export default ({style}) => {

  const addSkill = (agent, skill) =>{

    authAxios.post(api.foniva.skills.set, {
       skill_name : skill.name, agent_username :  agent.foniva_username  
    })
    
  } 

  const removeSkill = (agent, skill) => {
    authAxios.post(api.foniva.skills.unset, {
      skill_name : skill.name, agent_username :  agent.foniva_username  
   })

  }

  const [agents, setAgents] = useState([]) 
  const [agentsView, setAgentsView] = useState([])
  const [lastSelectAgents, setLastSelectAgents] = useState({
    foniva_username :"", 
    skills : []
  })
  const [selectAgents, setSelectAgents] = useState([])
  const [skills, setSkills] = useState([]) 
  const [selectSkill, setSelectSkill] = useState({name : "", dbid: "", length: ""})
  const [showModal, setShowModal] = useState(false)
  const [showErrorModalMsg, setShowErrorModalMsg] = useState("")
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [showModalSuccess, setShowModalSuccess] = useState(false)
  const [loaderMsg, setLoaderMsg] = useState([])

  const mapPoste = (agents) => agents.sort((a,b) => a.poste - b.poste).map(agent => {
    return {
      ...agent,
      poste : POSTE[agent.poste],
      is_selected : <CheckBox agent={agent} checked={false} />
    }})
  
  const filterAgentByPoste = (agents, poste) => agents.filter(agent =>   poste == 0 ||  agent.poste === POSTE[poste])
  const CheckBox = ({checked, agent, style}) => (
    <div style={{...style, cursor: 'pointer',width: '100%', textAlign: 'center'}}>
            <input onClick={(e) => {
              if(e.target.checked){
                afficherSkill(agent)
                selectionnerAgent(agent)
              }else{
                afficherSkill(agent)
                unSelectAgent(agent)
              }
            }} checked={checked}  type="checkbox" />
    </div>)
  
  const afficherSkill = (agent) => {setLastSelectAgents(agent)}
  const selectAllAgents = () => {
    setAgentsView(oldState => oldState.map(agent => { 
      return {...agent,  is_selected : <CheckBox agent={agent} checked={true} />
    } 
  }))

    setSelectAgents(oldState => {
      let newState = [...oldState]
      
      agentsView.forEach(agent => {
        if(newState.findIndex(e => e.foniva_agent_dbid === agent.foniva_agent_dbid  ) === -1){
          newState.push(agent)
        }
      })

      return newState
    })

  }

  const selectionnerAgent = (agent) => {

    setAgentsView(oldState =>{
      let newState = [...oldState]
      let index = oldState.findIndex((elem) => agent.foniva_agent_dbid === elem.foniva_agent_dbid)
  
      if(index > -1){
        newState[index].is_selected = <CheckBox agent={agent} checked={true} />
      }
      return newState
    })

    setSelectAgents(oldState => {
       if(oldState.every(elem => elem.foniva_username !== agent.foniva_username )){
         
        return  [...oldState, agent]
       }
      return oldState
    })
  }

  const clear = () =>{
    setSelectAgents(oldState => [])
    setAgentsView(oldState => [...oldState].map(a => {return {...a, is_selected : <CheckBox agent={a} checked={false} />}}))
  }

  const unSelectAgent = (agent) => {
   
    setSelectAgents(oldState => {
      
      let newState  = [...oldState]

      let index = oldState.findIndex(elem => elem.foniva_agent_dbid === agent.foniva_agent_dbid)
    
      if(index > -1){
        newState.splice(index, 1)
      }

      console.log(newState)

      return newState
    })

    setAgentsView(oldState => {

      let newState = [...oldState]

      let index = oldState.findIndex(elem => elem.foniva_agent_dbid === agent.foniva_agent_dbid)


      if(index > -1){
        newState[index].is_selected = <CheckBox 
        agent={agent}
        checked={false}
        />
      }
      

      return newState
    })


  }


  useEffect( () => {

    Promise.all([
      authAxios.get(api.foniva.agent.list).then(res => {
        setAgents(mapPoste(res.data))
        setAgentsView(mapPoste(res.data))
      }),

      authAxios.get(api.foniva.skills.list).then(res => {
        setSkills(res.data)
      })]
    )

  }, 

  [authAxios])

    return (

<section style={{...style}}>
  <SuccessModal
  showModalSuccess={showModalSuccess}
  setShowModalSuccess={setShowModalSuccess}
  title="Opération terminée!"
  >

  </SuccessModal>
  <InfoModal
  style={{
    height: '400px', 
    overflowY: 'scroll'
  }}
  setShowModalSuccess={setShowInfoModal}
  showModalSuccess={showInfoModal}
  title="Opération en cours..."
  >
<ul>{loaderMsg.map(msg => <li>{msg}</li>)}</ul>
  </InfoModal>

  <ConfirmModal 
  handleSubmit={() => {
    setShowInfoModal(true)
  }}
  setShowModalConfirm={setShowModal}
   showModalConfirm={showModal} 
   title="Confirmation">
    <p>Êtes-vous bien sur de vouloir ajouter le skill {selectSkill.name}({selectSkill.dbid}) pour {selectAgents.length} agent(s).</p>
  </ConfirmModal>

  <ErrorModal
  setShowErrorModal={setShowErrorModal}
  showErrorModal={showErrorModal}
  title="Erreur"
  >
    <p>{showErrorModalMsg}</p>
  </ErrorModal>
  
  <div
  style={{
    display: 'flex', 
    justifyContent: 'space-between', 
    paddingTop: '10px'
  }}
  >
    <h2>Gestion des skills </h2>
    <p>
      <a 
      className="select"
      style={{
      color: 'orange', 
      fontWeight: 'bold',
      fontSize: '1.4em'
    }} target='_blank' href="https://drive.google.com/file/d/1TDdEDgOg3jMnXsXCHKdKguTa0Qlsk0Vw/view?usp=sharing">
      Besoin d'aide
      <i class="icofont-question"></i>
      </a>

    </p>
  </div>

  

<div style={{  
  

  borderRadius: '5px',
  padding: '10px'
}}>

<div style={{
  backgroundColor: '#92bad599',
  justifyContent: 'space-between', 
  display: 'flex',
  marginBottom: '10px',
  padding: '10px'
  }}>
    <div>
        <h4>Actions: </h4>
                <button 
                onClick={
                  (e) => {
                    if(selectAgents.length === 0){
                      setShowErrorModalMsg("Vous devez sélectionner au moins un agent.")
                      setShowErrorModal(true)
                    }else if(selectSkill.dbid === ""){
                      setShowErrorModalMsg("Vous devez sélectionner au moins un skill.")
                      setShowErrorModal(true)
                    }else{
                      setShowModal(true)
                      
                      selectAgents.forEach((agent) => {
                        addSkill(agent, selectSkill)
                        setLoaderMsg(
                          oldState => [...oldState, `Le skill ${selectSkill.name} est assigné à l'agent ${agent.foniva_username}!`])
                      })

                      setTimeout(() => {
                        setShowInfoModal(false)
                        setShowModalSuccess(true)
                      },3000)


                    }
                  }
                }
                style={{ backgroundColor: '#0262a6', color: 'white', marginRight: '20px'}}>Ajouter un skill</button> 
                <button 
                  onClick={
                      (e) => {
                        if(selectAgents.length === 0){
                          setShowErrorModalMsg("Vous devez sélectionner au moins un agent.")
                          setShowErrorModal(true)
                        }else if(selectSkill.dbid === ""){
                          setShowErrorModalMsg("Vous devez sélectionner au moins un skill.")
                          setShowErrorModal(true)
                        }else{
                          setShowModal(true)
                          
                          selectAgents.forEach((agent) => {
                            removeSkill(agent, selectSkill)
                            setLoaderMsg(
                              oldState => [...oldState, `Le skill ${selectSkill.name} est retiré de l'agent ${agent.foniva_username}!`])
                          })

                          setTimeout(() => {
                            setShowInfoModal(false)
                            setShowModalSuccess(true)
                          },3000)


                        }
                      }
                    }

                style={{ backgroundColor: 'red', color: 'white'}}>Retirer un skill</button>

  <select 

  onChange={(e) => {     
    const { name, value } = e.target;

    setSelectSkill(skills[value])
  }}
  
  style={{padding: '5px', marginLeft: '10px'}}>
    {
      [<option value={-1} disabled selected>Skill...</option> ,...skills.map((skill,index) => <option value={index}>{skill.name}</option>)]
    }
  </select>


    </div>
 </div>


<div
style={{
  backgroundColor: '#92bad599',
  padding: '10px'
}}
>
 <h4>Filtrer par groupe : </h4>
  <select 
  onChange={(e) => {     
    const { name, value } = e.target;

    if(value !== 0){
      setAgentsView( (oldState) => filterAgentByPoste(agents, value))
    }else{
      setAgentsView(agents)
    }


  }
}


    

    

  style={{width: '30%', marginRight: '5px'}}>
  <option value={0}>Tous les agents</option>
    <option value={1}>Conseiller</option>
    <option value={2}>Contact</option>
    <option value={3}>Agent de saisie</option>
  </select>
  <button
  style={{margin: '0 20px'}}

  onClick={selectAllAgents}
  >Sélectionner tous</button> 
  <button
  onClick={clear}

  >Clear</button>
  </div>

 </div>







<div style={{display: 'flex', 
flexDirection: 'row',
width: "100%",
flexWrap: 'wrap', 
margin: '20px'
}}>


    <div
    style={{width: '35%', minWidth: '300px'}}>
      <h4>

        Liste des agents
      </h4>

        <ReactDataGrid

              onRowClick={ (i) => {
                afficherSkill(agentsView[i])
              }}
              columns={columns}
              rows={agentsView}
              rowGetter={i => agentsView[i]}
              rowsCount={3}
              minHeight={350} />


    </div>

  <div
  style={{width: '35%', minWidth: '300px', marginRight: '10px'}}
  >
      <h4>Skills de l'agent sélectionné</h4>
        <ReactDataGrid
              columns={skillColumns}
              rows={lastSelectAgents.skills}
              rowGetter={i => lastSelectAgents.skills[i]}
              rowsCount={3}
              minHeight={150} />
  </div>

  <div style={{  minHeight: '100px', marginBottom: '20px', width: '20%'}}>
<h4>Agent(s) sélectionné(s)</h4>

<ul style={{
  border : '1px solid grey',
  minWidth: '200px',
  height: '350px',
  padding: 5,
  overflowY: 'scroll',

}}>

{selectAgents.map(agent => <li
style={
  {
    listStyle: 'none',
    color: 'black'
  }
}
>{agent.foniva_username}</li>)}

</ul>

</div>
  </div>



      </section>
    )
}