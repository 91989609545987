import React from "react";
import {  Route, Switch, BrowserRouter } from "react-router-dom";
import Page from "react-page-loading";
import PrivateRoute from "./components/PrivateRoute";
import Layout from "./containers/Layout";

//Override Bootstrap CSS
import "./App.css"

//Package CSS & JS
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "animate.css/animate.min.css";

//Template SCSS & CSS Style
import "./assets/css/style.scss";
import "./assets/css/responsive.scss";

//Component Import
import Carrieres from "./pages/Carrieres";
import CareerList from "./containers/CareerList";
import CareerDetail from "./containers/CareerDetail";
import LiveStats from "./containers/LiveStats";

import Particuliers from "./pages/Particuliers";
import Cotation from "./pages/cotation/index";
import Entreprises from "./pages/Entreprises";
import PolitiqueConfidentialite from "./pages/PolitiqueConfidentialite"
import SuccesCarrieres from "./pages/SuccesCarrieres";
import Login from "./containers/Login";
import Horaire from "./pages/NousJoindre"
import AppelDeService from "./pages/AppelDeService"

// Concours
import ConcoursCreate from "./containers/ConcoursCreate"
import RappelCreate from "./containers/RappelCreate"
import ConcoursList from "./containers/ConcoursList"
import Foniva from "./containers/Foniva"
import ConfirmationConcours from "./pages/ConfirmationConcours"
import ConfirmationAppel from "./pages/ConfirmationAppel"
import ReactPixel from 'react-facebook-pixel';
import {Helmet} from "react-helmet";
import ConcoursTwoCreate from './containers/ConcoursCreateTwo'

import CareerCreateIT from "./containers/career/CareerCreateIT"
import NousJoindre from "./pages/NousJoindre";

function App() {

  const options = {
    autoConfig: true,
    debug: false,
  };

  //Facebook Adds
  ReactPixel.init('151105010287192', options);
  ReactPixel.pageView(); 



  return (
    <BrowserRouter>

      <Helmet>
            <meta name="facebook-domain-verification" content="h8r5qwtpapyb9ov6l71v40yrmmidrp" />
      </Helmet>
        
      <Page loader={"bubble-spin"} color={"orange"} size={25}>
        <Switch>
          <Route path="/carrieres" component={Carrieres} />
          <Route path="/carrieres-ti" component={CareerCreateIT} />
          <Route path="/cotation" component={Cotation} />
          <Route path="/entreprises" component={Entreprises} />
          <Route path="/concours-visa" component={ConcoursCreate} />
          <Route path="/confirmation-concours" component={ConfirmationConcours} />
          <Route path="/confirmation-appel-de-service" component={ConfirmationAppel} />
          <Route path="/politique-de-confidentialite" component={PolitiqueConfidentialite} />
          <Route path="/succes" component={SuccesCarrieres} />
          <Route path="/login" component={Login} />
          <Route path="/nous-joindre" component={NousJoindre} />
          <Route path="/appel-de-service" component={AppelDeService} />
 


          <Route exact path="/" component={Particuliers} />
          <Route>
            <Layout>
              <Switch>
                <PrivateRoute exact path="/careers" component={CareerList} />
                <PrivateRoute
                  exact
                  path="/careers/:clientId"
                  component={CareerDetail}
                />
                <PrivateRoute exact path="/concours-list" component={ConcoursList} />
                <PrivateRoute exact path="/livestats" component={LiveStats} />
                <PrivateRoute exact path="/foniva" component={Foniva} />
              </Switch>
            </Layout>
          </Route>
        </Switch>
      </Page>
    </BrowserRouter>
  );
}
export default App;
