import React, { useState, useEffect } from "react";
import SuppressionContact from "./foniva/SuppressionContact"
import SkillManager from "./foniva/SkillManager"
import LogoFoniva from  "../assets/img/foniva.png"
import {authenticationService, authAxios} from "../services/authentications.services"
import {api} from "../api"
import {Tabs, Tab} from "react-bootstrap";



const Logo = ({style}) => {
  
  return (
    <div style={{
          ...style,
            textAlign: 'center',
            marginTop: '5vh'
          }}>
          <img src={LogoFoniva} alt="Logo de Foniva"/>
      </div>)
}

const Foniva = () => {

  const [agentData, setAgentData] = useState([])

  useEffect( () => {

    authAxios.get(api.foniva.agent.list).then(data => {
      setAgentData(data)
      console.log(data)
    })

  }, 
  
  [authAxios])

  return (
    
    <div >
        <Logo/>
        <div  
        style={{margin: '0 10% 10% 10%'}}>
        <Tabs defaultActiveKey="suppression_contact" id="uncontrolled-tab">
          <Tab eventKey="suppression_contact" title="Suppression d'un contact">
            <SuppressionContact />
          </Tab>
          <Tab eventKey="getion_des_skills" title="Gestion des skills">
            <SkillManager />
          </Tab>
        </Tabs>

        </div>
    </div>

)
};


export default Foniva;
