import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { Container, Row, Col } from "react-bootstrap";
class Subscribe extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="subscribe-area">
          <Container className="center">
            <Row xs={1} md={1} lg={2}>
              <Col style={{ textAlign: "left" }}>
                <p style={{ fontSize: "15px" }}>
                  VOUS VOULEZ UNE SOUMISSION RAPIDE AU MEILLEUR PRIX?
                </p>
                <p>
                  PARLEZ À L'UN DE NOS CONSEILLERS <b>MAINTENANT</b>
                </p>
              </Col>
              <Col>
                <Button
                  className="mt-3"
                  variant="primary"
                  href="tel:+18666293686"
                  onClick="tel:+18666293686"
                  size="lg"
                  block
                  style={{
                    backgroundColor: "white",
                    color: "orange",
                    fontSize: "20px",
                  }}
                >
                  1 866 629-3686
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default Subscribe;
