import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Divider } from "semantic-ui-react";
import Message from "../components/portail/Message";
import { api } from "../api";
import { authAxios } from "../services";
import { useStateValue } from "../StateProvider";
import Careers from "../containers/Careers";
import SeachForm from "./SearchForm";
import "./CareerList.css";

const CareerList = () => {
  // eslint-disable-next-line
  const [{ searchResult }, dispatch] = useStateValue();
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await authAxios.get(api.candidats.list);
        console.log(response.data);
        dispatch({
          type: "SEARCH",
          result: response.data,
        });
      } catch (error) {
        setError(error.message);
      }
    }
    fetchData();
  }, [dispatch]);

  return (
    <div className="clientList__table">
      {error && <Message negative message={error} />}
      <Container  className="clientList_container" fluid="md">
        <SeachForm />
        <Divider />
        <Row>
          <Col>
            <Careers />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CareerList;
