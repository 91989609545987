import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel3";
import Icofont from "react-icofont";
import PropTypes from "prop-types";
import VisibilitySensor from "react-visibility-sensor";
import "react-modal-video/css/modal-video.min.css";
import ModalVideo from "react-modal-video";
import { Link } from "react-scroll";



class BannerEntreprises extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    //Home Slides loop start
    const bannerentreprisesdata = this.props.bannerentreprisesData.map(
      (bannerentreprises, index) => (
        <React.Fragment key={index}>
          <div className={bannerentreprises.bgImgClass}>
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <VisibilitySensor>
                    {({ isVisible }) => (
                      <div className="hero-content">
                        <h5
                          className={
                            isVisible
                              ? "animated fadeInDown slow opacityOne"
                              : "opacityZero"
                          }
                        >
                          {bannerentreprises.toptitle}
                        </h5>
                        <h1
                          style={{ fontSize: "35px", display: 'block' }}
                          className={
                            isVisible
                              ? "animated fadeInUp slow opacityOne"
                              : "opacityZero"
                          }
                        >
                          {bannerentreprises.mainlefttitle}{" "}
                          <span
                     
                          >{bannerentreprises.mainspantitle}</span>{" "}
                          {bannerentreprises.mainrighttitle}
                        </h1>
                        <p
                          style={{ fontSize: "29px" }}
                          className={
                            isVisible
                              ? "animated zoomIn Slow opacityOne"
                              : "opacityZero"
                          }
                        >
                          {bannerentreprises.content}
                        </p>

                        <Link href='https://apps.intactinsurance.com/qcc/quick-quote-com/?language=fr&province=QC&origin=WEBBK&sub_broker=0478&organization_source=TARGETMARKETWTB0478' onClick='https://apps.intactinsurance.com/qcc/quick-quote-com/?language=fr&province=QC&origin=WEBBK&sub_broker=0478&organization_source=TARGETMARKETWTB0478' >
                          <button
                            
                            // onClick='https://webrater.appliedsystems.com/WR/Default.aspx?GC=AGPA&LANG=FR&LOB=AUTO'
                            
                            className="btn btn-primary animated fadeInDown slow opacityOne"
                          >
                            <Icofont
                              style={{ fontSize: "20px" }}
                              icon="icofont-car-alt-4"
                            />{" "}
                            véhicules
                          </button>
                        </Link>

                        <Link href='https://apps.intactinsurance.com/quick-quote-cl/app/v1?lang=FR&prov=QC&origin=WEBBK&sub_broker=0478&organization_source=TARGETMARKETWTB0478' onClick='https://apps.intactinsurance.com/quick-quote-cl/app/v1?lang=FR&prov=QC&origin=WEBBK&sub_broker=0478&organization_source=TARGETMARKETWTB0478' >
                          <button
                            style={{ width: '158.81px' }}
                            // onClick={this.openModal}
                            className="btn btn-primary animated fadeInDown slow opacityOne"
                          >
                            <Icofont
                              style={{ fontSize: "20px" }}
                              icon="icofont-briefcase"
                            />{" "}
                              biens
                          </button>
                        </Link>
                        {/* <p className='mt-5'  style={{textAlign: 'center', fontSize: '23px'}} ><a href="tel:+18666293686" style={{color: '#ffffff'}}>1 866 629-3686</a> </p> */}
                      </div>
                    )}
                  </VisibilitySensor>
                </div>
              </div>
            </div>
         
          </div>
        </React.Fragment>
      )
    );
    //Home Slides END
    return (
      <React.Fragment>
        <div id="home">
          <ModalVideo
            channel="youtube"
            isOpen={this.state.isOpen}
            videoId={this.props.bannerentreprisesData[0].videoId}
            onClose={() =>
              this.setState({
                isOpen: false,
              })
            }
          />
          <OwlCarousel
            className="home-slides owl-theme"
            items={1}
            nav={true}
            dots={false}
            loop={true}
            touchDrag={false}
            mouseDrag={false}
            autoplay={true}
            autoplayHoverPause={true}
            animateOut={"slideOutDown"}
            animateIn={"slideInDown"}
            smartSpeed={700}
            autoplayTimeout={8000}
            navText={[
              "<i class='icofont-swoosh-right'></i>",
              "<i class='icofont-swoosh-right'></i>",
            ]}
          >
            {bannerentreprisesdata}
          </OwlCarousel>
        </div>
      </React.Fragment>
    );
  }
}
BannerEntreprises.propTypes = {
  animationImg1: PropTypes.string,
  animationImg2: PropTypes.string,
  animationImg3: PropTypes.string,
  animationImg4: PropTypes.string,
  animationImg5: PropTypes.string,
  animationImg6: PropTypes.string,
  bannerentreprisesData: PropTypes.array,
};
BannerEntreprises.defaultProps = {
  animationImg1: require("../../assets/img/1.png"),
  animationImg2: require("../../assets/img/2.png"),
  animationImg3: require("../../assets/img/1.png"),
  animationImg4: require("../../assets/img/5.png"),
  animationImg5: require("../../assets/img/6.png"),
  animationImg6: require("../../assets/img/7.png"),
  bannerentreprisesData: [
    {
      bgImgClass: "main-banner item-bg-two",
      toptitle: "",
      mainlefttitle: "On protège vos ambitions. Soyez",
      mainspantitle: "PERFORMANTS.",
      mainrighttitle: "",
      content: "Je veux une soumission",
      leftbtn: "Get Started",
      leftbtnlink: "#",
    },
  ],
};
export default BannerEntreprises;
