import React, { Component } from 'react';

//Components List
import NavBar from "../components/NavBar";
import BannerSuccess from "../components/BannerSuccess";
import Footer from "../components/Footer";
import ScrollUpBtn from "../components/ScrollUpBtn";

class SuccesCarrieres extends Component {
    render() {
        return(
            <React.Fragment>
                {/* NavBar: src/components/NavBar */}
                <NavBar />
                {/* NavBar: src/components/banner/BannerOne */}
                <BannerSuccess />
                {/* Subscribe: src/components/Subscribe */}

                <Footer />
                {/* ScrollUpBtn: src/components/ScrollUpBtn */}
                <ScrollUpBtn />
            </React.Fragment>
        );
    }
}
export default SuccesCarrieres;


