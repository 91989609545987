import React, { useState, useEffect } from "react";
import { useStateValue } from "../../StateProvider";

function Modal({style, children, isShow, id}){

    return ( isShow &&
      <div className="modal"
      id={id}
      style={{
        display: "block",
        position : "fixed",
        width: "70%",
        height: '20%',
  
        height: "auto",
        top:"50%",
        left:"50%",
        transform: "translate(-50%,-50%)",
        ...style

  
      }}
      >
     {children}
      </div>
    )
  }
  
  function ErrorModal({
    title, 
    children, 
    showErrorModal, 
    setShowErrorModal, 
    id
  }){
    return <Modal
    id={id}
    style={{
      padding: '20px', 
      border: '2px solid #0262a6',
      backgroundColor: 'white'
    }}
    isShow={showErrorModal}
    >
      <h2>{title}</h2>
      {children}
      <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
      }}>
      <button 
                 onClick={
                  () => {
                    setShowErrorModal(false)
                  }}
    
                 style={{
                   
                   margin: '20px',
                 }}
                 className="btn btn-danger">Ok</button>
  </div>
  
    </Modal>
  }


   function InfoModal({
     style,
    title,
    children, 
    showModalSuccess,
    setShowModalSuccess,
    id
  }){

    return(<Modal
      isShow={showModalSuccess}
        style={{
          padding: '20px', 
          border: '2px solid #0262a6',
          backgroundColor: 'white', 
          ...style
        }}
        id={id}
      >
            <h2>{title}</h2>
            {children}
              <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap'
              }}
              >
         
              </div>
    
      </Modal>)
  }

  
  function SuccessModal({
    title,
    children, 
    showModalSuccess,
    setShowModalSuccess,
    id
  }){
    return(<Modal
    isShow={showModalSuccess}
      style={{
        padding: '20px', 
        border: '2px solid #0262a6',
        backgroundColor: 'white'
      }}
      id={id}
    >
          <h2>{title}</h2>
          {children}
            <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap'
            }}
            >
                 <button 
                 onClick={
                  () => {
                    setShowModalSuccess(false)
                  }}
    
                 style={{
                   margin: '20px'
                 }}
                 className="btn btn-success">Ok</button>
       
            </div>
  
    </Modal>)
  }
  
  function ConfirmModal({
    title,
    children,
    showModalConfirm, 
    setShowModalConfirm, 
    handleSubmit,
    id}){
  
    return(
      <Modal 
      style={{
        padding: '20px', 
        border: '2px solid #0262a6',
        backgroundColor: 'white'
      }}
      id={id} 
    
      isShow={showModalConfirm}>
           <h2>{title}</h2>
           {children}
            <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap'
            }}
            >
                 <button 
                 onClick={
                   
                  () => {
                    setShowModalConfirm(false)
    
                    handleSubmit()
                  }}
    
                 
    
                 style={{
                   margin: '20px'
                 }}
                 className="btn btn-danger">Confirmer</button>
                <button 
                    onClick={
                   
                      () => {
                        setShowModalConfirm(false)
                      }}
                style={{
                      margin: '20px',
                      backgroundColor: 'grey', 
                      color: 'white'
                    }}
    
                className="btn btn-light">Annuler</button>
            </div>
          
      </Modal>
    )
  }

export {
    Modal,
    SuccessModal,
    ConfirmModal, 
    ErrorModal, 
    InfoModal
}