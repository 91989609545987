import React, { Component } from "react";
import PropTypes from "prop-types";
import {Divider, Image} from "semantic-ui-react";
import Icofont from "react-icofont";
import { Link } from "react-scroll";

export class FooterWeb extends Component {
  render() {
    return (
        <React.Fragment>
            <footer className="footer-area">
                <div className="container">

                    <ul className="social-links mr-4" style={{textAlign: 'center'}} >
                      <li><a href="https://www.facebook.com/agpassuranceqc"><Icofont style={{color: 'white', fontSize: '20px', marginRight: '10px', marginLeft: '10px'}} icon="icofont-facebook"/></a></li>
                      <li><a href="https://www.instagram.com/agpassurance/"><Icofont style={{color: 'white', fontSize: '20px', marginRight: '10px', marginLeft: '10px'}} icon="icofont-instagram"/></a></li>
                      <li><a href="https://www.linkedin.com/company/agp-assurance/"><Icofont style={{color: 'white', fontSize: '20px', marginLeft: '10px'}} icon="icofont-linkedin"/></a></li>
                    </ul>

                    <Divider />

                    <div className='mt-4 mb-4'>
                      <Image style={{height: '60px', width: '55', marginRight: 'auto', marginLeft: 'auto'}} src='https://mcusercontent.com/10d310a71fa47b547a2f36940/images/864446e1-c03f-4b99-bb60-83d419015d2c.png'   />
                    </div>

                    <p style={{textAlign: 'center'}} >Québec: 320-725 Boulevard Lebourgneuf, Québec, QC G2J 0C4   |  Amqui: 5-6, Rue Proulx, Amqui, QC G5J 3G2 </p>
                    <p style={{textAlign: 'center'}} className='mt-2'>Copyright <Link href="/login" onClick='/login' style={{color: '#d5d5d5'}}>©</Link> 2021 AGP Assurance   |   Tous droits réservés  | <Link href='/politique-de-confidentialite' onClick='/politique-de-confidentialite' target='_blank' style={{color: '#d5d5d5'}} > Politique de confidentialité </Link>  |   Designed by AGP Lab </p>
                    <p style={{textAlign: 'center'}} className='mt-4'>Notre cabinet a des liens financiers avec l’assureur Intact Assurance et nous faisons principalement affaire avec lui.</p>
                </div>
            </footer>
        </React.Fragment>
    );
  }
}
FooterWeb.PropsTypes = {
  copyrightText: PropTypes.string,
  footercopyrightLink1: PropTypes.string,
  footercopyrightText1: PropTypes.string,
  footercopyrightLink2: PropTypes.string,
  footercopyrightText2: PropTypes.string
};
FooterWeb.defaultProps = {
  copyrightText: "Copyright @ 2019 Exolot. All rights reserved",
  footercopyrightLink1: "#",
  footercopyrightText1: "Terms & Conditions ",
  footercopyrightLink2: "#",
  footercopyrightText2: "Privacy Policy"
};
FooterWeb.PropsTypes = {
  facebooklink: PropTypes.string,
  instagramlink: PropTypes.string,
  linkedinlink: PropTypes.string

};
export default FooterWeb;
