import React, {useState, useRef} from "react";
import { Row, Col } from "react-bootstrap";
import { Form, Input, Button, Segment, Image, Checkbox } from 'semantic-ui-react';
import DatePicker from "react-datepicker";
import moment from "moment";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import axios from 'axios';
import { api } from "../api";
import {history} from "../containers/helpers"
import {Regx, testInput} from "../utils/regx"
import {ErrorLabel, handleOnChange, validateAll} from "../utils/error"



const insurance_type_options = [
    { key: "1", text: "Automobile", value: "Automobile" },
    { key: "2", text: "Habitation", value: "Habitation" }
];

const ConcoursCreate = () => {

    document.body.style = 'background: #F1F1F1;';
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState({ error: false, msg:"" });
   
    



    const [renew_date, setRenewDate] = useState(null)
    const [renewDateError, setRenewDateError] = useState(null)
    const [renew_type, setRenewType] = useState(null)
    const [renewTypeError, setRenewTypeError] = useState(null)
    const [policy, setPolicy] = useState(false);
    const [policyError, setPolicyError] = useState(false);
   
    const btnRef = useRef();
    const [loading, setLoading] = useState(false)



    const [models, setModels] = useState({
        "first_name"  : {
            isValid : (value) => { return testInput(value, Regx.ALPHA)},
            msg : "",
            value : "",
            key : "first_name",
            errorMsg : "Un prénom ne peut contenir que des lettres", 
            required: true
        }, 
        "last_name"  : {
            isValid : (value) => { return testInput(value, Regx.ALPHA)},
            msg : "",
            value : "",
            key : "last_name",
            errorMsg : "Un nom de famille ne peut contenir que des lettres",
            required: true


        }, 
        "phone_number" : {
            isValid : (value) => { return testInput(value, Regx.PHONE_NUMBER)},
            msg : "",
            value : "",
            key : "phone_number",
            errorMsg : "Ce numéro de téléphone est invalide (ex: 1234567891)",
            required: true


        },
        "email" : {
            isValid : (value) => { return testInput(value, Regx.EMAIL)},
            msg : "",
            value : "",
            key : "email",
            errorMsg : "Cette adresse courriel est invalide",
            required: true

        }
    })


    function checkboxValue(){

        if (policy === true){
            setPolicy(false) 

        }else{
            setPolicyError("")
            setPolicy(true)  
        }

    }

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpenError(false)
        setOpenSuccess(false);
      };

    function Alert(props) {

        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    
    const handleSubmit = e => {

            setLoading(true)

            const isValid = validateAll(models, setModels)
            const renewDateIsValid = renew_date !== null 
            const renewTypeIsValid = renew_type !== null
            const policyErrorValid = policy === true

            if(!renewTypeIsValid){
                setRenewTypeError("Ce champ est requis")
            }

            if(!renewDateIsValid){
                setRenewDateError("Ce champ est requis")
            }

            if(!policyErrorValid){
                setPolicyError("Vous devez accepter la politique de confidentialité")

            }

            if(isValid && renewDateIsValid && renewTypeIsValid && policyErrorValid){

                e.preventDefault();
                const formData = new FormData()
                formData.append("first_name", models.first_name.value)
                formData.append("last_name", models.last_name.value)
                formData.append("email", models.email.value)
                formData.append("phone", models.phone_number.value)
                formData.append("renew_date", moment(renew_date).format("YYYY-MM-DD"))
                formData.append("renew_type", renew_type)
                formData.append("concours_name", 'concours_visa_qc_100')
    
                axios
                .post(api.concours.create, formData,{
                    headers: {
                        "Content-type": "multipart/form-data"
                    }
                })
                .then(res => {
                    if (res.status === 201) {
                        setOpenSuccess(true)
                        history.push('/confirmation-concours')
                    }
                })
                .catch(err => {


                   console.log(err.response)
                    let data = err.response.data
                    if("non_field_errors" in data){
                        setOpenError({ error: true, msg: "Erreur, vous avez déjà participé à ce concours." })
                    } else{
                        setOpenError({ error: true, msg: "Erreur, un ou plusieurs champs ne sont pas remplis correctement!" })
                    }
                    setLoading(false)
                })
               
            }else{

                setLoading(false);
                setOpenError({ error: true, msg: "Veuillez corriger les champs en erreur" })
                return false
            }

    }


  return (

    <section id="contact" className="ptb-100">
        <div className="container" style={{ maxWidth: '710px'}}>
            <Row>
                <Col xs={5} md={5} lg={5}>
                    <Image href='https://www.agpassurance.ca/' style={{marginLeft: '20px'}}src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/BeeProAgency/491756_472251/AGP-Logo-RVB-Noir.png"  />
                </Col>
                <Col>
                    <Image href='https://www.linkedin.com/company/agp-assurance/' target='_blank' style={{width: '32px', height: '32px'}} className="ui small right floated image" src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/t-only-logo-dark-gray/linkedin@2x.png"  />
                    <Image href='https://www.instagram.com/agpassurance/' target='_blank' style={{width: '32px', height: '32px'}}  className="ui small right floated image" src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/t-only-logo-dark-gray/instagram@2x.png"  />
                    <Image href='https://www.facebook.com/agpassuranceqc/' target='_blank' style={{width: '32px', height: '32px'}} className="ui small right floated image" src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/t-only-logo-dark-gray/facebook@2x.png"  />
                </Col>
            </Row>

            <Segment style={{backgroundColor: 'white', borderTopLeftRadius: '25px', borderTopRightRadius: '25px', paddingLeft: '40px', paddingRight: '40px'}} className='mt-5' >

                <div>
                    <Image src="https://mcusercontent.com/10d310a71fa47b547a2f36940/images/76370d90-0676-d805-fa71-78f0390ad293.png" />
                </div>

                <div style={{textAlign: 'center'}}>
                    <p style={{color: 'black', fontSize: '24px', lineHeight: "30px"}}>Participez à notre concours et courez la chance de gagner une carte VISA prépayée de <span style={{color: 'orange'}}>100 $</span></p>
                </div>

                <div className='pt-4'>
                    <p style={{color: 'orange', fontSize: '20px'}}>CONDITIONS<span style={{color: '#db2828'}}> *</span></p>
                    <p style={{color: 'black', fontSize: '14px'}}>- Être résident du Québec et avoir 18 ans et plus.</p>
                    <p style={{color: 'black', fontSize: '14px'}}>- Remplir le formulaire de participation.</p>
                </div>

                

                <div className="box" >
                    
                    <Form >

                        {/* first_name & last_name */}
                        <Form.Group widths='equal' >

                            <Form.Field
                                className={models.last_name.msg !== "" ? "error" : ""}
                                required
                                maxLength="20"
                                id='form-input-control-last-name'
                                control={Input}
                                label={()=> {
                                    return(<ErrorLabel 
                                        errorMsg={models.last_name.msg} 
                                        text="Nom" 
                                        />)
                                }}
                                placeholder='Nom'
                                value={models.last_name.value}
                                onChange={e => {handleOnChange(models, setModels, models.last_name, e) }}
                            />

                        </Form.Group>

                        <Form.Group widths='equal' >

                            <Form.Field
                                className={models.first_name.msg !== "" ? "error" : ""}
                                required
                                maxLength="20"
                                id='form-input-control-first-name'
                                control={Input}
                                label={()=> {
                                    return(<ErrorLabel 
                                        errorMsg={models.first_name.msg} 
                                        text="Prénom" 
                                        />)
                                }}
                                placeholder='Prénom'
                                value={models.first_name.value}
                                onChange={e => {handleOnChange(models, setModels, models.first_name, e) }}

                            />

                        </Form.Group>

                        {/* email & phone */}
                        <Form.Group widths='equal' >
                            
                            <Form.Field
                                className={models.email.msg !== "" ? "error" : ""}
                                required
                                maxLength="50"
                                id='form-input-control-email'
                                control={Input}
                                label={()=> {
                                    return(<ErrorLabel 
                                        errorMsg={models.email.msg} 
                                        text="Email" 
                                        />)
                                }}
                                placeholder='Email'
                                value={models.email.value}
                                onChange={e => {handleOnChange(models, setModels, models.email, e) }}
                            />

                        </Form.Group>

                        <Form.Group widths='equal' >

                            <Form.Field
                                className={models.phone_number.msg !== "" ? "error" : ""}
                                required
                                maxLength="10"
                                minLength="10"
                                id='form-input-control-phone'
                                label={()=> {
                                    return(<ErrorLabel 
                                        errorMsg={models.phone_number.msg} 
                                        text="Téléphone" 
                                        />)
                                }}
                                control={Input}
                                placeholder='Téléphone'
                                value={models.phone_number.value}
                                onChange={e => {handleOnChange(models, setModels, models.phone_number, e) }}
                            />

                        </Form.Group>


                        <label style={{ fontWeight: "bold", fontSize: "13px", marginTop: 20}}>
                            Quelle est la date de renouvellement de votre police d'assurance automobile ou habitation?
                            <span style={{ color: 'red'}}> *</span>
                            
                        <p style={{fontSize: '12px'}}>Si vous ne connaissez pas la date exacte, vous pouvez simplement indiquer le premier du mois de votre renouvellement.</p>
                        </label>
                        <Form.Group widths="equal" style={{ marginLeft: "1px",  display: 'flex', flexDirection :'column' }}>
                            
                            <ErrorLabel  
                              text="Date de renouvellement" 
                              errorMsg={renewDateError}
                            />
                           
                            <DatePicker
                            style={{display: 'block'}}
                            className={"error"}
                            placeholderText="Sélectionnez la date..."
                            onChange={(value) => { 
                                setRenewDateError("")
                                setRenewDate(value) 
                            } }
                            selected={renew_date ? new Date(renew_date) : null}
                            locale="fr"
                            timeCaption="Heure"
                            dateFormat="yyyy-MM-dd"
                            />
                            
                        </Form.Group>

                        <Form.Field className='mt-3'>

                            <ErrorLabel  
                              text="Type d'assurance?" 
                              errorMsg={renewTypeError}
                            />

                            <Form.Dropdown
                                    required
                                    placeholder='Choisir'

                                    selection
                                    onChange={(e, result)  => {
                                        setRenewTypeError("")
                                        setRenewType(result.value)}}
                                    options={insurance_type_options}
                                    // value={renew_type}
                                />

                        </Form.Field>

                        <Form.Field className='mt-4'>
                        <ErrorLabel  
                              text="Politique de confidentialité" 
                              errorMsg={policyError}
                            />

                        <Row>
                            <Col xs={1} md={1} lg={1}>

                                <Checkbox 
                                    toggle
                                    checked ={policy}
                                    onChange={(value)=> {
                                        checkboxValue(value)
                                    }}
                                />
                            </Col>

                            <Col xs={11} md={11} lg={11}>
                                <p> <span style={{color: '#db2828'}}>* </span>confirmez que vous avez lu et accepté la <a style={{color: 'orange'}} href='/politique-de-confidentialite' target='_blank'>Politique de confidentialité</a>.</p>
                            </Col>
                        </Row>

                        <div className='mt-3'>
                            <p style={{fontSize: '12px', textAlign: 'justify'}} >
                            * En remplissant ce formulaire, vous acceptez que AGP Assurance vous 
                            ajoute à son infolettre et vous contacte par téléphone dans les 90 jours 
                            qui précèdent votre renouvellement d'assurance automobile ou habitation. 
                            Le ou la gagnant(e) sera contacté par téléphone le 20 octobre 2021. Aucun achat requis.
                            </p>
                        </div>


                        <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="success">
                                Votre participation a été enregistré avec succès!
                            </Alert>
                        </Snackbar>

                        <Snackbar open={openError.error} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="Error">
                                {openError.msg}
                            </Alert>
                        </Snackbar>


                        </Form.Field>





                     
                        <div style={{textAlign: 'center'}} className='mt-4 mb-4'>
                            <Button 
                                ref={btnRef}
                                inverted 
                                onClick={handleSubmit}
                                loading={loading}
                                disabled={loading}
                                // type='submit'
                                color='orange'
                                // control={Button}
                                content='Participer'
                                
                            />
                        </div>
                        

                    </Form>

                </div>
            </Segment>

            <div className="container">

                <div className='mt-4 mb-2'>
                    <Image style={{height: '68px', width: '120px', marginRight: 'auto', marginLeft: 'auto'}} src='https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/BeeProAgency/491756_472251/AGP-Logo-RVB-Noir.png'   />
                </div>

                <p style={{textAlign: 'center', color: 'black', marginBottom: '-5px'}} >725, boulevard Lebourgneuf, bureau 320 </p>
                <p style={{textAlign: 'center', color: 'black'}} >Québec G2J 0C4</p>
            </div>







        </div>
    </section>
  
  );
};

export default ConcoursCreate;



