import React, { Component, useState, useEffect } from "react";
import Icofont from "react-icofont";
import PropTypes from "prop-types";
import { Divider } from "semantic-ui-react";
import { Link } from "react-scroll";
import axios from 'axios';
import { api } from "../api"


const FERMETURE_EXCEPTIONNELLE = "Fermeture exceptionnelle"


const HoraireTable = ({data}) => {
    const notes = []

    const body =  (data) => {
        let rows = []

        data.forEach((item, index) => {

            
           const style = {display: 'flex', justifyContent: 'space-between',  padding:'5px', backgroundColor : '#ffffff'}

           if(index === 0){
             style.backgroundColor = '#f9b51c7a'
           }

            let note_super = ""

            if( item.note !== undefined && item.note !== ""){
                note_super = "*".repeat(notes.length + 1)
                notes.push(
                    <p style={{fontStyle:'italic', color:"#f29d1d" }}>
                        {note_super}{item.note}
                    </p>
                )
   
          }

        /*
          if (item.id === 2 ){
              //HARD CODE IN FRONT END
              //REMOVE IT PLS :(
              note_super = "*".repeat(notes.length + 1)
              notes.push(
                  <p style={{fontStyle:'italic', color:"#f29d1d" }}>
                      {note_super}Fermeture exceptionnelle
                  </p>)
          }*/

          /*
          if( (item.note === undefined || item.note === "") && item.id === 5){
            //HARD CODE IN FRONT END
            //REMOVE IT PLS :(
            note_super = "*".repeat(notes.length + 1)
            notes.push(
                <p style={{fontStyle:'italic', color:"#f29d1d" }}>
                    {note_super}Horaire estival
                </p>
            )
          }*/

            let hours = "Fermé"
            if(item.min_hour !== null){

              let min_hour = item.min_hour.split(":").splice(0,2).join(":")
              let max_hour = item.max_hour.split(":").splice(0,2).join(":")


              hours =  min_hour + " - " + max_hour
            }
            
            rows.push(<li style={style}>
                <span> <span style={{color:"#f29d1d" }}>{note_super}</span>{item.day}</span>
                <span>{hours}</span>

            </li>)

           
        })
        return rows
    }

    return (
        <div>

        <ul style={{marginRight: 'auto', marginLeft:'auto', fontSize: '0.9em', padding: 0}}>
            {
                body(data)
            }
        </ul>
        {

            notes.length > 0 ? notes: ""
        }
        </div>
    )
}


function Horaire({style}) {

  const [horaireConseiller, setHoraireConseiller] = useState([]);
  const [horaireCC, setHoraireCC] = useState([]);

  useEffect(() => {
    async function fetchData() {
      
        let response = await axios.get(api.openingTime.conseillers);
        setHoraireConseiller(response.data)

        response = await axios.get(api.openingTime.contacts);
        console.log(response.data)
        setHoraireCC(response.data)
      
    }
    fetchData();
  }, []);


  return ( <React.Fragment >
    <section id="horaire" className="welcome- ptb-100" style={style} >
    <div style={{marginBottom: 0}} className="section-title">
          <h2 style={{ fontSize: "30px", color: "orange" }}>
          <i style={{paddingRight:'6px'}} class="icofont-wall-clock"></i>
          Horaires
          </h2>
          <Divider />
        </div>
    <div className="container">

        <h3 style={{textAlign: 'center', fontSize: '1.5em'}}>Assurance des particuliers</h3>
        
        <HoraireTable data={horaireConseiller} />
   
      </div>

      <div className="container">

      <h3 style={{textAlign: 'center', fontSize: '1.5em'}}>Centre de contact client</h3>

        
        <HoraireTable data={horaireCC} />
   
      </div>
    </section>
  </React.Fragment>)
}



export default Horaire;
