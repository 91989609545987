import React, { Component } from 'react';

//Components List
import NavBar from "../components/NavBar";
import BannerConcours from "../components/BannerConcours";
import Footer from "../components/Footer";
import ScrollUpBtn from "../components/ScrollUpBtn";

class ConfirmationConcours extends Component {
    render() {
        return(
            <React.Fragment>
                {/* NavBar: src/components/NavBar */}
                <NavBar />
                {/* NavBar: src/components/banner/BannerOne */}
                <BannerConcours />
                {/* Subscribe: src/components/Subscribe */}

                <Footer />
                {/* ScrollUpBtn: src/components/ScrollUpBtn */}
                <ScrollUpBtn />
            </React.Fragment>
        );
    }
}
export default ConfirmationConcours;


