  
export default function CareerEmbaucheCandidat(first_name, last_name){ 
    
    
    const email_content = `

    <!doctype html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
  
<h3>Bonjour, ${first_name} ${last_name} vous etes embauché </h3>
</html>
     `
    return email_content

}