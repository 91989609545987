let baseUrl = process.env.REACT_APP_LOCAL_API_URL

switch(process.env.REACT_APP_ENV){
  case "STAGING":
    baseUrl = process.env.REACT_APP_STAGING_API_URL
    break;
  case "PRODUCTION":
    baseUrl = process.env.REACT_APP_PROD_API_URL
    break;
}

console.log("API URL  : " + baseUrl)


export const api = {
  auth: {
    login: `${baseUrl}/dj-rest-auth/login/`,
  },
  dialer: {
    list: `${baseUrl}/api/dialer/`,
    open: `${baseUrl}/api/dialer/open/`,
    update: (dialerId) => `${baseUrl}/api/dialer/${dialerId}/update/`,
  },
  candidats: {
    notes: {
      list  : (clientId) =>  `${baseUrl}/api/careers/note-attachment?career=${clientId}`,
      create : `${baseUrl}/api/careers/note-attachment/create/`,
      delete : (id) =>  `${baseUrl}/api/careers/note-attachment/${id}/delete`
    },
    list: `${baseUrl}/api/careers/`,
    create: `${baseUrl}/api/careers/create/`,
    dialer: `${baseUrl}/api/dialer/`,
    search: `${baseUrl}/api/careers/search/`,
    advancedSearch: `${baseUrl}/api/careers/search/advanced/`,
    retrieve: (clientId) => `${baseUrl}/api/careers/${clientId}/`,
    update: (clientId) => `${baseUrl}/api/careers/${clientId}/update`,
    delete: (clientId) => `${baseUrl}/api/careers/${clientId}/delete`,
  },
  concours: {
    list: `${baseUrl}/api/concours/`,
    create: `${baseUrl}/api/concours/create/`,
    advancedSearch: `${baseUrl}/api/concours/search/advanced/`,
  },
  candidatsTI: {
    list: `${baseUrl}/api/careers/it`,
    create: `${baseUrl}/api/careers/it/create/`,
  }, 
  lead: {
    create: `${baseUrl}/api/leads/create/`,
  }, 
  appelDeService: {
    create: `${baseUrl}/api/appel-de-services/create/`,
  }, 
  openingTime: {
    conseillers: `${baseUrl}/api/opening-time/conseillers`,
    contacts: `${baseUrl}/api/opening-time/contacts`
  },
  foniva:{

    skills:{
      list: `${baseUrl}/api/foniva/skills/list`,
      set : `${baseUrl}/api/foniva/skills/set`,
      unset : `${baseUrl}/api/foniva/skills/unset`, 
    },

    agent:{
      list: `${baseUrl}/api/foniva/agents/list`,
      setOneSkill: `${baseUrl}/api/foniva/agents/set-one-skill`,
    },

    campaign: {
      list : `${baseUrl}/api/foniva/campaigns/list`,
    },
    
    contacts: {
    delete: `${baseUrl}/api/foniva/delete`

    }

  }
};
