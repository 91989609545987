import React, { useState } from "react";
import { Button, Form, Dropdown } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { api } from "../api";
import { authAxios } from "../services/authentications.services";
import { useStateValue } from "../StateProvider";

const SearchForm = () => {
  // eslint-disable-next-line
  const [{ searchResult }, dispatch] = useStateValue();
  const [concoursStatus, setConcoursStatus] = useState();
  // eslint-disable-next-line
  const [error, setError] = useState(null);

  const { Panel } = Collapse;


  const optionConcours = [
    { key: "1", text: "", value: "" },
    // { key: "2", text: "Le Naufrageur 100$", value: "concours_le_naufrageur_100" },
    // { key: "3", text: "Sepaq 100$", value: "concours_sepaq_100" },
    // { key: "4", text: "Esso 100$", value: "concours_esso_100" },
    // { key: "5", text: "Netflix", value: "concours_netflix" },
    //{ key: "6", text: "Starbucks", value: "concours_starbucks" }, 
    //{ key: "7", text: "IGA 100$", value: "concours_iga_100" },
    //{ key: "8", text: "Rappelez-nous", value: "rappelez_nous" },
    { key: "9", text: "Mondou 100$", value: "concours_mondou_100"},
    { key: "10", text: "IGA MTL 100$", value: "concours_iga_mtl_100"},
    { key: "11", text: "Mondou QC 100$", value: "concours_mondou_qc_100"},
    { key: "12", text: "Tim Hortons 100$", value: "concours_tim_hortons_100"}, 
    { key: "13", text: "Déménagement VISA 100$", value: "concours_demenagement_visa_100"},
    { key: "14", text: "Mondou MTL 100$", value: "concours_mondou_mtl_100"},
    { key: "15", text: "VISA QC 100$", value: "concours_visa_qc_100"},
    { key: "16", text: "VISA QC 100$ Janvier 2022", value: "concours_visa_qc_100_2022"},
    { key: "17", text: "SAQ 100$ Mai 2022", value: "concours_saq_2_100_2022"},
    { key: "18", text: "SAQ QC 100$ Juin 2022", value: "concours_saq_qc_juin_2022"},
    { key: "19", text: "SAQ EST 100$ Juillet 2022", value: "concours_saq_est_juillet_2022"},
    { key: "20", text: "SAQ MTL 100$ Aout 2022", value: "concours_saq_mtl_aout_2022"},
    { key: "21", text: "SAQ QC 100$ Octobre 2022", value: "concours_saq_qc_octobre_2022"},
    { key: "22", text: "SAQ MTL 100$ Decembre 2022", value: "concours_saq_mtl_decembre_2022"},


  ];
  
  async function handleSubmit(e) {
    e.preventDefault();

    setConcoursStatus(concoursStatus)
    
    console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@')
    console.log(concoursStatus);

    // dispatch the search result into the data layer
    try {
      const response = await authAxios.get(api.concours.advancedSearch, {
        params: { concoursStatus },
      });
      console.log(response.data);
      dispatch({
        type: "SEARCH",
        result: response.data,
      });
    } catch (error) {
      setError(error.message);
      console.log(error);
    }
  }

  return (
    <div>
      <Collapse
        bordered={false}
        // defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        className="site-collapse-custom-collapse"
      >
        
        <Panel
          header="Recherches avancées"
          key="1"
          className="site-collapse-custom-panel"
        >
          <Form onSubmit={handleSubmit} style={{ marginTop: "25px" }}>
            <Row
              className="justify-content-md-center"
              style={{ paddingBottom: "150px" }}
            >
              <Col xs={12}>
                <Form.Field>
                  <Dropdown
                    placeholder="Recherche par concours..."
                    fluid
                    // multiple
                    search
                    scrolling
                    clearable
                    selection
                    options={optionConcours}
                    value={concoursStatus}
                    onChange={(e, result) => setConcoursStatus(result.value)}
                  />
                </Form.Field>
              </Col>
              
            </Row>
            <Row
              className="justify-content-md-center"
              style={{ marginTop: "45px", marginBottom: "45px" }}
            >
              <Col xs={9}>
                <Button
                  type="submit"
                  style={{ backgroundColor: "orange", color: 'white' }}
                  fluid
                >
                  Rechercher
                </Button>
              </Col>
            </Row>
          </Form>
        </Panel>
      </Collapse>
    </div>
  );
};

export default SearchForm;
