import React, { Component } from "react";

//Components List
import NavBar from "../components/NavBar";
import BannerOne from "../components/banner/BannerOne";
import Subscribe from "../components/Subscribe";
import Welcome from "../components/Welcome";
import FooterWeb from "../components/FooterWeb";
import ScrollUpBtn from "../components/ScrollUpBtn";

class Particuliers extends Component {
    render() {
        return(
            <React.Fragment>
                {/* NavBar: src/components/NavBar */}
                <NavBar />
                

                  <BannerOne />


                {/* Subscribe: src/components/Subscribe */}
                <Subscribe />
                {/* Welcome: src/components/Welcome */}
                <Welcome />

        <FooterWeb />
        {/* ScrollUpBtn: src/components/ScrollUpBtn */}
        <ScrollUpBtn />
      </React.Fragment>
    );
  }
}

export default Particuliers;
