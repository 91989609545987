import React, { Component } from 'react';
import Icofont from "react-icofont";
import PropTypes from "prop-types";

export class Services extends Component {
  render() {
    //Start Services Loop
    const servicedata = this.props.servicesData.map((services, index ) =>(
        <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
            <div  
            style={{
                height: '300px',
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center'
            }}
            className="single-services">
                <Icofont icon={services.icon} />
                <h3>{services.title}</h3>
                <p>{services.content}</p>
                <div className="bg-number">{services.bgnumber}</div>
            </div>
        </div>
    ));
    //End Services Loop
    return (
        <React.Fragment>
            <section id="services" className="services-area ptb-100">
                <div className="container">
                    <div className="section-title">
                        <h2>{this.props.sectionTitle} <b>{this.props.sectionTitleSpan}</b></h2>
                        {/* <p>{this.props.sectionContent}</p> */}
                    </div>
                    <div className="row">{servicedata}</div>
                </div>
                <div className="animation-box5">
                    <img src={this.props.animationImg} className="rotateme" alt="vector" />
                </div>
            </section>
        </React.Fragment>
    );
  }
}
Services.propsTypes = {
    sectionTitle: PropTypes.string,
    sectionTitleSpan: PropTypes.string,
    sectionContent: PropTypes.string,
    animationImg: PropTypes.string,
    servicesData: PropTypes.array
}

Services.defaultProps = {
    sectionTitle: "Nos",
    sectionTitleSpan: "Avantages",
    sectionContent: "Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
    animationImg: require("../../src/assets/img/vector-bg.png"),

    servicesData: [
        {
            icon: "icofont-dollar",
            title: "Salaire",
            content: "20,00$/h pour commencer avec la possibilité de voir son salaire augmenté à 21,00$/h après quelques semaines!",
            bgnumber: "1"
        },
        {
            icon: "icofont-stethoscope",
            title: "Programme de télé-santé",
            content: "Un accès gratuit et illimité à des professionnels de la santé 24h/7 sans attente et sans sortir de chez toi grâce à ton téléphone intelligent!",
            bgnumber: "2"
        },
        {
            icon: "icofont-chart-growth",
            title: "Bonis de performance",
            content: "Tu carbures aux défis? Plus tu performes, plus tu as de chances de remporter les tirages!",
            bgnumber: "3"
        },
        {
            icon: "icofont-hat-alt",
            title: "Conciliation travail/études",
            content: "Tu changes d'horaire quatre fois par année? Ce n'est pas un problème. On s'adapte à ta réalité.",
            bgnumber: "4"
        },
        {
            icon: "icofont-calendar",
            title: "Horaires flexibles",
            content: "Nous faisons les horaires adaptés en fonction de tes disponibilités et de tes besoins. Simple comme ça.                    ",
            bgnumber: "5"
        },
        {
            icon: "icofont-spinner-alt-5",
            title: "Formation continue",
            content: "Chez AGP, ton statut d'étudiant n'est pas un frein. Avance quand même dans la compagnie.",
            bgnumber: "6"
        },
        {
            icon: "icofont-golf-field",
            title: "Salle de détente & de jeux",
            content: "Envie de t'amuser au travail? On est un peu comme le «Google de l'assurance».",
            bgnumber: "7"
        },
       
        {
            icon: "icofont-beer",
            title: "Pub AGP",
            content: "Non non tu ne rêves pas! On t'offre une bière gratuitement pour bien commencer ton weekend.",
            bgnumber: "8"
        },
        {
            icon: "icofont-coffee-alt",
            title: "Café et collations gratuites",
            content: "Des fruits, des barres tendres, du café, du thé… chose certaine, tu ne mourras pas de faim chez nous.",
            bgnumber: "9"
        }
    ]
}
export default Services

