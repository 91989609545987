import React from 'react';

function Work(){
  
    return (
        <React.Fragment>
            <section style={{backgroundColor: '#999'}} className="work-with-us ptb-100">      
                <div className="container" style={{marginTop: '110px'}}>
                    <h3>Postes à combler</h3>
                    <span>Cliquez sur les onglets disponibles pour postuler à nos emplois</span>    
                </div>
            </section>
        </React.Fragment>
    );
}
  



export default Work
