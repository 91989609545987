import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Header,
  Icon,
} from "semantic-ui-react";
import { Container, Row, Col } from "react-bootstrap";
import Message from "../components/Message";
import { api } from "../api";
import { useParams, useHistory } from "react-router-dom";
import { authAxios } from "../services";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import moment from "moment";
import CareerEmbaucheCandidat from "../templates/CareerEmbaucheCandidat";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {optionsOrigin}  from "./CareerCreate"
registerLocale("fr", fr);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const optionsReachStatus = [
  { key: "1", text: "", value: "" },
  { key: "2", text: "Absent ", value: "Absent" },
  { key: "3", text: "Rejoint ", value: "Rejoint" },
];

const optionAttempt = [
  { key: "1", text: "", value: "" },
  { key: "2", text: "1", value: "1" },
  { key: "3", text: "2", value: "2" },
  { key: "4", text: "3", value: "3" },
  { key: "5", text: "4", value: "4" },
];

const optionPhoneStatus = [
  { key: "1", text: "", value: "" },
  { key: "2", text: "Entrevue planifiée", value: "Entrevue planifiée" },
  { key: "3", text: "En attente", value: "En attente" },
  { key: "4", text: "En banque", value: "En banque" },
  { key: "5", text: "Décliné - Expérience téléphonique", value: "Décliné - Expérience téléphonique" },
  { key: "6", text: "Décliné - Pas le profil recherché", value: "Décliné - Pas le profil recherché" },
  { key: "7", text: "Décliné - Scolarité", value: "Décliné - Scolarité" },
  { key: "8", text: "Décliné - Doublon", value: "Décliné - Doublon" },
  { key: "9", text: "Décliné - Aucun retour du candidat",  value: "Décliné - Aucun retour du candidat"},
  { key: "10", text: "Décliné - AMF",  value: "Décliné - AMF"}
];


const optionsDeclinePhoneReason = {
  "Décliné - Expérience téléphonique" : [
    { key: "1", text: "Candidat donne des réponses très brèves/manque de dynamisme.", value: "Candidat donne des réponses très brèves/manque de dynamisme." },
    { key: "2", text: "Candidat ne démontre pas un intérêt concret pour le poste, ses motivations ne sont pas valables", value: "Candidat ne démontre pas un intérêt concret pour le poste, ses motivations ne sont pas valables" },
    { key: "3", text: "CV non satisfaisant", value: "CV non satisfaisant" },
    { key: "4", text: "Le candidat ne s'exprime pas clairement en français", value: "Le candidat ne s'exprime pas clairement en français" },

  ], 

  "Décliné - Pas le profil recherché" : [
      { key: "1", text: "Le candidat est âgé de plus de 35 ans", value: "Le candidat est âgé de plus de 35 ans" },
      { key: "2", text: "Le candidat n'habite pas la ville de Québec (aucune ambigüité)", value: "Le candidat n'habite pas la ville de Québec (aucune ambigüité)" },
      { key: "3", text: "Le candidat se cherche un emploi temporaire", value: "Le candidat se cherche un emploi temporaire" },
      { key: "4", text: "Le candidat veut faire un nombre d'heure inférieur à 16h", value: "Le candidat veut faire un nombre d'heure inférieur à 16h" },
  ], 

  "Décliné - Scolarité" : [
    { key: "1", text: "Le candidat prévoit un stage bientôt", value: "Le candidat prévoit un stage bientôt" },
    { key: "2", text: "Le candidat termine bientôt ses études et risque de se chercher un emploi dans son domaine.", value: "Le candidat termine bientôt ses études et risque de se chercher un emploi dans son domaine." },
    { key: "3", text: "Le candidat est surqualité pour le poste ou possède un diplôme dans un domaine très spécifique.", value: "Le candidat est surqualité pour le poste ou possède un diplôme dans un domaine très spécifique." },
  ],

  "Décliné - AMF" : [
    { key: "1", text: "Le candidat ne possède pas de DEC, n'est pas en voie d'en compléter un et ne possède pas les 3 années de travail à temps plein", value: "Le candidat ne possède pas de DEC, n'est pas en voie d'en compléter un et ne possède pas les 3 années de travail à temps plein" },
  ],

  "Décliné - Doublon" : [
    { key: "1", text: "Candidat non retenu qui réapplique sur le poste", value: "Candidat non retenu qui réapplique sur le poste" },
  ]

}

const optionFinalStatus = [
  { key: "1", text: "", value: "" },
  { key: "2", text: "Engagé", value: "Engagé" },
  { key: "3", text: "En attente", value: "En attente" },
  { key: "4", text: "En banque", value: "En banque" },
  { key: "5", text: "Décliné - Candidature non retenue", value: "Décliné - Candidature non retenue" },
  { key: "6", text: "Décliné - Candidat refuse le poste", value: "Décliné - Candidat refuse le poste" }
];

const optionKnowAgp = [
  { key: "1", text: "", value: "" },
  { key: "2", text: "Le candidat ne connaît rien de l'entreprise", value: "Le candidat ne connaît rien de l'entreprise" },
  { key: "3", text: "Le candidat connaît moyennement l'entreprise", value: "Le candidat connaît moyennement l'entreprise" },
  { key: "4", text: "Le candidat connaît bien l'entreprise", value: "Le candidat connaît bien l'entreprise" }
  
];


const optionReasonNewJob = [
  { key: "1", text: "", value: "" },
  { key: "2", text: "Le candidat veut de la nouveauté / par ennuis dans son ancien emploi", value: "Le candidat veut de la nouveauté / par ennuis dans son ancien emploi" },
  { key: "3", text: "Le candidat veut entrer dans un milieu professionnel/avancement/son domaine", value: "Le candidat veut entrer dans un milieu professionnel/avancement/son domaine" }

];

const optionInterestsInThisJob = [
  { key: "1", text: "", value: "" },
  { key: "2", text: "Le candidat ne démontre pas un intérêt marqué ni pour le poste, ni pour le domaine de l'assurance", value: "Le candidat ne démontre pas un intérêt marqué ni pour le poste, ni pour le domaine de l'assurance" },
  { key: "3", text: "Le candidat démontre un intérêt moyen pour le poste et le domaine de l'assurance", value: "Le candidat démontre un intérêt moyen pour le poste et le domaine de l'assurance" },
  { key: "4", text: "Le candidat démontre un intérêt très prononcé pour le poste et pour le domaine de l'assurance", value: "Le candidat démontre un intérêt très prononcé pour le poste et pour le domaine de l'assurance" }

];

const optionAdmissibiliteAMF = [
  { key: "1", text: "", value: "" },
  { key: "2", text: "Admissible - Le candidat a complété son DEC", value: "Admissible - Le candidat a complété son DEC" },
  { key: "3", text: "Admissible - Le candidat possède 3 années de travail à temps plein", value: "Admissible - Le candidat possède 3 années de travail à temps plein" },
  { key: "4", text: "Bientôt admissible - Le candidat est en voie de compléter son DEC", value: "Bientôt admissible - Le candidat est en voie de compléter son DEC" },
  { key: "5", text: "Non admissible", value: "Non admissible" }
];




const CareerDetail = () => {

  const [candidat, setClient] = useState(null);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const { clientId } = useParams();


  useEffect(() => {
    async function fetchData() {
      try {
        const response = await authAxios.get(api.candidats.retrieve(clientId));
        const files = await authAxios.get(api.candidats.notes.list(clientId));
        console.log(response.data)
        setClient(response.data);

        setFiles((oldState) =>  files.data)
      } catch (error) {
        setError(error.message);
      }
    }
    fetchData();
  }, [clientId]);

  return (
    <div
      style={{
        backgroundColor: "#fafafa",
        paddingTop: "50px",
        paddingBottom: "50px",
      }}
    >
      
      {/* <Breadcrumb>
                <Breadcrumb.Item href="/careers">Liste</Breadcrumb.Item>
                <Breadcrumb.Item active>Detail</Breadcrumb.Item>
            </Breadcrumb> */}

      {/* <div style={{paddingRight: '20px'}}>
                <DeleteModal clientId={clientId}/>
            </div> */}

      {error && <Message negative message={error} />}

      {candidat && (
        <Container>
          <div className="card card-body mt-4 ">
            <label
              style={{
                fontWeight: "bold",
                fontSize: "25px",
                textAlign: "center",
              }}
            >
              Informations du candidat
            </label>
            <Divider />

            <Form>
              {/* first_name & last_name */}
              <Form.Group widths="equal">
                <Form.Field
                  id="form-input-control-first-name"
                  control={Input}
                  label="Nom Complet"
                  value={candidat.first_name + " " + candidat.last_name}
                  // onChange={e => setFirst_name(e.target.value)}
                />

                <Form.Field
                  id="form-input-control-email"
                  control={Input}
                  label="Email"
                  value={candidat.email}
                  // onChange={e => setEmail(e.target.value)}
                />
                <Form.Field
                  id="form-input-control-phone"
                  control={Input}
                  label="Téléphone"
                  value={candidat.phone}
                  // onChange={e => setPhone(e.target.value)}
                />
              </Form.Group>

              {/* origin & school_status */}
              <Form.Group widths="equal">
                <Form.Field
                  id="form-input-control-origin"
                  control={Input}
                  label="Où as-tu entendu parler de nous?"
                  value={candidat.origin}
                  // onChange={e => setLast_name(e.target.value)}
                />

                <Form.Field
                  id="form-input-control-school-status"
                  control={Input}
                  label="Es-tu présentement aux études?"
                  value={candidat.school_status}
                  // onChange={e => setLast_name(e.target.value)}
                />

                <Form.Field
                  id="form-input-control-study"
                  control={Input}
                  label="Niveau d'étude"
                  value={candidat.study}
                  // onChange={e => setLast_name(e.target.value)}
                />

                <Form.Field
                  id="form-input-control-program"
                  control={Input}
                  label="Quel programme?"
                  value={candidat.program}
                  // onChange={e => setLast_name(e.target.value)}
                />
              </Form.Group>

              {/* nb session & nb hour */}
              <Form.Group widths="equal">
                <Form.Field
                  id="form-input-control-number-of-session"
                  control={Input}
                  label="Nombre de sessions restantes?"
                  value={candidat.number_of_session}
                  // onChange={e => setLast_name(e.target.value)}
                />

                <Form.Field
                  id="form-input-control-number-of-hour"
                  control={Input}
                  label="Nombre d'heures souhaitées?"
                  value={candidat.number_of_hour}
                  // onChange={e => setLast_name(e.target.value)}
                />

                <Form.Field
                  id="form-input-control-hour-to-call"
                  control={Input}
                  label="Moment idéal pour te joindre?"
                  value={candidat.hour_to_call}
                  // onChange={e => setLast_name(e.target.value)}
                />
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field
                  id="form-input-control-first-name"
                  control={Input}
                  label="Parmi les critères de formation minimales de l'AMF, lequel correspond le mieux à ta situation:"
                  value={candidat.formation_amf}
                />
              </Form.Group>

              {/* attachment */}
              <Form.Group widths="equal">
                <Form.Field>
                  <Button
                    style={{ backgroundColor: "orange" }}
                    type="button"
                    fluid
                    content="Télécharger le C.V"
                    labelPosition="left"
                    icon="file"
                    onClick={() => window.open(candidat.attachment, "_blank")}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </div>

          <CareerUpdateForm
            clientId={clientId}
            initialReachStatus={candidat.reach_status}
            initialAttempt={candidat.attempt}
            initialPhoneInterviewDate={candidat.phone_interview_date}
            initialPhoneResult={candidat.phone_result}
            initialDeclinePhoneReason={candidat.decline_phone_reason}
            initialPhoneStatus={candidat.phone_status}
            initialPhoneComment={candidat.phone_comment}
            initialInterviewDate={candidat.interview_date}
            initialFinalResult={candidat.final_result}
            initialFinalStatus={candidat.final_status}
            initialFinalComment={candidat.final_comment}
            initialFirstName={candidat.first_name}
            initialLastName={candidat.last_name}
            initialEmail={candidat.email}
            initialActualJob={candidat.actual_job}
            initialActualJobComment={candidat.actual_job_comment}
            initialHoursOfWork={candidat.hours_of_work}
            initialHoursOfWorkComment={candidat.hours_of_work_comment}
            initialTimeLeftAtSchool={candidat.time_left_at_school}
            initialTimeLeftAtSchoolComment={candidat.time_left_at_school_comment}
            initialNumberOfCoursBySession={candidat.number_of_cours_by_session}
            initialNumberOfCoursBySessionComment={candidat.number_of_cours_by_session_comment}
            initialKnowAgp={candidat.know_agp}
            initialKnowAgpComment={candidat.know_agp_comment}
            initialReasonNewJob={candidat.reason_new_job}
            initialReasonNewJobComment={candidat.reason_new_job_comment}
            initialInterestsInThisJob={candidat.interests_in_this_job}
            initialAdmissibiliteAMF={candidat.admissibilite_amf}
            initialAdmissibiliteAMFComment={candidat.admissibilite_amf_comment === "undefined" ? "" : candidat.admissibilite_amf_comment}
            initialInterestsInThisJobComment={candidat.interests_in_this_job_comment}
            initialGeneralImpression={candidat.general_impression}
            initialGeneralImpressionComment={candidat.general_impression_comment}
            notes={files}
            setNotes={setFiles}
            inititialOrigin={candidat.origin}
            initialOriginComment={candidat.originComment}


          />
        </Container>
      )}
    </div>
  );
};



const CareerUpdateForm = ({
  clientId,
  initialReachStatus,
  initialAttempt,
  initialPhoneInterviewDate,
  initialPhoneResult,
  initialPhoneStatus,
  initialDeclinePhoneReason,
  initialPhoneComment,
  initialInterviewDate,
  initialFinalResult,
  initialFinalStatus,
  initialFinalComment,
  initialFirstName,
  initialLastName,
  initialEmail,
  initialActualJob,
  initialActualJobComment,
  initialHoursOfWork,
  initialHoursOfWorkComment,
  initialTimeLeftAtSchool,
  initialTimeLeftAtSchoolComment,
  initialNumberOfCoursBySession,
  initialNumberOfCoursBySessionComment,
  initialKnowAgp,
  initialKnowAgpComment,
  initialReasonNewJob,
  initialReasonNewJobComment,
  initialInterestsInThisJob,
  initialInterestsInThisJobComment,
  initialAdmissibiliteAMF,
  initialAdmissibiliteAMFComment,
  initialGeneralImpression,
  initialGeneralImpressionComment,
  notes,
  setNotes,
  initialOriginComment,
  inititialOrigin
}) => {


  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);


  const [reach_status, setReachStatus] = useState(initialReachStatus);
  const [attempt, setAttempt] = useState(initialAttempt);
  const [phone_interview_date, setPhoneInterviewDate] = useState(initialPhoneInterviewDate);
  const [phone_result, setPhoneResult] = useState(initialPhoneResult);
  const [phone_status, setPhoneStatus] = useState(initialPhoneStatus);
  const [declinePhoneReason, setDeclinePhoneReason] = useState(initialDeclinePhoneReason);
  const [phone_comment, setPhoneComment] = useState(initialPhoneComment);
  const [interview_date, setInterviewDate] = useState(initialInterviewDate);
  const [final_result, setFinalResult] = useState(initialFinalResult);
  const [final_status, setFinalStatus] = useState(initialFinalStatus);
  const [final_comment, setFinalComment] = useState(initialFinalComment);

  const [actual_job, setActualJob] = useState(initialActualJob); /////////
  const [actual_job_comment, setActualJobComment] = useState(initialActualJobComment); /////////
  const [hours_of_work, setHoursOfWork] = useState(initialHoursOfWork); /////////
  const [hours_of_work_comment, setHoursOfWorkComment] = useState(initialHoursOfWorkComment); /////////
  const [time_left_at_school, setTimeLeftAtSchool] = useState(initialTimeLeftAtSchool); /////////
  const [time_left_at_school_comment, setTimeLeftAtSchoolComment] = useState(initialTimeLeftAtSchoolComment); /////////


  const [number_of_cours_by_session, setNumberOfCoursBySession] = useState(initialNumberOfCoursBySession); /////////
  const [number_of_cours_by_session_comment, setNumberOfCoursBySessionComment] = useState(initialNumberOfCoursBySessionComment); /////////
  const [know_agp, setKnowAgp] = useState(initialKnowAgp); /////////
  const [know_agp_comment, setKnowAgpComment] = useState(initialKnowAgpComment); /////////
  const [reason_new_job, setReasonNewJob] = useState(initialReasonNewJob); /////////
  const [reason_new_job_comment, setReasonNewJobComment] = useState(initialReasonNewJobComment); /////////

  const [interests_in_this_job, setInterestsInThisJob] = useState(initialInterestsInThisJob);
  const [admissibiliteAMF, setAdmissibiliteAMF] = useState(initialAdmissibiliteAMF); /////////
  const [admissibiliteAMFComment, setAdmissibiliteAMFComment] = useState(initialAdmissibiliteAMFComment); /////////
  const [interests_in_this_job_comment, setInterestsInThisJobComment] = useState(initialInterestsInThisJobComment);
  const [general_impression, setGeneralImpression] = useState(initialGeneralImpression);
  const [general_impression_comment, setGeneralImpressionComment] = useState(initialGeneralImpressionComment);
  
  const [uploadDingFiles, setUploadingFiles] = useState([]);
  const [origin, setOrigin] = useState(inititialOrigin);
  const [originComment, setOriginComment] = useState(initialOriginComment);

  const FileList = ({files,
                     setFiles, 
                     style, 
                     elementStyle}) => {
    return ( 
      <ul
      style={style}
      >
      {
      files.map((file,index) => {
      return(
      <li
        key={file.id}
        style={
        {
          ...elementStyle,
          display : 'flex',
          justifyContent : 'space-between'
        }}  
      >

       <a href={file.attachment}> 
       {file.name}
       </a>

       

       <button

       onClick={(e) =>{

         //Delete the file
         authAxios.delete(api.candidats.notes.delete(file.id),)
         setFiles((oldValue) => {
           let newValue = [...oldValue]
           newValue.splice(file.index, 1)
           return newValue

         })
       }}


       style={{fontWeight: 'bold', 
               backgroundColor: 'Transparent',
               borderColor: 'transparent',
               color: 'red',
               cursor: 'pointer'}}
       >X</button>
       
      
      </li>)
    }) 
  }
    </ul>
    )
  }

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const mailgun = require("mailgun-js");
  const DOMAIN = "mail.agpassurance.ca";
  // eslint-disable-next-line
  const mg = mailgun({
    apiKey: "key-8cdc956d187052f646ef4a5f3e1a8df4",
    domain: DOMAIN,
  });
  // eslint-disable-next-line
  const data = {
    from: "AGP Assurance <info@mail.agpassurance.ca>",
    to: `${initialEmail}`,
    subject: "Vous êtes Engagé",
    html: CareerEmbaucheCandidat(initialFirstName, initialLastName),
  };

  const fileInputRef = useRef()


  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();

    if (reach_status != null) {
      formData.append("reach_status", reach_status);
    } else if (reach_status == null) {
      formData.append("reach_status", "");
    }

    if (attempt != null) {
      formData.append("attempt", attempt);
    } else if (attempt == null) {
      formData.append("attempt", "");
    }

    if (phone_interview_date != null) {
      formData.append(
        "phone_interview_date",
        moment(phone_interview_date).format("YYYY-MM-DD HH:mm")
      );
    } else if (phone_interview_date == null) {

      formData.append("phone_interview_date", "");
    }

    if (phone_result != null) {
      formData.append("phone_result", phone_result);
    } else if (phone_result == null) {
      formData.append("phone_result", "");
    }

    if (phone_status != null) {
      formData.append("phone_status", phone_status);
    } else if (phone_status == null) {
      formData.append("phone_status", "");
    }

    if (phone_comment != null) {
      formData.append("phone_comment", phone_comment);
    } else if (phone_comment == null) {
      formData.append("phone_comment", "");
    }

    if(declinePhoneReason != null) {
      formData.append("decline_phone_reason", declinePhoneReason );
    } else{
      formData.append("decline_phone_reason", "")
    }

    if (interview_date != null) {
      formData.append(
        "interview_date",
        moment(interview_date).format("YYYY-MM-DD HH:mm")
      );
    } else if (interview_date == null) {
      formData.append("interview_date", "");
    }

    if (final_result != null) {
      formData.append("final_result", final_result);
    } else if (final_result == null) {
      formData.append("final_result", "");
    }

    if (final_status != null) {
      formData.append("final_status", final_status);
    } else if (final_status == null) {
      formData.append("final_status", "");
    }

    if (final_comment != null) {
      formData.append("final_comment", final_comment);
    } else if (final_comment == null) {
      formData.append("final_comment", "");
    }

    // if (final_status === "Engagé" && initialFinalStatus !== "Engagé") {
    //   mg.messages().send(data, function (error, body) {
    //     console.log("Sent");
    //   });
    // }

    if (actual_job != null) {
      formData.append("actual_job", actual_job);
    } else if (actual_job == null) {
      formData.append("actual_job", "");
    }

    if (actual_job_comment != null) {
      formData.append("actual_job_comment", actual_job_comment);
    } else if (actual_job_comment == null) {
      formData.append("actual_job_comment", "");
    }

    if (hours_of_work != null) {
      formData.append("hours_of_work", hours_of_work);
    } else if (hours_of_work == null) {
      formData.append("hours_of_work", "");
    }

    if (hours_of_work_comment != null) {
      formData.append("hours_of_work_comment", hours_of_work_comment);
    } else if (hours_of_work_comment == null) {
      formData.append("hours_of_work_comment", "");
    }

    if (time_left_at_school != null) {
      formData.append("time_left_at_school", time_left_at_school);
    } else if (time_left_at_school == null) {
      formData.append("time_left_at_school", "");
    }

    if (time_left_at_school_comment != null) {
      formData.append("time_left_at_school_comment", time_left_at_school_comment);
    } else if (time_left_at_school_comment == null) {
      formData.append("time_left_at_school_comment", "");
    }

    if (number_of_cours_by_session != null) {
      formData.append("number_of_cours_by_session", number_of_cours_by_session);
    } else if (number_of_cours_by_session == null) {
      formData.append("number_of_cours_by_session", "");
    }

    if (number_of_cours_by_session_comment != null) {
      formData.append("number_of_cours_by_session_comment", number_of_cours_by_session_comment);
    } else if (number_of_cours_by_session_comment == null) {
      formData.append("number_of_cours_by_session_comment", "");
    }

    if (know_agp != null) {
      formData.append("know_agp", know_agp);
    } else if (know_agp == null) {
      formData.append("know_agp", "");
    }

    if (know_agp_comment != null) {
      formData.append("know_agp_comment", know_agp_comment);
    } else if (know_agp_comment == null) {
      formData.append("know_agp_comment", "");
    }

    if (reason_new_job != null) {
      formData.append("reason_new_job", reason_new_job);
    } else if (reason_new_job == null) {
      formData.append("reason_new_job", "");
    }

    if (reason_new_job_comment != null) {
      formData.append("reason_new_job_comment", reason_new_job_comment);
    } else if (reason_new_job_comment == null) {
      formData.append("reason_new_job_comment", "");
    }

    if (interests_in_this_job != null) {
      formData.append("interests_in_this_job", interests_in_this_job);
    } else if (interests_in_this_job == null) {
      formData.append("interests_in_this_job", "");
    }

    if (interests_in_this_job_comment != null) {
      formData.append("interests_in_this_job_comment", interests_in_this_job_comment);
    } else if (interests_in_this_job_comment == null) {
      formData.append("interests_in_this_job_comment", "");
    }

    if (general_impression != null) {
      formData.append("general_impression", general_impression);
    } else if (general_impression == null) {
      formData.append("general_impression", "");
    }

    if (general_impression_comment != null) {
      formData.append("general_impression_comment", general_impression_comment);
    } else if (general_impression_comment == null) {
      formData.append("general_impression_comment", "");
    }

    formData.append("admissibilite_amf", admissibiliteAMF);
    formData.append("admissibilite_amf_comment", admissibiliteAMFComment);
    formData.append("origin_comment", originComment);
    formData.append("origin", origin);

    authAxios
      .put(api.candidats.update(clientId), formData, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          setLoading(false);
          // setOpen(true);
          // TO DO redirect back to the post list
          // history.push(`/careers`)
          // window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
        setError(err.message || err);
      });
  }

  return (
    <Container className="clientCreate_container" fluid>
      <Form onSubmit={handleSubmit}>
        {error && <Message negative message={error} />}
       
        <Row>
          <div className="card card-body mt-4">
            <label
              style={{
                fontWeight: "bold",
                fontSize: "25px",
                textAlign: "center",
              }}
            >
              Résultat entrevue téléphonique
            </label>
            <Col>
              {/* {documentUrl && <Image src={documentUrl} size='small'/>} */}
              <Divider />
              <Form.Group widths="equal">
                <Form.Dropdown
                  placeholder="Choisir"
                  label="Statut d'appel?"
                  selection
                  onChange={(e, result) => setReachStatus(result.value)}
                  options={optionsReachStatus}
                  value={reach_status}
                />

                <Form.Dropdown
                  placeholder="Choisir"
                  label="Nombre de tentative?"
                  selection
                  onChange={(e, result) => setAttempt(result.value)}
                  options={optionAttempt}
                  value={attempt}
                />
              </Form.Group>

              <label style={{ fontWeight: "bold", fontSize: "13px" }}>
                Date d'entrevue
              </label>
              <Form.Group widths="equal" style={{ marginLeft: "1px" }}>
                <DatePicker
                  placeholderText="Click to select a date"
                  onChange={(date) => setPhoneInterviewDate(date)}
                  selected={
                    phone_interview_date ? new Date(phone_interview_date) : null
                  }
                  locale="fr"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="Heure"
                  dateFormat="yyyy-MM-dd hh:mm:ss"
                />
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field
                  id="form-input-control-phone-result"
                  control={Input}
                  label="Résultat téléphonique"
                  placeholder="Entrez une note entre 0 et 100"
                  value={phone_result}
                  onChange={(e) => setPhoneResult(e.target.value)}
                />

                <Form.Dropdown
                  placeholder="Choisir"
                  label="Statut téléphonique"
                  selection
                  onChange={(e, result) => setPhoneStatus(result.value)}
                  options={optionPhoneStatus}
                  value={phone_status}
                />
              </Form.Group>
              


              <div
              style={{
                margin: '10px 0',
                display : phone_status in optionsDeclinePhoneReason ? '' : 'None'
              }}
              >
                
                <Form.Dropdown
                
                style={{width: '100%'}}
                    placeholder="Choisir" 
                    label="Raison du déclin"
                    selection
                    onChange={(e, result) => setDeclinePhoneReason(result.value)}
                    options={optionsDeclinePhoneReason[phone_status]}
                    value={declinePhoneReason}
                  />

              </div>



              <Form.Group widths="equal">
                <Form.TextArea
                  label="Commentaire téléphonique"
                  value={phone_comment}
                  onChange={(e, result) => setPhoneComment(result.value)}
                />
              </Form.Group>
            </Col>
          </div>

          <div className="card card-body mt-4">
            <label
              style={{
                fontWeight: "bold",
                fontSize: "25px",
                textAlign: "center",
              }}
            >
              Résultat entrevue finale
            </label>
            <Col>
              {error && <Message negative message={error} />}
              {/* {documentUrl && <Image src={documentUrl} size='small'/>} */}
              <Divider />

              <label style={{ fontWeight: "bold", fontSize: "13px" }}>
                Date d'entrevue
              </label>
              <Form.Group widths="equal" style={{ marginLeft: "1px" }}>
                <DatePicker
                  placeholderText="Click to select a date"
                  onChange={(date) => setInterviewDate(date)}
                  selected={interview_date ? new Date(interview_date) : null}
                  locale="fr"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="Heure"
                  dateFormat="yyyy-MM-dd hh:mm:ss"
                />
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field
                  id="form-input-control-final-result"
                  control={Input}
                  label="Résultat final"
                  placeholder="Entrez une note entre 0 et 100"
                  value={final_result}
                  onChange={(e) => setFinalResult(e.target.value)}
                />
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Dropdown
                  placeholder="Choisir"
                  label="Statut final"
                  selection
                  onChange={(e, result) => setFinalStatus(result.value)}
                  options={optionFinalStatus}
                  value={final_status}
                />
              </Form.Group>

              <Form.Group widths='equal'>

              <Form.Field
              >
                  
          
                  <h4>Notes : 
                     
                  </h4>

                  <div>

                  <input
                      accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/msword"
                      ref={fileInputRef}
                      type="file"
                      hidden
                      name="notes"
                      onChange={e => {
                        const file  = e.target.files[0]
                        
                        const fileForm = new FormData()

                        fileForm.append("career", clientId)
                        fileForm.append("name", file.name)
                        fileForm.append("attachment",  file)

                        setUploadingFiles(oldState =>  [...oldState, 
                          file.name
                        ])

                        authAxios.post(api.candidats.notes.create, fileForm,{
                            headers: {
                                "Content-type": "multipart/form-data"
                            }
                        }).then(
                          res => {
                            let newFile = res.data

                            setUploadingFiles(oldState => {
                              let newState = [...oldState]
                              let index = oldState.findIndex(elem => elem == file.name)
                              newState.splice(index, 1)
                              return newState
                            })

                            setNotes( 
                              (oldState) => [...oldState,
                                            newFile]
                            )
                          }

                        ).catch(err => {
                          console.log(err)
                          console.log(err.response)
                        })

                        
                      
                      }

                      }
                  />


<span
                      style={{
                              display: 'inline',
                              color: 'grey',
                              fontSize: '0.8em',
                              display : uploadDingFiles.length == 0 ? 'None' : ''
                              }}
                      >{uploadDingFiles.join(", ")} (En cours de téléversement...)</span>

</div>

                  <FileList 
                  style={{margin: '0 10px', 
                          padding: '2px', 
                          border: '1px solid black',
                          minHeight: '5px'
                         }}
                  elementStyle={{margin: '0 10px'}}
                  files={notes}
                  setFiles={setNotes}
                  />

                  <Button
                      style={{backgroundColor: '#247afd', 
                              color: 'white',
                              margin: '10px', 
                              width: '50%',
                            textAlign:'center'}}
                      type="button"
                      fluid
                      content="Téléverser une note"
                      labelPosition="left"
                      icon="file"
                      onClick={() => 
                        fileInputRef.current.click()
                        
                      }
                  />

              </Form.Field>

              </Form.Group>

              <Form.Group widths="equal">
                <Form.TextArea
                  label="Commentaire final"
                  value={final_comment}
                  onChange={(e, result) => setFinalComment(result.value)}
                />
              </Form.Group>
            </Col>
          </div>
        </Row>

        <div
          className="card card-body mt-4"
          style={{ marginLeft: "-15px", marginRight: "-15px" }}
        >

          <Container>
            <label
              style={{
                fontWeight: "bold",
                fontSize: "25px",
                marginLeft: "350px",
              }}
            >
              Grille entrevue téléphonique
            </label>
            <Divider />

            <Form.Group widths="equal" >
                
                <Form.Dropdown
                  placeholder="Choisir"
                  label="Où as-tu entendu parler de nous? "
                  selection
                  onChange={(e, result) => {
                    setOriginComment("")
                    setOrigin(result.value)
                  }}
                  options={optionsOrigin}
                  value={origin}
                />

                {
                  origin  === "Quelqu'un qui travaille chez AGP" || origin === "Autre" ? (
                  
                  <Form.Field
                    id="form-input-control-origin-comment"
                    control={Input}
                    label={() => {
                      return {
                        "Quelqu'un qui travaille chez AGP" : "Nom de la référence : ",
                        "Autre" : "Détaillez : "
                      }[origin]
                    }}
                    value={originComment}
                    onChange={(e) => setOriginComment(e.target.value)}
                  /> ): ""
                  
                }

            </Form.Group>

             <Form.Group widths="equal" >
                <Form.Field
                  id="form-input-control-phone-result"
                  control={Input}
                  label="As-tu un emploi actuellement?"
                  placeholder="Emploi"
                  value={actual_job}
                  onChange={(e) => setActualJob(e.target.value)}
                />

                <Form.Field
                  id="form-input-control-phone-result"
                  control={Input}
                  label="Combien d'heures désires-tu travailler par semaine?"
                  placeholder="Heures par semaine"
                  value={hours_of_work}
                  onChange={(e) => setHoursOfWork(e.target.value)}
                />

              </Form.Group>


              <Form.Group widths="equal">

                <Form.Field
                  id="form-input-control-phone-result"
                  control={Input}
                  label="Il te reste combien de temps aux études?"
                  placeholder="Combien de temps aux études"
                  value={time_left_at_school}
                  onChange={(e) => setTimeLeftAtSchool(e.target.value)}
                />

                <Form.Field
                  id="form-input-control-phone-result"
                  control={Input}
                  label="Nombre de cours par session?"
                  placeholder="Nombre de cours par session"
                  value={number_of_cours_by_session}
                  onChange={(e) => setNumberOfCoursBySession(e.target.value)}
                />

              </Form.Group>

 

              <Form.Group widths="equal">

              <Form.Dropdown
                  placeholder="Choisir"
                  label="Connais-tu AGP Assurance? (15 points)"
                  selection
                  onChange={(e, result) => setKnowAgp(result.value)}
                  options={optionKnowAgp}
                  value={know_agp}
                />

                <Form.Dropdown
                  placeholder="Choisir"
                  label="Pourquoi te cherches-tu un nouvel emploi? (25 points)"
                  selection
                  onChange={(e, result) => setReasonNewJob(result.value)}
                  options={optionReasonNewJob}
                  value={reason_new_job}
                />

              </Form.Group>

              <Form.Group widths="equal">

              {know_agp === '' || know_agp === null  ? (
                  
                  <div></div>

              ) : (

                <Form.TextArea
                  label="Commentaire"
                  value={know_agp_comment}
                  onChange={(e, result) => setKnowAgpComment(result.value)}
                />
              )}

              {reason_new_job === '' || reason_new_job === null  ? (
                  
                  <div></div>

              ) : (

                <Form.TextArea
                  label="Commentaire"
                  value={reason_new_job_comment}
                  onChange={(e, result) => setReasonNewJobComment(result.value)}
                />
              )}

                

                

              </Form.Group>

              <Form.Group widths="equal">

              <Form.Dropdown
                  placeholder="Choisir"
                  label="Qu'est-ce qui t'intéresse dans le poste? (35 points)"
                  selection
                  onChange={(e, result) => setInterestsInThisJob(result.value)}
                  options={optionInterestsInThisJob}
                  value={interests_in_this_job}
                />

                <Form.Field
                  id="form-input-control-phone-result"
                  control={Input}
                  label="Facilité à communiquer + Impression générale (25 points)"
                  placeholder="Facilité à communiquer + Impression générale"
                  value={general_impression}
                  onChange={(e) => setGeneralImpression(e.target.value)}
                />

              </Form.Group>

            <Form.Group widths="equal">
              <Form.Dropdown
                  placeholder="Choisir"
                  label="Admissibilité AMF"
                  selection
                  onChange={(e, result) => setAdmissibiliteAMF(result.value)}
                  options={optionAdmissibiliteAMF}
                  value={admissibiliteAMF}
                />
                
            </Form.Group>
            
            {
                  (admissibiliteAMF !== undefined && admissibiliteAMF !== "") ? 
                    <Form.TextArea
                    label="Commentaire"
                    value={admissibiliteAMFComment}
                    onChange={(e, result) => setAdmissibiliteAMFComment(result.value)}
                    /> : ""
              }
               

              <Form.Group widths="equal">

              {interests_in_this_job === '' || interests_in_this_job === null  ? (
                  
                  <div></div>

              ) : (

                <Form.TextArea
                  label="Commentaire"
                  value={interests_in_this_job_comment}
                  onChange={(e, result) => setInterestsInThisJobComment(result.value)}
                />
              )}

              {general_impression === '' || general_impression === null  ? (
                  
                  <div></div>

              ) : (

                <Form.TextArea
                  label="Commentaire"
                  value={general_impression_comment}
                  onChange={(e, result) => setGeneralImpressionComment(result.value)}
                />
              )}

              </Form.Group>

          </Container>

        </div>
        
        

        <div
          className="card card-body mt-4 mb-4"
          style={{ marginLeft: "-15px", marginRight: "-15px" }}
        >
          <Container>
            <Button
              inverted
              loading={loading}
              disabled={loading}
              type="submit"
              color="orange"
              // control={Button}
              content="Enregistrer"
              onClick={handleClick}
            />
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Modification enregistré avec succès!
              </Alert>
            </Snackbar>

            <DeleteModal clientId={clientId} />
          </Container>
        </div>
      </Form>
    </Container>
  );
};



function DeleteModal({ clientId }) {
  const [setError] = useState("");
  const [open, setOpen] = useState(false);
  let history = useHistory();

  function handleDelete() {
    authAxios
      .delete(api.candidats.delete(clientId), {
        headers: {
          "Content-Type": "application/json"
          
        },
      })
      .then((res) => {
        console.log(res);
        // TO DO redirect back to the post list
        history.push("/careers");
      })
      .catch((err) => {
        console.log(err);
        setError(err.message || err);
      });
  }

  return (
    <Modal
      style={{ background: "none", position: "initial", paddingTop: "250px" }}
      open={open}
      trigger={
        <Button floated="right" color="red">
          Supprimer
        </Button>
      }
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Header
        icon="trash"
        content="Attention! Vous vous apprêtez a supprimer un candidat"
      />

      <Modal.Content>
        <p>Voulez-vous vraiment supprimer ce candidat définitivement?</p>
      </Modal.Content>

      <Modal.Actions>
        <Button color="red" onClick={() => setOpen(false)}>
          <Icon name="remove" /> Non
        </Button>

        <Button color="green" onClick={handleDelete}>
          <Icon name="checkmark" /> Oui
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CareerDetail;
