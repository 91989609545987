import React, { useState, useEffect } from "react";
import { Table } from "semantic-ui-react";
import { useStateValue } from "../StateProvider";
import Pagination from "../components/Pagination";
import { Link } from "react-scroll";

const Concours = () => {
  // eslint-disable-next-line
  const [{ searchResult }, dispatch] = useStateValue();
  const [currentPage, setCurrentPage] = useState(1);
  const [candidatsPerPage] = useState(20);

  useEffect(()=>{

    setCurrentPage(1)

  }, [searchResult])
  // const [boxSelection, setBoxSelection] = useState([]);


  // console.log(boxSelection);

  const indexOfLastCandidat = currentPage * candidatsPerPage;
  const indexOfFirstCandidat = indexOfLastCandidat - candidatsPerPage;
  let currentSearch = [];
  let currentCandidats = [];
  let totalCandidats = [];

  if (searchResult["0"] !== undefined) {
    currentSearch = searchResult["0"];
    totalCandidats = currentSearch.length;
    currentCandidats = currentSearch.slice(
      indexOfFirstCandidat,
      indexOfLastCandidat
    );
  }


  const paginate = (pageNum) => {
    setCurrentPage(pageNum);
  };

  const nextPage = () => {
    let maximumPages = Math.ceil(totalCandidats / candidatsPerPage);
    
    if (currentPage < maximumPages) {
      setCurrentPage(currentPage + 1);
    }

  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const firstPage = () => {
    setCurrentPage(1)
  }

  const lastPage = () =>{
    setCurrentPage(Math.ceil(currentSearch.length/candidatsPerPage))
  }

  return (
    <div>
      <Table>
        <Table.Header>
          <Table.Row>
            {/* <Table.HeaderCell>Actions</Table.HeaderCell> */}

            <Table.HeaderCell>

            <Link to="">
              ID
            </Link>

            </Table.HeaderCell>

            <Table.HeaderCell>

              <Link to="">
                Nom
              </Link>
        
            </Table.HeaderCell>

            <Table.HeaderCell>

              <Link to="">
                Téléphone
              </Link>
        
            </Table.HeaderCell>

            <Table.HeaderCell>

              <Link to="">
                Email
              </Link>
        
            </Table.HeaderCell>

            <Table.HeaderCell>

              <Link to="">
                Date de renouvellement
              </Link>
        
            </Table.HeaderCell>

            <Table.HeaderCell>

              <Link to="">
                Type
              </Link>
        
            </Table.HeaderCell>

            <Table.HeaderCell>

              <Link to="">
                Date Autre
              </Link>
        
            </Table.HeaderCell>

            <Table.HeaderCell>

              <Link to="">
                Concours
              </Link>
        
            </Table.HeaderCell>
            
              
            
            <Table.HeaderCell>Date de création</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>

          {currentCandidats?.map((result) => {
            return (
              <Table.Row key={result.id}>
                {/* <Table.Cell>
                  <Checkbox
                    name="myCheckBox1"
                    onChange={() =>
                      setBoxSelection([...boxSelection, result.id])
                    }
                  />
                </Table.Cell> */}
                <Table.Cell>{result.id}</Table.Cell>
                <Table.Cell>
                    {result.first_name} {result.last_name}
                </Table.Cell>
                <Table.Cell>{result.phone}</Table.Cell>
                <Table.Cell>{result.email}</Table.Cell>
                <Table.Cell>{result.renew_date}</Table.Cell>
                <Table.Cell>{result.renew_type}</Table.Cell>
                <Table.Cell>{result.other_date}</Table.Cell>
                <Table.Cell>{result.concours_name}</Table.Cell>
                <Table.Cell>{result.created_at}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <Pagination
        
        candidatsPerPage={candidatsPerPage}
        totalCandidats={currentSearch.length}
        paginate={paginate}
        nextPage={nextPage}
        previousPage={previousPage}
        lastPage ={lastPage}
        firstPage={firstPage}
        current={currentPage}
      />

      <div className='mt-5'></div>
      

      
    </div>
  );
};

export default Concours;
