import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import {
  Icon,
  Statistic,
  Grid,
  Table,
  Divider,
  Button,
} from "semantic-ui-react";
import { authAxios } from "../services";
import { api } from "../api";
import {GetCurrentRDV} from "../containers/genesys/api";

const LiveStats = () => {
  const [dialingAgent, setDialingAgent] = useState(null);
  const [dialingVendeur, setDialingVendeur] = useState(null);
  const [dialingDuoAgent, setDialingDuoAgent] = useState(null);
  const [dialingDuoVendeur, setDialingDuoVendeur] = useState(null);
  const [transferts, setTransferts] = useState(null);
  const [rdv, setRdv] = useState(null);

  const [agentAnswerHour, setAgentAnswerHour] = useState(null);
  const [agentAnswerDay, setAgentAnswerDay] = useState(null);
  const [vendeurAnswerHour, setVendeurAnswerHour] = useState(null);
  const [vendeurAnswerDay, setVendeurAnswerDay] = useState(null);

  const [agentAbandonHour, setAgentAbandonHour] = useState(null);
  const [agentAbandonDay, setAgentAbandonDay] = useState(null);
  const [vendeurAbandonHour, setVendeurAbandonHour] = useState(null);
  const [vendeurAbandonDay, setVendeurAbandonDay] = useState(null);

  const [scWaiting, setScWaiting] = useState(null);
  const [naWaiting, setNaWaiting] = useState(null);
  const [longestScWaiting, setLongestScWaiting] = useState(null);
  const [longestNaWaiting, setLongestNaWaiting] = useState(null);

  const [veto, setVeto] = useState(null);
  const [cruze, setCruze] = useState(null);
  const [callback, setCallback] = useState(null);

  const [low, setLow] = useState(null);
  const [moderate, setModerate] = useState(null);
  const [high, setHigh] = useState(null);
  const [extreme, setExtreme] = useState(null);

  const [agentStatus, setAgentStatus] = useState(null);
  const [vendeurStatus, setVendeurStatus] = useState(null);
  const [callbackSc, setCallBackSc] = useState(null);

  // eslint-disable-next-line
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(null);
  const [id, setId] = useState(null);

  const nbRdv = GetCurrentRDV();

 
  const changeOn = () => {

    console.log(GetCurrentRDV())
    // const formData = new FormData();
    // formData.append("set_open", true);
    // authAxios
    //   .put(api.dialer.update(id), formData, {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then((res) => {
    //     if (res.status === 200) {
    //       console.log(res);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     // setLoading(false);
    //     setError(err.message || err);
    //   });
    // openDialer(true);
  };

  const changeOff = () => {

    console.log(GetCurrentRDV())

    // const formData = new FormData();
    // formData.append("set_open", false);
    // authAxios
    //   .put(api.dialer.update(id), formData, {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then((res) => {
    //     if (res.status === 200) {
    //       console.log(res);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     // setLoading(false);
    //     setError(err.message || err);
    //   });
  };

  const openDialer = (value) => {
    authAxios
      .get(api.dialer.open, {
        params: value,
      })
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
        setError(err.message || err);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      async function fetchData() {
        try {
          const response = await authAxios.get(api.dialer.list);
          console.log(response.data);
          setDialingAgent(
            (response.data["0"]["dialing_agent"] * 100).toFixed(0)
          );
          setDialingVendeur(
            (response.data["0"]["dialing_vendeur"] * 100).toFixed(0)
          );
          setDialingDuoAgent(
            (response.data["0"]["dialing_agent_duo"] * 100).toFixed(0)
          );
          setDialingDuoVendeur(
            (response.data["0"]["dialing_vendeur_duo"] * 100).toFixed(0)
          );

          setTransferts(response.data["0"]["transferts"]);
          setRdv(response.data["0"]["total_RDV"]);

          setAgentAnswerHour(response.data["0"]["agent_answer_rate_hour"]);
          setAgentAnswerDay(response.data["0"]["agent_answer_rate_daily"]);
          setVendeurAnswerHour(response.data["0"]["vendeur_answer_rate_hour"]);
          setVendeurAnswerDay(response.data["0"]["vendeur_answer_rate_daily"]);

          setAgentAbandonHour(response.data["0"]["agent_abandon_rate_hour"]);
          setAgentAbandonDay(response.data["0"]["agent_abandon_rate_daily"]);
          setVendeurAbandonHour(
            response.data["0"]["vendeur_abandon_rate_hour"]
          );
          setVendeurAbandonDay(
            response.data["0"]["vendeur_abandon_rate_daily"]
          );

          setScWaiting(response.data["0"]["total_services_waiting"]);
          setNaWaiting(response.data["0"]["total_nas_waiting"]);
          setLongestScWaiting(response.data["0"]["longest_waiting_service"]);
          setLongestNaWaiting(response.data["0"]["longest_waiting_na"]);

          // setConseillerReady(response.data["0"]["total_conseillers_waiting"]);
          // setProdReady(response.data["0"]["total_prod_autonome_waiting"]);

          setVeto(response.data["0"]["veto_comment"]);
          setCruze(response.data["0"]["cruze_comment"]);
          setCallback(response.data["0"]["callback_comment"]);

          setLow((response.data["0"]["low"] * 100).toFixed(0));
          setModerate((response.data["0"]["moderate"] * 100).toFixed(0));
          setHigh((response.data["0"]["high"] * 100).toFixed(0));
          setExtreme((response.data["0"]["extreme"] * 100).toFixed(0));

          setAgentStatus(response.data["0"]["agent_statut"]);
          setVendeurStatus(response.data["0"]["vendeur_statut"]);
          setCallBackSc(response.data["0"]["callback_sc"]);
          setOpen(response.data["0"]["set_open"]);
          setId(response.data["0"]["id"]);
        } catch (error) {
          setError(error.message);
        }
      }
      fetchData();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#fafafa",
        paddingTop: "5px",
        paddingBottom: "50px",
        fontSize: "12px",
      }}
    >
      <Container>
        <div className="card card-body mt-4 ">
          <Statistic.Group widths="one">
            <Statistic>
              <Statistic.Value text>
                Automatisation
                <br />
                {open ? (
                  <h2 style={{ color: "#36da36" }}>Activé</h2>
                ) : (
                  <h2 style={{ color: "#ff1d3b" }}>Désactivé</h2>
                )}
                <Button.Group>
                  <Button positive onClick={changeOn}>
                    Ouverture
                  </Button>
                  <Button.Or />
                  <Button negative onClick={changeOff}>
                    Fermeture
                  </Button>
                </Button.Group>
              </Statistic.Value>
            </Statistic>
          </Statistic.Group>
          <br />
        </div>

        <div className="card card-body mt-4 ">
          <Statistic.Group widths="three">
            <Statistic>
              <Statistic.Value>
                <Icon name="users" /> {transferts}
              </Statistic.Value>
              <Statistic.Label>Transferts en Attente</Statistic.Label>
            </Statistic>

            <Statistic>
              <Statistic.Value>
                <Icon name="users" /> {rdv}
              </Statistic.Value>
              <Statistic.Label>RDV heure courante</Statistic.Label>
            </Statistic>

            <Statistic>
              <Statistic.Value>
                <Icon name="users" /> {callbackSc}
              </Statistic.Value>
              <Statistic.Label>SC en attente</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </div>




        <Grid
          divided="vertically"
          className="mt-2 ml-0"
          style={{ textAlign: "center", marginRight: "-25px" }}
        >
          <Grid.Row columns={2}>
           
            <Grid.Column>
              <label
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  textAlign: "center",
                }}
              >
                Clients en attente (Service)
              </label>
              <Divider />
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Queue</Table.HeaderCell>
                    <Table.HeaderCell>Nombre en attente</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>

                  <Table.Row>
                    <Table.Cell style={{ fontWeight: "bold", background: "#f9fafb" }}>
                      Nombres
                    </Table.Cell>
                    <Table.Cell>{scWaiting}</Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell style={{ fontWeight: "bold", background: "#f9fafb" }}>
                      Nombres
                    </Table.Cell>
                    <Table.Cell>{scWaiting}</Table.Cell>
                  </Table.Row>

               


                </Table.Body>
              </Table>
            </Grid.Column>



            <Grid.Column>
              <label
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  textAlign: "center",
                }}
              >
                Clients en attente (Vente)
              </label>
              <Divider />
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>Services</Table.HeaderCell>
                    <Table.HeaderCell>Ventes</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell
                      style={{ fontWeight: "bold", background: "#f9fafb" }}
                    >
                      Nombres
                    </Table.Cell>
                    <Table.Cell>{scWaiting}</Table.Cell>
                    <Table.Cell>{naWaiting}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell
                      style={{ fontWeight: "bold", background: "#f9fafb" }}
                    >
                      Max attente en sec
                    </Table.Cell>
                    <Table.Cell>{longestScWaiting}</Table.Cell>
                    <Table.Cell>{longestNaWaiting}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>


          </Grid.Row>

        </Grid>
      </Container>
    </div>
  );
};

export default LiveStats;
