import React, { useState, useEffect } from "react";
import { Button, Form, Dropdown, Input, Label } from "semantic-ui-react";
import {ErrorModal, ConfirmModal, SuccessModal}  from "./Modals"
import { Col, Row } from "react-bootstrap";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { api } from "../../api";
import { authAxios } from "../../services/authentications.services";
import { useStateValue } from "../../StateProvider";
import LogoFoniva from  "../../assets/img/foniva.png"

import {Regx, testInput} from "../../utils/regx"


export default () => {
  
  useEffect(() => {

    async function fetchData() {
      try {
        const response = await authAxios.get(api.foniva.campaign.list);
        campaignToOptions(response.data)
      } catch (error) {

       
      }
    }

    fetchData();
  }, [campaigns]);

  const [campaign, setCampaign] = useState({});
  const [loading, setLoading] = useState(false)
  const [number, setNumber] = useState();
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [error, setError] = useState({title: "", message :""});
  const { Panel } = Collapse;
  const [campaigns, setCampaigns] = useState([]) 


const campaignToOptions = (campaigns) =>{
setCampaigns(campaigns.map((c,i) => ({
  "key" : i+1,
  text : c.name,
  value: c.dbid
})))

}

  
  async function handleSubmit(e) {

    try {
      console.log(campaign)

      const response = await authAxios.delete(api.foniva.contacts.delete, {
        params: { "dbid" : campaign.dbid, "number" : number },

      });
      setShowModalSuccess(true)
    } catch (error) {
      if(error.response.status == 403){
        setError({title: "403 Accès refusé" , message : "Vous n'avez pas les accréditations pour effectuer cette action!"})
        setShowModalError(true)
      }
      
    }
  }

  return (
    <section>
      <ErrorModal
           title={error.title}
           id="modal-confirmation"
           setShowErrorModal={setShowModalError}
           showErrorModal={showModalError}
      >
        <p>{error.message}</p>


      </ErrorModal>

      <ConfirmModal
      title="Confirmation"
      id="modal-confirmation"
      setShowModalConfirm={setShowModalConfirm}
      showModalConfirm={showModalConfirm}
      handleSubmit={handleSubmit}
      >
        <p>Vous êtes sur le point de supprimer le numéro {number} de la campagne {campaign.name} (#{campaign.dbid}).</p>
      </ConfirmModal>

      <SuccessModal
      title="Opération réussi"
      id="modal-success"
      setShowModalSuccess={setShowModalSuccess}
      showModalSuccess={showModalSuccess}
      >
        <p>Opération réussie!</p>
      </SuccessModal>
      
      
      <h2>Suppression d'un contact</h2>

          <Form onSubmit={(e) => {
            e.preventDefault()
            
            if(campaign.dbid === undefined ||campaign.dbid === ""){

              setError({"title" : "Erreur", "message" : "Vous devez sélectionner une campagne."})
              setShowModalError(true)
              return false;
            }

            if(number === "anonymous" || new RegExp(Regx.PHONE_NUMBER).test(number)){
              setShowModalConfirm(true)
            } 
            else{
              setError({"title" : "Erreur", "message" : "Le numéro de téléphones est invalide ou n'est pas un appel anonyme."})
              setShowModalError(true)
            }
            
          }
            
            } style={{ marginTop: "25px" }}>
            <Row
              className="justify-content-md-center"
              style={{ paddingBottom: "150px" }}
            >
              <Col xs={12}>
                <Form.Field
                 required

                label="Campagne"
                control={Dropdown}
                placeholder="Campagne..."
                fluid
                scrolling
                clearable
                selection
                options={campaigns}
                value={campaign.dbid}
                onChange={(e, result) => 
                  {
                    let  nameKey = e.target.querySelector("span")
                    nameKey = nameKey == null ? "" : nameKey.textContent
                    setCampaign( { name : nameKey  , dbid : result.value})
                  }
                  }
                >
            
                </Form.Field>

                <Form.Field
                 
                 required
                 type="text"
                 id='form-input-control-number'
                 control={Input}
                 name="studies-hours"
                 label="Numéro de téléphone (123456789) ou anonymous" 
                 value={number}
                 onChange={
                   e => setNumber(e.target.value)
                }
                 
                 />

              </Col>

            <div
            style={{
              width: '100%',
            margin: '20px', 
          textAlign: 'center'
          }}
            >
            <Button 

                            inverted 
                            loading={loading}
                            disabled={loading}
                            type='submit'
                            color='red'
                            // control={Button}
                            content='Supprimer'
                            
                        />
            </div>
      
              
            </Row>

         
                        
     

          </Form>

          </section>


  );
};


