import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel3";
import Icofont from "react-icofont";
import PropTypes from "prop-types";
import VisibilitySensor from "react-visibility-sensor";
import "react-modal-video/css/modal-video.min.css";
import ModalVideo from "react-modal-video";
import { Link } from "react-scroll";
class HomeSlides extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    //Home Slides loop start
    const homeslidesdata = this.props.homeslidesData.map(
      (homeslides, index) => (
        <React.Fragment key={index}>
          <div className={homeslides.bgImgClass}>

    
              <div 
              className="banner-title-top">
                <div className="container">
                  <VisibilitySensor>
                    {({ isVisible }) => (
                      <div className="hero-content">
                        <h5
                          className={
                            isVisible
                              ? "animated fadeInDown slow opacityOne"
                              : "opacityZero"
                          }
                        >
                          {homeslides.toptitle}
                        </h5>
                        <h1
                          style={{ fontSize: "44px" }}
                          className={
                            isVisible
                              ? "animated fadeInUp slow opacityOne"
                              : "opacityZero"
                          }
                        >
                          {homeslides.mainlefttitle}{" "}
                          <span>{homeslides.mainspantitle}</span>{" "}
                          {homeslides.mainrighttitle}
                        </h1>
                        <p
                          style={{ fontSize: "29px" }}
                          className={
                            isVisible
                              ? "animated zoomIn Slow opacityOne"
                              : "opacityZero"
                          }
                        >
                          {homeslides.content}
                        </p>

                        <div style={{
                          minWidth: '300px',
                          maxWidth: '400px',  
                          marginRight: 'auto', 
                          marginLeft: 'auto',
                          display: 'flex', 
                          justifyContent: 'space-between', 

                          justifySelf: 'center'
                          }}>

                          <Link
                            href="/cotation"
                            onClick="/cotation"
                            style={{
                            marginBottom: '20px', 
                            paddingRight: '5px'
                          }}

                          >
                            <button
                              className="btn btn-primary animated fadeInDown slow opacityOne"
                            >
                              <Icofont
                                style={{ fontSize: "20px" }}
                                icon="icofont-car-alt-4"
                              />{" "}
                            automobile
                          </button>
                          </Link>

                          <Link href="/cotation"
                            onClick="/cotation"

                          >
                            <button
                              className="btn btn-primary animated fadeInDown slow opacityOne"
                            >
                              <Icofont
                                style={{ fontSize: "20px" }}
                                icon="icofont-home"
                              />{" "}
                            habitation
                          </button>
                          </Link>


                          {/*
                          <Link href="/appel-de-service"
                            onClick="/appel-de-service"
                            style={{width:'100%'}}
                          >
                            <button
                              className="btn btn-primary animated fadeInDown slow opacityOne"
                            >
                              <Icofont
                                style={{ fontSize: "20px" }}
                                icon="icofont-calendar"
                              />{" "}
                            Prise de rendez-vous
                          </button>
                          </Link>
                          */}
                        </div>
                        {/* <p className='mt-5'  style={{textAlign: 'center', fontSize: '23px'}} ><a href="tel:+18666293686" style={{color: '#ffffff'}}>1 866 629-3686</a> </p> */}
                      </div>
                    )}
                  </VisibilitySensor>
                </div>
              </div>

              <div 
              className="service-web"
              >
                <div className="container">
                  <VisibilitySensor>
                    {({ isVisible }) => (
                      <div className="hero-content">
                        <h5
                          className={
                            isVisible
                              ? "animated fadeInDown slow opacityOne"
                              : "opacityZero"
                          }
                        >
             
                        </h5>
                        <h1
                          style={{ fontSize: "44px" }}
                          className={
                            isVisible
                              ? "animated fadeInUp slow opacityOne"
                              : "opacityZero"
                          }
                        >
                          PLANIFIER UN <span style={{color: 'orange'}}>APPEL DE SERVICE.</span> 
                        </h1>
                        <p
                          style={{ fontSize: "29px" }}
                          className={
                            isVisible
                              ? "animated zoomIn Slow opacityOne"
                              : "opacityZero"
                          }
                        >
                          Prendre rendez-vous avec un conseiller
                        </p>

                        <div style={{
                          minWidth: '300px',
                          maxWidth: '400px',  
                          marginRight: 'auto', 
                          marginLeft: 'auto',
                          }}>

      

                          
                          <Link href="/appel-de-service"
                            onClick="/appel-de-service"
                            style={{
                              marginBottom: '20px', 
                            }}
                          >
                            <button
                              className="btn btn-primary animated fadeInDown slow opacityOne"
                            >
                              <Icofont
                                style={{ fontSize: "20px" }}
                                icon="icofont-calendar"
                              />{" "}
                           Rendez-vous 
                          </button>
                          </Link>
                          
                        </div>
                        {/* <p className='mt-5'  style={{textAlign: 'center', fontSize: '23px'}} ><a href="tel:+18666293686" style={{color: '#ffffff'}}>1 866 629-3686</a> </p> */}
                      </div>
                    )}
                  </VisibilitySensor>
                </div>
              </div>
   
          </div>
        </React.Fragment>
      )
    );
    //Home Slides END
    return (
      <React.Fragment>
        <div id="home">
          <ModalVideo
            channel="youtube"
            isOpen={this.state.isOpen}
            videoId={this.props.homeslidesData[0].videoId}
            onClose={() =>
              this.setState({
                isOpen: false,
              })
            }
          />

          {homeslidesdata}

        </div>
      </React.Fragment>
    );
  }
}
HomeSlides.propTypes = {
  animationImg1: PropTypes.string,
  animationImg2: PropTypes.string,
  animationImg3: PropTypes.string,
  animationImg4: PropTypes.string,
  animationImg5: PropTypes.string,
  animationImg6: PropTypes.string,
  homeslidesData: PropTypes.array,
};
HomeSlides.defaultProps = {
  animationImg1: require("../../assets/img/1.png"),
  animationImg2: require("../../assets/img/2.png"),
  animationImg3: require("../../assets/img/1.png"),
  animationImg4: require("../../assets/img/5.png"),
  animationImg5: require("../../assets/img/6.png"),
  animationImg6: require("../../assets/img/7.png"),
  homeslidesData: [
    {
      bgImgClass: "main-banner item-bg-one",
      toptitle: "",
      mainlefttitle: "Obtenez un prix en quelques",
      mainspantitle: "MINUTES.",
      mainrighttitle: "",
      content: "Je veux une soumission",
      leftbtn: "Get Started",
      leftbtnlink: "#",
    }
  ],
};
export default HomeSlides;
