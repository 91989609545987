import React, { useState } from "react";
import { Button, Form, Dropdown } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { api } from "../api";
import { authAxios } from "../services/authentications.services";
import { useStateValue } from "../StateProvider";


const SearchForm = () => {
  // eslint-disable-next-line
  const [{ searchResult }, dispatch] = useStateValue();
  const [phoneStatusState, setPhoneStatusState] = useState();
  const [finalStatusState, setFinalStatusState] = useState();
  const [callAttempts, setCallAttempts] = useState();
  // eslint-disable-next-line
  const [error, setError] = useState(null);

  const { Panel } = Collapse;

  const optionPhoneStatus = [
    { key: "1", text: "", value: "" },
    { key: "2", text: "Entrevue planifiée", value: "Entrevue planifié" },
    { key: "3", text: "En attente", value: "En attente" },
    { key: "4", text: "En banque", value: "En banque" },
    { key: "5", text: "Décliné - Expérience téléphonique", value: "Décliné - Expérience téléphonique" },
    { key: "6", text: "Décliné - Pas le profil recherché", value: "Décliné - Pas le profil recherché" },
    { key: "7", text: "Décliné - Scolarité", value: "Décliné - Scolarité" },
    { key: "8", text: "Décliné - Doublon", value: "Décliné - Doublon" },
    { key: "9", text: "Décliné - Aucun retour du candidat",  value: "Décliné - Aucun retour du candidat"},
    { key: "10", text: "Décliné - AMF",  value: "Décliné - AMF"}
  ];

  const optionFinalStatus = [
    { key: "1", text: "", value: "" },
    { key: "2", text: "Engagé", value: "Engagé" },
    { key: "3", text: "En attente", value: "En attente" },
    { key: "4", text: "En banque", value: "En banque" },
    { key: "5", text: "Décliné - Candidature non retenue", value: "Décliné - Candidature non retenue" },
    { key: "6", text: "Décliné - Candidat refuse le poste", value: "Décliné - Candidat refuse le poste" }
  ];
  
  const optionAttempt = [
  { key: "1", text: "", value: "" },
  { key: "2", text: "1", value: "1" },
  { key: "3", text: "2", value: "2" },
  { key: "4", text: "3", value: "3" },
  { key: "5", text: "4", value: "4" },
];

  async function handleSubmit(e) {
    e.preventDefault();

    // setPhoneStatusState(unidecode(phoneStatusState))
    // setFinalStatusState(unidecode(finalStatusState))
    
    console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@')
    // console.log(unidecode(phoneStatusState))
    console.log(phoneStatusState, finalStatusState, callAttempts);

    // dispatch the search result into the data layer
    try {
      const response = await authAxios.get(api.candidats.advancedSearch, {
        params: { phoneStatusState, finalStatusState, callAttempts },
      });
      // console.log(response.data);
      dispatch({
        type: "SEARCH",
        result: response.data,
      });
    } catch (error) {
      setError(error.message);
      console.log(error);
    }
  }

  return (
    <div>
      <Collapse
        bordered={false}
        // defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        className="site-collapse-custom-collapse"
      >
        <Panel
          header="Recherches avancées"
          key="1"
          className="site-collapse-custom-panel"
        >
          <Form onSubmit={handleSubmit} style={{ marginTop: "25px" }}>
            <Row
              className="justify-content-md-center"
              style={{ paddingBottom: "150px" }}
            >
              <Col xs={3}>
                <Form.Field>
                  <Dropdown
                    placeholder="Statut téléphonique"
                    fluid
                    // multiple
                    scrolling
                    search
                    clearable
                    selection
                    options={optionPhoneStatus}
                    value={phoneStatusState}
                    onChange={(e, result) => setPhoneStatusState(result.value)}
                  />
                </Form.Field>
              </Col>
              <Col xs={3}>
                <Form.Field>
                  <Dropdown
                    placeholder="Statut final"
                    fluid
                    // multiple
                    search
                    scrolling
                    clearable
                    selection
                    options={optionFinalStatus}
                    value={finalStatusState}
                    onChange={(e, result) => setFinalStatusState(result.value)}
                  />
                </Form.Field>
              </Col>
              <Col xs={3}>
                <Form.Field>
                  <Dropdown
                    placeholder="Tentatives d'appels"
                    fluid
                    // multiple
                    search
                    clearable
                    scrolling
                    selection
                    options={optionAttempt}
                    value={callAttempts}
                    onChange={(e, result) => setCallAttempts(result.value)}
                  />
                </Form.Field>
              </Col>
            </Row>
            <Row
              className="justify-content-md-center"
              style={{ marginTop: "45px", marginBottom: "45px" }}
            >
              <Col xs={9}>
                <Button
                  type="submit"
                  style={{ backgroundColor: "orange" }}
                  fluid
                >
                  Rechercher
                </Button>
              </Col>
            </Row>
          </Form>
        </Panel>
      </Collapse>
    </div>
  );
};

export default SearchForm;
