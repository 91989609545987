import React from 'react';


export function ContactUs(props) {
    
  
    //Start ContactUs Loop
    const contactusdata = props.contactussData.map((contactus, index ) =>(
        <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
            <div className="contact-box">
                <div className="icon">
                    <i className={contactus.boxicon} />
                </div>
                <h4>{contactus.boxtitle}</h4>
                <p><a href={contactus.boxitem1link}>{contactus.boxitem1}</a></p>
                <p><a href={contactus.boxitem2link}>{contactus.boxitem2}</a></p>
            </div>
        </div>
    ));      
    //End ContactUs Loop
    return ( 
        
        <React.Fragment>

            <section id="contact" className="contact-area ptb-100">
                <div className="container">
                    <div className="section-title">
                        <h2>{props.sectionTitle} <b>{props.sectionTitleSpan}</b></h2>
                        <p>{props.sectionContent}</p>
                    </div>
                    <div className="row">
                        {contactusdata}
                    </div>
                </div>
            </section>

        </React.Fragment>
    );
  
} 


ContactUs.defaultProps = {
    sectionTitle: "Agent de contact",
    sectionTitleSpan: "",
    sectionContent: "Une job qui te fait commencer à 20,00$/h (+ bonification) et te donne la possibilité d'augmenter à 21,00$/h (+bonification) après seulement quelques semaines de formation, ça te parle?",

    contactussData:[

        {
            boxicon: "icofont-calendar",
            boxtitle: "Horaire:",
            boxitem1link: "#",
            boxitem1: "Temps plein,",
            boxitem2link: "#",
            boxitem2: "temps partiel",
        },

        {
            boxicon: "icofont-dollar",
            boxtitle: "Salaire:",
            boxitem1link: "#",
            boxitem1: "Salaire concurrentiel",
            boxitem2link: "#",
            boxitem2: "20,00$/h - 21,00$/h",

        },

        {
            boxicon: "icofont-location-pin",
            boxtitle: "Localisation:",
            boxitem1link: "#",
            boxitem1: "725, boulevard Lebourgneuf",
            boxitem2link: "#",
            boxitem2: "bureau 320, Québec G2J0C4",

        }
    ]
}
export default ContactUs
