import React from "react";
import { useHistory} from "react-router-dom";


function  BannerSuccess() {

    let history = useHistory();

    function goToHome(){

        history.push('/')
    }

    return (
      <React.Fragment>
        <div id="home" className="main-banner item-bg-two">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="hero-content">
                  <h1 style={{color: 'orange'}}>Vous avez terminé!</h1>
                  <p style={{fontSize: '17px'}}>Merci d'avoir participé à notre concours et bonne chance!</p>
                  <p style={{fontSize: '17px'}}>Suivez-nous sur les réseaux sociaux.</p>
                  <button onClick={goToHome} className="btn btn-primary">
                     Quitter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}


export default BannerSuccess;
