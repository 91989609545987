import React, {useState, useRef} from "react";
import { Row, Col } from "react-bootstrap";
import { Form, Input, Button, Segment, Image } from 'semantic-ui-react';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import axios from 'axios';
import { api } from "../../api";
import {history} from "../../containers/helpers"
import Message from "../../components/Message";
import CareerCreateChef from '../../templates/CareerCreateChef'

const optionsOrigin = [
    { key: "1", text: "SPLA (UL)", value: "SPLA (UL)" },
    { key: "2", text: "Facebook", value: "Facebook" },
    { key: "3", text: "Instagram", value: "Instagram" },
    { key: "4", text: "LinkedIn", value: "LinkedIn" },
    { key: "5", text: "Jobillico", value: "Jobillico" },
    { key: "6", text: "Pinterest", value: "Pinterest" },
    { key: "7", text: "Quelqu'un qui travaille chez AGP", value: "Quelqu'un qui travaille chez AGP" },
    { key: "8", text: "Autre", value: "Autre" }
];

const optionsHourToCall = [
    { key: "1", text: "Toute la journée", value: "Toute la journée" },
    { key: "2", text: "En matinée", value: "En matinée" },
    { key: "3", text: "9h - 10h", value: "9h - 10h" },
    { key: "4", text: "10h - 11h", value: "10h - 11h" },
    { key: "5", text: "11h - 12h", value: "11h - 12h" },
    { key: "6", text: "12h - 13h", value: "12h - 13h" },
    { key: "7", text: "13h - 14h", value: "13h - 14h" },
    { key: "8", text: "14h - 15h", value: "14h - 15h" },
    { key: "9", text: "15h - 16h", value: "15h - 16h" },
    { key: "10", text: "16h - 17h", value: "16h - 17h" }
];

const optionsSchoolLevel = [
    { key: "1", text: "Université", value: "Université" },
    { key: "2", text: "Cégep ", value: "Cégep" },
    { key: "3", text: "Technique", value: "Technique" },
    { key: "4", text: "Autre", value: "Autre" },
];




const CareerCreateIT = () => {

    document.body.style = 'background: #F1F1F1;';
    const fileInputRef = useRef()
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [first_name, setFirst_name] = useState(null);
    const [last_name, setLast_name] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [origin, setOrigin] = useState(null);

    const [attachment, setAttachment] = useState(null);
    const [schoolLevel, setSchoolLevel] = useState(null);
    const [hour_to_call, setHour_to_call] = useState(null);
    const [loading, setLoading] = useState(false)

    const mailgun = require("mailgun-js");
    const DOMAIN = 'mail.agpassurance.ca';
    const mg = mailgun({apiKey: 'key-8cdc956d187052f646ef4a5f3e1a8df4', domain: DOMAIN});
    const data = {
        from: 'AGP Assurance <info@mail.agpassurance.ca>',
        to: 'maxime-morin.gagnon@agpassurance.ca',
        // to: 'jonathan.pagani@agpassurance.ca',
        subject: 'Nouvelle candidature',
        html: CareerCreateChef(first_name, last_name, new Date().toLocaleDateString())
    };

   

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpenError(false)
        setOpenSuccess(false);
      };

    function Alert(props) {

        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    
    const handleSubmit = e => {

        // console.log('first_name : ',first_name)
        // console.log('last_name : ', last_name)
        // console.log('email : ', email)
        // console.log('phone :' ,phone)
        // console.log('renew_date :' ,renew_date)
        // console.log('renew_type :' ,renew_type)
        // console.log('policy :' , policy)


        if (first_name !== null && last_name !== null && email !== null && phone !== null && 
            phone.length === 10 && origin !== null && schoolLevel !== null && hour_to_call !== null && attachment !== null) {
      
            setOpenError(false)
            setOpenSuccess(true)

            e.preventDefault();
            const formData = new FormData()
            formData.append("first_name", first_name)
            formData.append("last_name", last_name)
            formData.append("email", email)
            formData.append("phone", phone)
            formData.append("origin", origin)
            formData.append("attachment", attachment)
            formData.append("schoolLevel", schoolLevel)
            formData.append("hour_to_call", hour_to_call)



            axios
            .post(api.candidatsTI.create, formData,{
                headers: {
                    "Content-type": "multipart/form-data"
                }
            })
            .then(res => {
                if (res.status === 201) {
                    setLoading(true);
                    mg.messages().send(data, function (error, body) {
                        // console.log("Sent");
                        history.push('/succes')
                        // console.log(res.status)
                        // console.log(res)
                    });


                }
            })
            .catch(err => {
                console.log(err)
                setLoading(false);
                setOpenError(true)
            })

        }else{
            setOpenSuccess(false)
            setOpenError(true)
        }
        
    }


  return (

    <section id="contact" className="ptb-100">
        <div className="container" style={{ maxWidth: '710px'}}>
            <Row>
                <Col xs={5} md={5} lg={5}>
                    <Image href='https://www.agpassurance.ca/' style={{marginLeft: '20px'}}src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/BeeProAgency/491756_472251/AGP-Logo-RVB-Noir.png"  />
                </Col>
                <Col>
                    <Image href='https://www.linkedin.com/company/agp-assurance/' target='_blank' style={{width: '32px', height: '32px'}} className="ui small right floated image" src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/t-only-logo-dark-gray/linkedin@2x.png"  />
                    <Image href='https://www.instagram.com/agpassurance/' target='_blank' style={{width: '32px', height: '32px'}}  className="ui small right floated image" src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/t-only-logo-dark-gray/instagram@2x.png"  />
                    <Image href='https://www.facebook.com/agpassuranceqc/' target='_blank' style={{width: '32px', height: '32px'}} className="ui small right floated image" src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/t-only-logo-dark-gray/facebook@2x.png"  />
                </Col>
            </Row>

            <Segment style={{backgroundColor: 'white', borderTopLeftRadius: '25px', borderTopRightRadius: '25px', paddingLeft: '40px', paddingRight: '40px'}} className='mt-5' >

                <div>
                    <Image style={{borderRadius: 10}} src="https://mcusercontent.com/10d310a71fa47b547a2f36940/images/5362e362-a7b9-4e10-98c1-a10a289bd5c3.png" />
                </div>



                <div style={{textAlign: 'left'}} className='mt-4'>
                    <p style={{color: 'black', fontSize: '27px', lineHeight: "0.5", fontWeight: "bold"}}>Développeur Junior</p>
                    <p style={{color: 'black', fontSize: '20px',lineHeight: "1",fontWeight: "bold"}}>Salaire : 30$/h</p>
                    <p style={{color: 'black', fontSize: '20px',lineHeight: "1",fontWeight: "bold"}}>À l’année 62 400$</p>
                    <p style={{color: 'black', fontSize: '15px',lineHeight: "1"}}>Temps plein (40h)</p>
                    <p style={{color: 'black', fontSize: '15px',lineHeight: "1"}}>Possibilité de télétravail</p>
                </div>


                <div style={{textAlign: 'left'}} className='mt-4'>
                    <p style={{color: 'black', fontSize: '15px'}}>AGP Assurance est à la recherche d’un développeur junior pour se joindre à son département technologique «aka le AGP Lab».</p>
                </div>


                <div className='pt-4' >
                    <p style={{color: 'black', fontSize: '14px', fontWeight: "bold"}}>Sous la supervision du gestionnaire technologique, tu auras la responsabilité de :</p>
                    <p style={{color: 'black', fontSize: '14px', marginLeft: '20px', lineHeight: "2"}}><span style={{color: 'black', fontSize: '8px'}}>{'\u2B24'}</span> Analyser, diagnostiquer et résoudre les problèmes techniques des utilisateurs.</p>
                    <p style={{color: 'black', fontSize: '14px', marginLeft: '20px', lineHeight: "2"}}><span style={{color: 'black', fontSize: '8px'}}>{'\u2B24'}</span> Assister les utilisateurs et assurer les liens avec les parties prenantes.</p>
                    <p style={{color: 'black', fontSize: '14px', marginLeft: '20px', lineHeight: "2"}}><span style={{color: 'black', fontSize: '8px'}}>{'\u2B24'}</span> Participer à l'implantation, à l'installation, à la mise à jour et à la maintenance du matériel, des logiciels, des applications, des systèmes d'exploitation et des bases de données.</p>
                    <p style={{color: 'black', fontSize: '14px', marginLeft: '20px', lineHeight: "2"}}><span style={{color: 'black', fontSize: '8px'}}>{'\u2B24'}</span> Réaliser et tenir à jour les tests unitaires et d'assurance qualité.</p>
                    <p style={{color: 'black', fontSize: '14px', marginLeft: '20px', lineHeight: "2"}}><span style={{color: 'black', fontSize: '8px'}}>{'\u2B24'}</span> Rédiger et mettre à jour la documentation nécessaire à la compréhension, au développement, à l’exploitation et à l’entretien des programmes.</p>
                    <p style={{color: 'black', fontSize: '14px', marginLeft: '20px', lineHeight: "2"}}><span style={{color: 'black', fontSize: '8px'}}>{'\u2B24'}</span> Développer de nouvelles fonctionnalités et maintenir le code existant.</p>
                    <p style={{color: 'black', fontSize: '14px', marginLeft: '20px', lineHeight: "2"}}><span style={{color: 'black', fontSize: '8px'}}>{'\u2B24'}</span> Participer à l'amélioration continue des applications Web de l’entreprise.</p>
                    <p style={{color: 'black', fontSize: '14px', marginLeft: '20px', lineHeight: "2"}}><span style={{color: 'black', fontSize: '8px'}}>{'\u2B24'}</span> Participer à l'analyse et à la conception des projets innovants de l’entreprise.</p>
                </div>


                <div className='pt-4' >
                    <p style={{color: 'black', fontSize: '14px', fontWeight: "bold"}}>La personne que l’on recherche doit avoir les compétences suivantes : </p>
                    <p style={{color: 'black', fontSize: '14px', marginLeft: '20px', lineHeight: "2"}}><span style={{color: 'black', fontSize: '8px'}}>{'\u2B24'}</span> Posséder au minimum 1 an d'expérience pertinente ou une formation équivalente.</p>
                    <p style={{color: 'black', fontSize: '14px', marginLeft: '20px', lineHeight: "2"}}><span style={{color: 'black', fontSize: '8px'}}>{'\u2B24'}</span> Avoir une bonne connaissance de Python, Javascript, HTML5, CSS, Linux/Ubuntu (ligne de commande) et du système d'exploitation Windows.</p>

                </div>


                <div className='pt-4' >
                    <p style={{color: 'black', fontSize: '14px', fontWeight: "bold"}}>Et si tu veux vraiment nous impressionner, tu pourrais avoir : </p>
                    <p style={{color: 'black', fontSize: '14px', marginLeft: '20px', lineHeight: "2"}}><span style={{color: 'black', fontSize: '8px'}}>{'\u2B24'}</span> Une connaissance de ReactJS</p>
                    <p style={{color: 'black', fontSize: '14px', marginLeft: '20px', lineHeight: "2"}}><span style={{color: 'black', fontSize: '8px'}}>{'\u2B24'}</span> Une connaissance de Django</p>
                    <p style={{color: 'black', fontSize: '14px', marginLeft: '20px', lineHeight: "2"}}><span style={{color: 'black', fontSize: '8px'}}>{'\u2B24'}</span> Une connaissance du Django Rest Framework</p>
                    <p style={{color: 'black', fontSize: '14px', marginLeft: '20px', lineHeight: "2"}}><span style={{color: 'black', fontSize: '8px'}}>{'\u2B24'}</span> De l’expérience avec AWS, Looker et MySQL </p>
                    <p style={{color: 'black', fontSize: '14px', marginLeft: '20px', lineHeight: "2"}}><span style={{color: 'black', fontSize: '8px'}}>{'\u2B24'}</span> Une bonne capacité de communication en anglais (écrit et parlé)</p>

                </div>


                <div className='pt-4' style={{textAlign: 'justify'}} >
                    <p style={{color: 'black', fontSize: '14px'}}>Des salaires compétitifs, un horaire flexible et partiellement à distance, 
                    une station de travail puissante et agréable, une ambiance incomparable, une équipe du tonnerre, des outils technologiques modernes, 
                    et surtout, un défi à ta hauteur avec de réelles possibilités d'avancement. Voilà ce que l'on t'offre. C'est beaucoup plus qu'un boulot, 
                    c'est une opportunité à saisir. Envoie-nous ta candidature: il ne manque que toi.
                    </p>
                </div>


                <div className='pt-4' >
                    <p style={{color: 'black', fontSize: '14px', fontWeight: "bold",textAlign: 'justify'}}>Et si ton cœur chavire quand tu entends les mots «avantages et assurances collectives», 
                    tu seras servi chez nous! Voici quelques moyens offerts chez AGP qui te permette d’épargner à la source si tu le souhaites :  
                    </p>
                    <p style={{color: 'black', fontSize: '14px', marginLeft: '20px', lineHeight: "2"}}><span style={{color: 'black', fontSize: '8px'}}>{'\u2B24'}</span> Régime Enregistré d’Épargne-Retraite (REER)</p>
                    <p style={{color: 'black', fontSize: '14px', marginLeft: '20px', lineHeight: "2"}}><span style={{color: 'black', fontSize: '8px'}}>{'\u2B24'}</span> Régime de Participation Différée aux Bénéfices (RPDB)</p>
                    <p style={{color: 'black', fontSize: '14px', marginLeft: '20px', lineHeight: "2", textAlign: 'justify'}}>Le RPDB est un compte indépendant de celui de l’employé. 
                    Dans ce compte, l’employeur contribue 2% du salaire hebdomadaire de l’employé et ce, à chaque semaine suivant l’ouverture du compte, si la contribution de l’employé est également 2% ou plus! </p>
                    <p style={{color: 'black', fontSize: '14px', marginLeft: '20px', lineHeight: "2"}}><span style={{color: 'black', fontSize: '8px'}}>{'\u2B24'}</span> Assurances collectives (Assurance vie salarié, Assurance invalidité, Assurance médicaments)</p>
                </div>
                

                <div className="box" style={{paddingTop: "50px"}}>
                    {attachment && <Message info message={`Document sélectionné: ${attachment.name}`} />}
                    
                    <Form >

                        {/* first_name & last_name */}
                        <Form.Group widths='equal' >

                            <Form.Field
                                required
                                maxLength="20"
                                id='form-input-control-last-name'
                                control={Input}
                                label='Nom'
                                placeholder='Nom'
                                // value={last_name}
                                onChange={e => setLast_name(e.target.value)}
                            />

                            <Form.Field
                                required
                                maxLength="20"
                                id='form-input-control-first-name'
                                control={Input}
                                label='Prénom'
                                placeholder='Prénom'
                                // value={first_name}
                                onChange={e => setFirst_name(e.target.value)}
                            />

                        </Form.Group>


                        {/* email & phone */}
                        <Form.Group widths='equal'>

                            <Form.Field
                                required
                                maxLength="10"
                                minLength="10"
                                // id='form-input-control-phone'
                                control={Input}
                                label='Téléphone'
                                placeholder='Téléphone'
                                onChange={e => setPhone(e.target.value)}
                            />
                            
                            <Form.Field
                                required
                                maxLength="50"
                                id='form-input-control-email'
                                control={Input}
                                label='Email'
                                placeholder='Email'
                                onChange={e => setEmail(e.target.value)}
                            />

                        </Form.Group>

                        <Form.Group widths='equal'>

                        <Form.Dropdown
                            required
                            placeholder="Choisir"
                            label= "Où as-tu entendu parler de nous?"
                            selection
                            onChange={(e, result) => setOrigin(result.value)}
                            options={optionsOrigin}
                        />

                        <Form.Dropdown
                            required
                            placeholder='Choisir'
                            label= "Niveau d'étude"
                            selection
                            onChange={(e, result)  => setSchoolLevel(result.value)}
                            options={optionsSchoolLevel}
                        />

                        </Form.Group>


                        {/* attachment */}
                        <Form.Group widths='equal'>

                            <Form.Field className='mt-3'>

                                <Button
                                    style={{backgroundColor: 'orange'}}
                                    type="button"
                                    fluid
                                    content="Télécharge ton C.V. (pdf/docx/doc)"
                                    labelPosition="left"
                                    icon="file"
                                    onClick={() => fileInputRef.current.click()}
                                />

                                <input
                                    required
                                    accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/msword"
                                    ref={fileInputRef}
                                    type="file"
                                    hidden
                                    onChange={e => setAttachment(e.target.files[0])}
                                />
                            </Form.Field>


                        </Form.Group>

                            


                        {/* hour to call */}
                        <Form.Group widths='equal'>
                          
                            <Form.Dropdown
                                required
                                placeholder="Choisir"
                                label="Moment idéal pour te joindre?"
                                selection
                                onChange={(e, result)  => setHour_to_call(result.value)}
                                options={optionsHourToCall}
                            />
                      </Form.Group>



                        <Form.Field className='mt-4'>

                        <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="success">
                                Votre candidature a été enregistré avec succès!
                            </Alert>
                        </Snackbar>

                        <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="Error">
                                Erreur, un ou plusieurs champs ne sont pas remplis correctement!
                            </Alert>
                        </Snackbar>

                        </Form.Field>
                        
                        <div style={{textAlign: 'center'}} className='mt-4 mb-4'>
                            <Button 
                                
                                inverted 
                                loading={loading}
                                disabled={loading}
                                onClick={handleSubmit}
                                color='orange'
                                content='Envoyer'
                                
                            />
                        </div>
                        

                    </Form>

                </div>
            </Segment>

            <div className="container">

                <div className='mt-4 mb-2'>
                    <Image style={{height: '68px', width: '120px', marginRight: 'auto', marginLeft: 'auto'}} src='https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/BeeProAgency/491756_472251/AGP-Logo-RVB-Noir.png'   />
                </div>

                <p style={{textAlign: 'center', color: 'black', marginBottom: '-5px'}} >725, boulevard Lebourgneuf, bureau 320 </p>
                <p style={{textAlign: 'center', color: 'black'}} >Québec G2J 0C4</p>
            </div>







        </div>
    </section>
  
  );
};

export default CareerCreateIT;



